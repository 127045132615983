import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    color: ${theme.colors.neutral.white};
    padding: ${theme.spacing.lg} ${theme.spacing.md};
    transition: opacity 0.3s ease-in-out;
  `}
`;

export const EmptyWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
