import { Icon } from 'components/structure';
import * as S from './BreadCrumb.styles';

export type BreadCrumbProps = {
  screens: string[];
  isAbsolute?: boolean;
};

export const BreadCrumb = ({ screens, isAbsolute }: BreadCrumbProps) => (
  <S.Wrapper isAbsolute={isAbsolute}>
    {screens.map((screen, index) => {
      const isLast = index === screens.length - 1;
      return (
        <>
          <S.ScreenText active={isLast}>{screen}</S.ScreenText>
          {!isLast && <Icon icon="IcChevronRight" />}
        </>
      );
    })}
  </S.Wrapper>
);
