import * as Yup from 'yup';
import { passwordStrength } from 'lib/contexts/input';
import messages from 'lib/core/errors';

const { invalidParam, requiredParam, paramsDontMatch } = messages;

export const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, invalidParam('password'))
    .required(requiredParam('password'))
    .matches(
      passwordStrength.hasNumber,
      messages.invalidParam('passwordHasNumber'),
    )
    .matches(
      passwordStrength.hasSymbol,
      messages.invalidParam('passwordHasSymbol'),
    )
    .matches(
      passwordStrength.hasCapitalLetter,
      messages.invalidParam('passwordHasCapitalLetter'),
    )
    .matches(
      passwordStrength.hasLowercaseLetter,
      messages.invalidParam('passwordHasLowercaseLetter'),
    ),
  rePassword: Yup.string()
    .required(requiredParam('rePassword'))
    .oneOf([Yup.ref('password'), null], paramsDontMatch('password')),
  token: Yup.string(),
});

export const defaultValues = {
  password: '',
  rePassword: '',
  token: '',
};

export type ChangePassword = Yup.InferType<typeof schema>;
