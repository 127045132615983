import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PreviewWrapper = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${active
      ? theme.colors.neutral.black
      : theme.colors.skeleton.foregroundColor};
    transition: 0.5s;
  `}
`;

export const Header = styled.header<{ active: boolean }>`
  ${({ theme, active }) => css`
    padding: ${theme.spacing['2xl']};
    background-color: ${active
      ? theme.colors.primary.main
      : theme.colors.grayscale.dark.info};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    & > img {
      max-width: 300px;
      max-height: 200px;
    }
  `}
`;

export const Content = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.md};
    max-width: 537px;
    margin: auto;
  `}
`;
