import * as yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam, invalidParam } = messages;

export const schema = yup.object().shape({
  destination: yup
    .string()
    .email(invalidParam('email'))
    .required(requiredParam('email')),
});
