import {
  accreditationEN,
  accreditationES,
  accreditationPT,
} from './accreditation';
import { authEN, authES, authPT } from './auth';
import { eventEN, eventES, eventPT } from './events';
import {
  organizationEN,
  organizationES,
  organizationPT,
} from './organizations';
import { routesEN, routesES, routesPT } from './routes';

export const contextsPT = {
  ...routesPT,
  ...accreditationPT,
  ...authPT,
  ...eventPT,
  ...organizationPT,
};

export const contextsEN = {
  ...routesEN,
  ...accreditationEN,
  ...authEN,
  ...eventEN,
  ...organizationEN,
};

export const contextsES = {
  ...routesES,
  ...accreditationES,
  ...authES,
  ...eventES,
  ...organizationES,
};
