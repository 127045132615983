import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam } = messages;

export const schemaValidate = Yup.object().shape({
  email: Yup.string().email().required(requiredParam('email')),
  password: Yup.string().required(requiredParam('password')),
});

export const defaultValues = {
  email: '',
  password: '',
};
