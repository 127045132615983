import { useMemo, useState } from 'react';
import { EventStatus } from 'useCases/events';
import { useClickOutside, useTheme, useTranslate } from 'hooks';
import { Checkbox } from 'components/form';
import { Icon } from 'components/structure';
import * as S from './Filter.styles';

const allStatus = [
  EventStatus.ONGOING,
  EventStatus.FUTURE,
  EventStatus.FINISHED,
];

type FilterProps = {
  statuses: EventStatus[];
  onChange: (statuses: EventStatus[]) => void;
};

export const Filter = ({ onChange, statuses }: FilterProps) => {
  const translate = useTranslate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const ref = useClickOutside(() => setOpen(false));

  const handleChange = (newStatus: EventStatus, value: boolean) => {
    onChange(
      value
        ? [...statuses, newStatus]
        : statuses.filter((status) => status !== newStatus),
    );
  };

  const allChecked = useMemo(
    () => allStatus.every((status) => statuses.includes(status)),
    [statuses],
  );

  return (
    <S.IconButton>
      <Icon
        onClick={(ev) => {
          ev.stopPropagation();
          setOpen(!open);
        }}
        color={theme.colors.neutral.white}
        height="18px"
        width="18px"
        icon="IcFilter"
      />
      <S.Tooltip ref={ref} open={open}>
        <S.TooltipItem>
          <Checkbox
            checked={allChecked}
            onChange={(ev) => {
              const checked = ev.target.checked;
              if (!checked && allChecked) {
                onChange([]);
              } else {
                onChange(allStatus);
              }
            }}
            name="all"
            label={translate('event.eventList.all')}
          />
        </S.TooltipItem>
        <S.TooltipItem>
          <Checkbox
            checked={statuses.includes(EventStatus.ONGOING)}
            name="ongoing"
            label={translate('event.eventList.status.ongoing')}
            onChange={(ev) =>
              handleChange(EventStatus.ONGOING, ev.target.checked)
            }
          />
        </S.TooltipItem>
        <S.TooltipItem>
          <Checkbox
            checked={statuses.includes(EventStatus.FUTURE)}
            name="future"
            label={translate('event.eventList.status.future')}
            onChange={(ev) =>
              handleChange(EventStatus.FUTURE, ev.target.checked)
            }
          />
        </S.TooltipItem>
        <S.TooltipItem>
          <Checkbox
            checked={statuses.includes(EventStatus.FINISHED)}
            name="finished"
            label={translate('event.eventList.status.finished')}
            onChange={(ev) =>
              handleChange(EventStatus.FINISHED, ev.target.checked)
            }
          />
        </S.TooltipItem>
      </S.Tooltip>
    </S.IconButton>
  );
};
