import create from 'zustand';
import { persist } from 'zustand/middleware';

type EventStore = {
  eventId: string;
  addEventId: (value: string) => void;
};

const EVENT_ID = 'eventId';

export const useEventStore = create<EventStore>(
  persist(
    (set) => ({
      eventId: '',
      addEventId: (eventId) => set({ eventId }),
    }),
    {
      name: EVENT_ID,
    },
  ),
);
