export const sizes = {
  navbar: '65px',
  sidebar: '78px',
  editMenu: '221px',
  chat: '360px',
  footer: '80px',
  video: '1312px',
  input: '248px',
  portView: '1300px',
  navbarMobile: '56px',
  businessCard: '286px',
} as const;
