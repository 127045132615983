import styled, { css } from 'styled-components/macro';

type WrapperProps = {
  removeSpace?: boolean;
  backgroundImage?: string;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme }) => css`
    background: linear-gradient(
      180deg,
      ${theme.colors.background.outlet.private.light} -25.39%,
      ${theme.colors.background.outlet.private.dark} 111.46%
    );
    height: 100%;
    min-height: 100vh;
    transition: width 0.2s ease-in-out;
    display: grid;
    grid-template-areas: 'side head' 'side main';
    grid-template-rows: ${theme.sizes.navbar} 1fr;
    grid-template-columns: 78px 1fr;
    padding: 0;
    width: 100%;
  `}
`;

export const OutletWrapper = styled.main`
  ${() => css`
    grid-area: main;
  `}
`;
