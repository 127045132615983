import { ChangeEvent, InputHTMLAttributes, useRef, useState } from 'react';
import { useTheme } from 'hooks';
import { Button, Icon, IconsKeys } from 'components/structure';
import * as S from './FileInput.styles';

export type FileInputProps = {
  fileType: string;
  label: string;
  helperText?: string;
  error?: boolean;
  emptyText: string;
  onChangeFile?: (file: File) => void;
  icon?: IconsKeys;
  color?: 'primary' | 'secondary' | 'tertiary';
};

export const FileInput = ({
  fileType,
  label,
  helperText,
  emptyText,
  error,
  onChangeFile,
  icon,
  value,
  color = 'primary',
  ...props
}: FileInputProps & InputHTMLAttributes<HTMLInputElement>) => {
  const theme = useTheme();
  const input = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null | undefined>(undefined);

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    setFile(file);
    file && onChangeFile?.(file);
    props.onChange?.(event);
  };

  return (
    <S.Wrapper>
      <input
        {...props}
        type="file"
        accept={fileType}
        ref={input}
        value={value instanceof File ? value : ''}
        onChange={handleChangeFile}
      />
      <S.InputWrapper>
        {icon && <Icon icon={icon} color={theme.colors.grayscale.dark.info} />}
        <Button
          type="button"
          onClick={() => input.current?.click()}
          color={color}
        >
          {label}
        </Button>
        <S.FileName>
          {file?.name || (!(value instanceof File) && value) || emptyText}
        </S.FileName>
      </S.InputWrapper>
      <S.HelperText error={error}>{helperText}</S.HelperText>
    </S.Wrapper>
  );
};
