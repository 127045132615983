import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FieldWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing.md};
  `}
`;

export const ButtonsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin: 0 -${theme.spacing.xs};

    & > button {
      margin: ${theme.spacing.xs};
    }
  `}
`;
