import { useEventStore } from 'store';
import { useEvent } from 'useCases/events';
import { usePreventLeave, useTranslate } from 'hooks';
import { useModalContext, useEmailFormContext } from 'contexts';
import {
  BreadCrumb,
  Button,
  Divisor,
  Icon,
  SubTitle,
} from 'components/structure';
import { Tabs } from 'components/structure/Tabs/Tabs';
import { ROUTES } from 'constants/urls';
import * as S from './EmailTabs.styles';

type EmailsTabsProps = {
  form: string;
};

export const EmailsTabs = ({ form }: EmailsTabsProps) => {
  const translate = useTranslate();
  const { eventId } = useEventStore();
  const { preventLeave } = useEmailFormContext();
  const { data: event } = useEvent(eventId);
  const { setModalProps, setShowModal } = useModalContext();

  usePreventLeave({
    closeTabMessage: translate('event.editEvent.preventLeave.title'),
    preventDefaultInterceptor: (callback) => {
      if (preventLeave) {
        setModalProps({
          icon: <Icon icon="IcPencil" />,
          title: translate('event.editEvent.preventLeave.title'),
          description: translate('event.editEvent.preventLeave.description'),
          customButtons: (
            <Divisor hasMaxWidth>
              <Button
                color="secondary"
                onClick={() => {
                  setShowModal(false);
                  callback();
                }}
              >
                {translate('event.editEvent.preventLeave.exit')}
              </Button>
              <Button
                form={form}
                type="submit"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {translate('event.editEvent.preventLeave.save')}
              </Button>
            </Divisor>
          ),
        });
        setShowModal(true);
      } else {
        callback();
      }
    },
  });

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <SubTitle>
          {translate('event.editEvent.menu.accreditation.root')}
        </SubTitle>
        <BreadCrumb
          screens={[
            event?.name ?? '',
            translate('event.editEvent.menu.accreditation.email'),
          ]}
        />
        <Tabs
          variant="primary"
          tabs={[
            {
              title: translate('event.editEvent.emailForm.confirmation.title'),
              url: ROUTES.editEvent.getLink('emails'),
            },
            {
              title: translate('event.editEvent.emailForm.recoverPass.title'),
              url: ROUTES.editEvent.getLink('emails', undefined, 'recoverPass'),
            },
            {
              title: translate('event.editEvent.emailForm.welcome.title'),
              url: ROUTES.editEvent.getLink('emails', undefined, 'welcome'),
            },
          ]}
        />
      </S.HeaderWrapper>
    </S.Wrapper>
  );
};
