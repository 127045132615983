import { useEffect } from 'react';
import { PreventDefaultCallback, useSettingsContext } from 'contexts';

type usePreventLeaveProps = {
  closeTabMessage?: string;
  preventDefaultInterceptor?: PreventDefaultCallback;
};

export const usePreventLeave = ({
  closeTabMessage,
  preventDefaultInterceptor,
}: usePreventLeaveProps) => {
  const { setPreventCloseTabMessage, setPreventDefaultInterceptor } =
    useSettingsContext();
  useEffect(() => {
    setPreventCloseTabMessage(closeTabMessage);
    setPreventDefaultInterceptor(preventDefaultInterceptor);
    return () => {
      setPreventDefaultInterceptor(undefined);
      setPreventCloseTabMessage(undefined);
    };
  }, [
    closeTabMessage,
    preventDefaultInterceptor,
    setPreventCloseTabMessage,
    setPreventDefaultInterceptor,
  ]);
};
