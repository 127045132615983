import { PropsWithChildren } from 'react';
import { useEventStore } from 'store';
import { useEvent } from 'useCases/events';
import { useTranslate } from 'hooks';
import { BreadCrumb, SubTitle } from 'components/structure';
import * as S from './SectionLayout.styles';

export type SectionLayoutProps = {
  breadcrumb: string[];
  subTitle: string;
};

export const SectionLayout = ({
  subTitle,
  breadcrumb,
  children,
}: PropsWithChildren<SectionLayoutProps>) => {
  const translate = useTranslate();
  const { eventId } = useEventStore();
  const { data: event } = useEvent(eventId);

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <SubTitle>{translate(subTitle)}</SubTitle>
        <BreadCrumb
          screens={[
            event?.name ?? '',
            ...breadcrumb.map((item) => translate(item)),
          ]}
        />
      </S.HeaderWrapper>
      <S.ContentWrapper>{children}</S.ContentWrapper>
    </S.Wrapper>
  );
};
