import { forwardRef, HTMLAttributes } from 'react';
import { Icon } from 'components/structure';
import * as S from './Interest.styles';

export type InterestProps = {
  isDraggable?: boolean;
  value: string;
  selected?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Interest = forwardRef<HTMLDivElement, InterestProps>(
  ({ isDraggable, value, selected, ...props }, ref) => (
    <S.Wrapper ref={ref} {...props}>
      {isDraggable && <Icon icon="IcDrag" />}
      <S.Content selected={selected}>
        <span>{value}</span>
      </S.Content>
    </S.Wrapper>
  ),
);
