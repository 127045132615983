import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { invalidParam, requiredParam } = messages;

export const schema = Yup.object().shape({
  email: Yup.string()
    .email(invalidParam('email'))
    .required(requiredParam('email')),
});

export const defaultValues = {
  email: '',
};

export type ForgotPassword = Yup.InferType<typeof schema>;
