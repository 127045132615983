import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_ERRORS, INVALID_PASSWORD } from 'constants/enums';

interface ErrorResponse {
  status: number;
  data: {
    error: string | undefined;
    message: string;
  };
}

export const useRecaptcha = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const onRecaptchaError = (errorResponse: ErrorResponse | undefined) => {
    if (!errorResponse) return;

    const { status, data } = errorResponse;

    if (
      status === RECAPTCHA_ERRORS.TOO_MANY_TRIES ||
      data?.message === RECAPTCHA_ERRORS.TOKEN_IS_MISSING
    ) {
      setShowRecaptcha(true);
      recaptchaRef.current?.reset();
    }

    if (showRecaptcha && data?.message === INVALID_PASSWORD) {
      recaptchaRef.current?.reset();
    }

    if (data?.error === RECAPTCHA_ERRORS.TOKEN_IS_INVALID) {
      recaptchaRef.current?.reset();
    }
  };

  return { recaptchaRef, showRecaptcha, onRecaptchaError };
};
