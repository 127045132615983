import { useOrganizationStore } from 'store';
import { useEvents } from 'useCases/events';
import { useTranslate } from 'hooks';
import { Events, OrganizationRoot } from 'components/contexts';
import { ROUTES } from 'constants/urls';

export const EventsRoot = () => {
  const translate = useTranslate();
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const { data: events, isLoading } = useEvents({
    organizationId,
  });

  // TODO add skeleton
  if (isLoading) {
    return <span>Loading...</span>;
  }

  return events?.length ? (
    <Events />
  ) : (
    <OrganizationRoot
      title={translate('event.firstEvent.createFirstEvent')}
      description={translate('event.firstEvent.description')}
      buttonLabel={translate('event.firstEvent.createEvent')}
      buttonAction={ROUTES.events.getLink('root')}
    />
  );
};
