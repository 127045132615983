import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import {
  IntlProvider,
  ModalProvider,
  ToastProvider,
  OrganizationProvider,
  EventProvider,
} from 'contexts';
import GlobalStyles from 'styles/global';
import theme from 'styles/theme';
import { WithChildren } from 'interfaces/children';

export const AppContexts = ({ children }: WithChildren) => (
  <ThemeProvider theme={theme}>
    <IntlProvider>
      <ToastProvider>
        <ModalProvider>
          <OrganizationProvider>
            <EventProvider>
              <GlobalStyles />
              <HelmetProvider>{children}</HelmetProvider>
            </EventProvider>
          </OrganizationProvider>
        </ModalProvider>
      </ToastProvider>
    </IntlProvider>
  </ThemeProvider>
);
