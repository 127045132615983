import {
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';
import { useEventStore } from 'store';
import { useCopyEmail, useEmails } from 'useCases/emails';
import { useTranslate } from 'hooks';
import { useModalContext } from 'contexts';
import { Button, Icon, Heading, Divisor } from 'components/structure';
import { WithChildren } from 'interfaces/children';
import {
  EditEventEmailsCategory,
  EmailFormFilled,
  EmailFormInputsType,
  EmailNames,
  EmailRequested,
} from 'interfaces/email';

type EmailFormContextType = {
  preventLeave: boolean;
  setPreventLeave: Dispatch<SetStateAction<boolean>>;
  filledData?: EmailFormFilled;
  setFilledData: Dispatch<SetStateAction<EmailFormFilled | undefined>>;
  inputData?: EmailFormInputsType;
  setInputData: Dispatch<SetStateAction<EmailFormInputsType | undefined>>;
  isLoading: boolean;
  email?: EmailRequested;
  setFormData: (
    filledData: EmailFormFilled,
    inputsData: EmailFormInputsType,
  ) => void;
};

const defaultValue: EmailFormContextType = {
  preventLeave: false,
  setPreventLeave: () => false,
  setFilledData: () => false,
  setInputData: () => false,
  isLoading: false,
  setFormData: () => null,
};

const EmailFormContext = createContext<EmailFormContextType>(defaultValue);
let lastEmail: EmailRequested | undefined;
let lastForm: EditEventEmailsCategory | undefined;

export const EmailFormProvider = ({
  children,
  form,
}: { form: EditEventEmailsCategory } & WithChildren) => {
  const translate = useTranslate();
  const { setModalProps, setShowModal } = useModalContext();

  const [preventLeave, setPreventLeave] = useState(defaultValue.preventLeave);
  const [filledData, setFilledData] = useState<EmailFormFilled>();
  const [inputData, setInputData] = useState<EmailFormInputsType>();
  const { eventId } = useEventStore();
  const { data: emails, isLoading } = useEmails(eventId);
  const { isPending: isPendingCopy, handleCopyEmail } = useCopyEmail();
  const [email, setEmail] = useState(
    emails?.find?.((email) => email.category === form),
  );

  const setFormData = useCallback(
    (filledData: EmailFormFilled, inputsData: EmailFormInputsType) => {
      setFilledData(filledData);

      const headerImagePieces = inputsData.headerImage.split(/\\|\//);

      setInputData({
        ...inputsData,
        headerImage: headerImagePieces[headerImagePieces.length - 1],
      });
    },
    [],
  );

  useEffect(() => {
    if (email) {
      setFormData(
        {
          button: email.button,
          footer: email.baseBoard,
          paragraph: email.paragraph,
          socialNetworks: [],
          title: email.title,
          headerImage: email.imageUrl,
          id: email.id,
          subject: email.subject,
        },
        {
          button: email.button,
          footer: email.baseBoard,
          paragraph: email.paragraph,
          socialNetworks: [],
          title: email.title,
          headerImage: email.imageUrl,
          subject: email.subject,
        },
      );
    }
  }, [email, setFormData]);

  useEffect(() => {
    if (!email && lastEmail?.id && lastForm) {
      setModalProps({
        icon: <Icon icon="IcCopy" />,
        title: `${translate('event.editEvent.emailForm.copyModal.title')}`,
        description: (
          <Heading>
            {translate('event.editEvent.emailForm.copyModal.emailOf')}
            {translate(EmailNames[lastForm])}?
          </Heading>
        ),
        customButtons: (
          <Divisor hasMaxWidth>
            <Button
              isLoading={isPendingCopy}
              disabled={isPendingCopy}
              onClick={() => {
                if (lastEmail) {
                  handleCopyEmail(lastEmail.id, form, (emailCreated) => {
                    setEmail(emailCreated);
                    setShowModal(false);
                  });
                }
              }}
            >
              {translate('event.editEvent.emailForm.copyModal.confirm')}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              {translate('event.editEvent.emailForm.copyModal.cancel')}
            </Button>
          </Divisor>
        ),
      });
      setShowModal(true);
    }
  }, [
    email,
    form,
    handleCopyEmail,
    isPendingCopy,
    setModalProps,
    setShowModal,
    translate,
  ]);

  useEffect(() => {
    if (form !== lastForm) {
      return () => {
        lastEmail = email;
      };
    }
  }, [email, form]);

  useEffect(
    () => () => {
      lastForm = form;
    },
    [form],
  );

  useEffect(() => {
    setEmail(emails?.find?.((email) => email.category === form));
  }, [emails, form]);

  return (
    <EmailFormContext.Provider
      value={{
        preventLeave,
        setPreventLeave,
        filledData,
        setFilledData,
        inputData,
        setInputData,
        isLoading,
        email,
        setFormData,
      }}
    >
      {children}
    </EmailFormContext.Provider>
  );
};

export const useEmailFormContext = () => useContext(EmailFormContext);
