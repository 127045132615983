import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam } = messages;

export const schemaValidate = Yup.object().shape({
  isClosed: Yup.boolean().required(requiredParam('category')),
  accessType: Yup.string().required(requiredParam('accessType')),
});

export const defaultValues = {
  isClosed: undefined,
  accessType: undefined,
};
