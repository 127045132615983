import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { usePreventLeave, useTranslate } from 'hooks';
import {
  NewEventProvider,
  useModalContext,
  useNewEventContext,
} from 'contexts';
import { ProgressBar } from 'components/contexts';
import {
  BreadCrumb,
  Button,
  Icon,
  Paragraph,
  SubTitle,
} from 'components/structure';
import { ROUTES } from 'constants/urls';
import * as S from './NewEvent.styles';

export const NewEvent = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { newEventProps } = useNewEventContext();
  const { setModalProps, setShowModal } = useModalContext();

  usePreventLeave({
    closeTabMessage: translate('event.newEvent.preventDefaultModal.title'),
    preventDefaultInterceptor: (callback) => {
      setModalProps({
        icon: <Icon icon="IcPencil" />,
        title: translate('event.newEvent.preventDefaultModal.title'),
        description: translate(
          'event.newEvent.preventDefaultModal.description',
        ),
        customButtons: (
          <S.ButtonContainer>
            <Button color="secondary" onClick={() => setShowModal(false)}>
              {translate('event.newEvent.preventDefaultModal.cancel')}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setShowModal(false);
                callback && callback();
              }}
            >
              {translate('event.newEvent.preventDefaultModal.confirm')}
            </Button>
          </S.ButtonContainer>
        ),
      });
      setShowModal(true);
    },
  });

  useEffect(() => {
    if (!newEventProps.name) {
      navigate(ROUTES.events.getLink('root'));
    }
  }, [navigate, newEventProps.name]);

  return (
    <NewEventProvider>
      <S.Wrapper>
        <BreadCrumb
          screens={[translate('event.events'), translate('event.newEventKey')]}
          isAbsolute
        />
        <SubTitle>{translate('event.newEvent.title')}</SubTitle>
        <Paragraph>{translate('event.newEvent.description')}</Paragraph>
        <ProgressBar />
        <Outlet />
      </S.Wrapper>
    </NewEventProvider>
  );
};
