import { forwardRef, useState, useEffect } from 'react';
import { useTranslate } from 'hooks';
import { Switch } from 'components/form';
import {
  DraggableItem,
  DraggableList,
  Icon,
  Paragraph,
} from 'components/structure';
import { DefaultTermsLabel } from './DefaultTermsLabel';
import * as S from './TermsPreview.styles';

type Term = { name: string; description: string };

type TermsPreviewProps = {
  terms: Array<Term>;
  handleSwapItems?: (from: number, to: number) => void;
};

type TermProps = {
  isDraggable?: boolean;
  value: Term;
  selected?: boolean;
};

const Term = forwardRef<HTMLDivElement, TermProps>(
  ({ value, isDraggable, selected, ...props }, ref) => (
    <S.TermWrapper ref={ref} {...props}>
      {isDraggable && <Icon icon="IcDrag" height="16px" />}
      <S.SwitchWrapper>
        <Switch
          name={value.name}
          label={<Paragraph margin="0">{value.description}</Paragraph>}
          defaultChecked={selected}
        />
      </S.SwitchWrapper>
    </S.TermWrapper>
  ),
);

export const TermsPreview = ({ terms, handleSwapItems }: TermsPreviewProps) => {
  const translate = useTranslate();
  const [termsList, setTermsList] = useState<Array<DraggableItem<Term>>>([]);

  useEffect(() => {
    setTermsList(
      terms.map((term, index) => ({
        code: index,
        value: term,
        priority: index,
        selected: true,
      })),
    );
  }, [terms]);

  return (
    <S.Wrapper>
      <Paragraph>
        {translate('event.editEvent.termsForm.others.preview.title')}
      </Paragraph>
      <S.ListWrapper>
        <S.SwitchWrapper>
          <Switch
            name="switch"
            label={<DefaultTermsLabel />}
            defaultChecked={true}
          />
        </S.SwitchWrapper>
        <DraggableList
          items={termsList}
          setItems={setTermsList}
          element={Term}
          handleSwapItems={handleSwapItems}
        />
      </S.ListWrapper>
    </S.Wrapper>
  );
};
