import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Form = styled.form`
  ${() => css`
    display: flex;
    width: 100%;
    height: 100%;

    & > * {
      flex: 1;
    }

    ${media.lessThan('large')`
      flex-direction: column;
    `}
  `}
`;

export const EditAreaWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm};
    width: 100%;
    max-width: 458px;
  `}
`;

export const PreviewAreaWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm};
    background-color: ${theme.colors.neutral.filter};

    ${media.greaterThan('large')`
      margin-top: -140px;
    `}
  `}
`;

export const ActionButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.sm};
    ${media.greaterThan('large')`
      position: absolute;
      right: ${theme.spacing.sm};
      bottom: ${theme.spacing.sm};
    `};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    & > * {
      padding: ${theme.spacing.sm} 0;
    }
  `}
`;
