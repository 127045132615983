import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useConfirmRegistration } from 'useCases';

export const ConfirmRegistration = () => {
  const { handleConfirmRegistration } = useConfirmRegistration();
  const { token } = useParams();

  useEffect(() => {
    handleConfirmRegistration(token);
  }, [handleConfirmRegistration, token]);

  return <></>;
};
