import styled, { css } from 'styled-components';
import { Paragraph, TooltipProps } from 'components/structure';

const tooltipModifiers = {
  bottom: () => css`
    ::after {
      left: 23px;
      top: -8px;
      transform: rotate(135deg);
    }
  `,
};

export const Tooltip = styled.div<Pick<TooltipProps, 'position'>>`
  ${({ theme, position }) => css`
    display: flex;
    align-items: center;
    min-height: 61px;
    min-width: 200px;
    position: absolute;
    background ${theme.colors.neutral.black};
    border-radius: ${theme.shapes.borderRadius.sm};
    border: 1px solid ${theme.colors.grayscale.dark.line};
    transition: all 0.3s ease-in-out, opacity 0.3s 0.05s linear;
    pointer-events: none;
    z-index: 2;
    ${Paragraph} {
      background ${theme.colors.neutral.black};
      font-size: ${theme.typography.sizes.xs};
      line-height: ${theme.typography.lineHeight.sm};
      margin: ${theme.spacing.xs};
      width: 100%;
      height: 100%;
    }
     ::after {
      z-index: 1;
      content: '';
      width: 15px;
      height: 15px;
      background-color: ${theme.colors.neutral.black};
      border-left: 1px solid ${theme.colors.grayscale.dark.line};
      border-bottom: 1px solid ${theme.colors.grayscale.dark.line};
      position: absolute;
    }
    ${tooltipModifiers[position]()}
  `}
`;
