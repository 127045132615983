import styled, { css, DefaultTheme } from 'styled-components';
import { ActionsButtonProps } from './ActionsButton';

type WrapperProps = Pick<ActionsButtonProps, 'isAbsolute'>;

const wrapperModifiers = {
  isAbsolute: (theme: DefaultTheme) => css`
    position: absolute;
    right: ${theme.spacing.xs};
    bottom: ${theme.spacing.xs};
    background: ${theme.colors.primary.main};
    width: 48px;
    height: 48px;

    > svg {
      width: 24px;
      height: 24px;
    }
  `,
};

export const Wrapper = styled.button<WrapperProps>`
  ${({ theme, isAbsolute }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.grayscale.dark.line};
    border-radius: 50%;
    margin: ${theme.spacing.xs};
    width: 28px;
    height: 28px;
    transition: opacity 0.3s ease-in-out;

    > svg {
      color: ${theme.colors.neutral.white};
      width: 12px;
      height: 12px;
    }

    &:disabled {
      opacity: 0.6;
    }
    &:hover,
    &:active {
      border: 1px solid ${theme.colors.primary.main};
    }

    ${isAbsolute && wrapperModifiers.isAbsolute(theme)}
  `}
`;
