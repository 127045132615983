import { Outlet } from 'react-router';
import { useTranslate } from 'hooks';
import { Paragraph, SubTitle } from 'components/structure';
import logoWhite from 'assets/images/core/logo-white.png';
import * as S from './Routes.styles';

export const PublicRoutes = () => {
  const translate = useTranslate();

  return (
    <S.Wrapper>
      <S.Apresentation>
        <img src={logoWhite} />
        <SubTitle>{translate('routes.welcome.title')}</SubTitle>
        <Paragraph>{translate('routes.welcome.description')}</Paragraph>
      </S.Apresentation>

      <S.OutletWrapper>
        <Outlet />
      </S.OutletWrapper>
    </S.Wrapper>
  );
};
