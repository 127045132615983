import { useTranslate } from 'hooks';
import { Paragraph } from 'components/structure';
import * as S from './ImagesPreview.styles';

export type ImagesPreviewProps = {
  logoImage?: string;
  heroImage?: string;
};

export const ImagesPreview = ({ logoImage, heroImage }: ImagesPreviewProps) => {
  const translate = useTranslate();

  return (
    <S.Wrapper>
      <Paragraph>
        {translate('event.editEvent.imagesForm.imagesPreview')}
      </Paragraph>
      <S.PreviewWrapper>
        <S.CredentialScreen>
          {logoImage && <S.Logo url={logoImage} />}
          {heroImage && <S.Hero url={heroImage} />}
        </S.CredentialScreen>
      </S.PreviewWrapper>
    </S.Wrapper>
  );
};
