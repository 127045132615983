import { useSignIn } from 'useCases';
import { SignInForm } from 'components/contexts';

export const SignIn = () => {
  const { handleAuthentication, isPending, recaptchaRef, showRecaptcha } =
    useSignIn();

  return (
    <SignInForm
      onSubmit={handleAuthentication}
      isPending={isPending}
      recaptchaRef={recaptchaRef}
      showRecaptcha={showRecaptcha}
    />
  );
};
