import { cloneElement } from 'react';
import { useFieldArray, UseFormMethods } from 'react-hook-form';
import { Icon, Paragraph } from 'components/structure';
import * as S from './FieldArray.styles';

export type FieldArrayProps = {
  control: UseFormMethods['control'];
  name: string;
  input: React.ReactElement;
  labels?: Array<string>;
  labelAdd: string;
  watch?: Array<{ [x: string]: unknown }>;
  register: UseFormMethods['register'];
  onChange?: (value: string, index: number) => void;
  errors?: UseFormMethods['errors'];
  errorParser?: (id: string) => string;
  onRemove?: (index: number) => void;
};

export const FieldArray = ({
  control,
  name,
  input,
  labels,
  labelAdd,
  watch,
  register,
  onChange,
  errors,
  errorParser,
  onRemove,
}: FieldArrayProps) => {
  const { fields, append, remove } = useFieldArray({ control, name });

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watch?.[index],
  }));

  return (
    <>
      {controlledFields.map((field, index) => (
        <S.InputWrapper key={field.id}>
          {cloneElement(input, {
            ref: register({}),
            label: labels?.[index],
            name: `${name}[${index}].value`,
            defaultValue: field.value,
            onChange: () => onChange?.(field.value, index),
            error: !!errors?.[index],
            helperText:
              errorParser?.(errors?.[index]?.value.message) ||
              errors?.[index]?.value.message,
          })}
          {fields.length > 1 && (
            <S.ButtonWrapper>
              <S.IconButton
                type="button"
                onClick={() => {
                  onRemove?.(index);
                  remove(index);
                }}
              >
                <Icon icon="IcDelete" />
              </S.IconButton>
            </S.ButtonWrapper>
          )}
        </S.InputWrapper>
      ))}
      <S.ButtonWrapper>
        <S.IconButton type="button" onClick={() => append({ value: '' })}>
          <Icon icon="IcAdd" />
        </S.IconButton>
        <Paragraph>{labelAdd}</Paragraph>
      </S.ButtonWrapper>
    </>
  );
};
