import { AxiosRequestConfig } from 'axios';
import useSWR, { Key, SWRConfiguration } from 'swr';
import { useAPI } from './useAPI';

export const useFetch = <Data = unknown, Error = unknown>(
  url: Key,
  axiosConfigs?: AxiosRequestConfig | undefined,
  config?: SWRConfiguration<Data, Error>,
) => {
  const api = useAPI();

  const { data, error, mutate, revalidate, isValidating } = useSWR<Data, Error>(
    url,
    async (url) => {
      const response = await api.get(url, axiosConfigs);

      return response.data;
    },
    config,
  );

  return {
    data,
    error,
    isError: !!error,
    isLoading: !error && !data && String(data) !== '',
    isValidating,
    mutate,
    revalidate,
  };
};
