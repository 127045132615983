import styled, { css } from 'styled-components';
import credentialLogoInputImage from 'assets/images/events/customization/credential-logo-input-image.svg';

type LogoProps = {
  url: string;
};

type HeroProps = {
  url: string;
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    color: ${theme.colors.neutral.white};
    padding: ${theme.spacing.lg} ${theme.spacing.md};
    transition: opacity 0.3s ease-in-out;
  `}
`;

export const PreviewWrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.grayscale.dark.line};
    border-radius: ${theme.shapes.borderRadius.xs};
    padding: 47px;
    margin: auto;
  `};
`;

export const CredentialScreen = styled.div`
  background-image: url(${credentialLogoInputImage});
  width: 605px;
  height: 310px;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
`;

export const Logo = styled.div<LogoProps>`
  ${({ theme, url }) =>
    css`
      position: absolute;
      top: 9px;
      left: 11px;

      background-image: url(${url});
      background-size: contain;
      background-repeat: no-repeat;
      background-color: ${theme.colors.neutral.white};
      width: 150px;
      height: 50px;
    `}
`;

export const Hero = styled.div<HeroProps>`
  ${({ theme, url }) => css`
    position: absolute;
    width: 282px;
    height: 310px;
    top: 0;
    right: 0;

    background: url(${url});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: ${theme.colors.neutral.black};
  `}
`;
