import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam } = messages;

export const schemaValidate = Yup.object().shape({
  name: Yup.string().required(requiredParam('organizationName')),
  file: Yup.mixed().required(requiredParam('file')),
});

export const defaultValues = {
  name: '',
};
