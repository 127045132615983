import styled, { css, DefaultTheme } from 'styled-components';
import { FormContentProps } from './FormContent';

type ModifierParams = {
  theme: DefaultTheme;
};

const wrapperModifiers = {
  show: ({ theme }: ModifierParams) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${theme.spacing.sm};
    margin-bottom: ${theme.spacing.sm};
  `,
};

export const Wrapper = styled.div<Pick<FormContentProps, 'show'>>`
  ${({ show, theme }) => css`
    display: none;
    ${show && wrapperModifiers.show({ theme })}
  `}
`;
