import { useNavigate } from 'react-router-dom';
import { useEventStore, useOrganizationStore } from 'store';
import { mutate } from 'swr';
import {
  Event,
  EventStatus as EventStatusType,
  useDeleteEvent,
} from 'useCases/events';
import { useTranslate } from 'hooks';
import { getEventStatus } from 'lib/contexts/events';
import { EventCard } from 'components/contexts/event/EventCard/EventCard';
import { EmptyState } from 'components/contexts/event/EventList/EmptyState/EmptyState';
import { EventStatus } from 'components/contexts/event/EventStatus/EventStatus';
import { SearchField } from 'components/form';
import { Button, Paragraph } from 'components/structure';
import { EVENTS } from 'constants/endpoints';
import { AccessTypeEvent } from 'constants/enums';
import { ROUTES } from 'constants/urls';
import * as S from './EventList.styles';
import { Filter } from './Filter/Filter';

export type EventListProps = {
  events: Event[];
  search: string;
  onSearch: (search: string) => void;
  statuses: EventStatusType[];
  onFilter: (statuses: EventStatusType[]) => void;
  onResetFilter: () => void;
};

export const EventList = ({
  events,
  search,
  statuses,
  onSearch,
  onFilter,
  onResetFilter,
}: EventListProps) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const { addEventId } = useEventStore();
  const { deleteEvent } = useDeleteEvent();

  const handleEdit = (eventId: string) => {
    addEventId(eventId);
    navigate(ROUTES.editEvent.getLink('root'));
  };

  const handleDelete = async (eventId: string) => {
    await deleteEvent({
      eventId: eventId,
      organizationId,
    });
    mutate(
      [EVENTS.SEARCH(search, statuses), organizationId],
      async (events: Event[]) => events.filter(({ id }) => id !== eventId),
      false,
    );
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.FilterContainer>
          <Paragraph bold center>
            {translate('event.eventList.legend')}
          </Paragraph>
          <Filter
            statuses={statuses}
            onChange={(statuses) => onFilter(statuses)}
          />
        </S.FilterContainer>
        <S.SearchContainer>
          <S.Legend>
            <div>
              <EventStatus status={EventStatusType.ONGOING} />
              <Paragraph lowercase center>
                {translate('event.eventList.status.ongoing')}
              </Paragraph>
            </div>
            <div>
              <EventStatus status={EventStatusType.FUTURE} />
              <Paragraph lowercase center>
                {translate('event.eventList.status.future')}
              </Paragraph>
            </div>
            <div>
              <EventStatus status={EventStatusType.FINISHED} />
              <Paragraph lowercase center>
                {translate('event.eventList.status.finished')}
              </Paragraph>
            </div>
          </S.Legend>
          <SearchField
            placeholder={translate('event.eventList.search')}
            value={search}
            onChange={(ev) => onSearch(ev.target.value)}
          />
          <Button onClick={() => navigate(ROUTES.events.getLink('root'))}>
            {translate('event.eventList.newEvent')}
          </Button>
        </S.SearchContainer>
      </S.Header>
      {events.length === 0 ? (
        <EmptyState onClick={onResetFilter} />
      ) : (
        <S.EventContainer>
          {events.map((event) => (
            <EventCard
              key={event.id}
              name={event.name}
              status={getEventStatus(event)}
              startDate={event.startedAt}
              isFree={event.accessType === AccessTypeEvent.FREE}
              onEdit={() => handleEdit(event.id)}
              onDelete={() => handleDelete(event.id)}
            />
          ))}
        </S.EventContainer>
      )}
    </S.Wrapper>
  );
};
