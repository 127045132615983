import { useOrganizationContext } from 'contexts';
import { OrganizationRoot } from 'components/contexts';
import { ROUTES } from 'constants/urls';

export const Organizations = () => {
  const { hasOrganizations, isLoading } = useOrganizationContext();

  // TODO add skeleton
  if (isLoading) {
    return <span>Loading...</span>;
  }

  return hasOrganizations ? (
    <OrganizationRoot
      title="organization.selectOrganization.title"
      description="organization.selectOrganization.description"
    />
  ) : (
    <OrganizationRoot
      title="organization.firstOrganization.createFirstOrganization"
      description="organization.firstOrganization.description"
      buttonLabel="organization.firstOrganization.createOrganization"
      buttonAction={ROUTES.organizations.getLink('create')}
    />
  );
};
