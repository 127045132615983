export const languages = [
  {
    label: 'labels.pt',
    value: 'pt',
  },
  {
    label: 'labels.es',
    value: 'es',
  },
  {
    label: 'labels.en',
    value: 'en',
  },
];
