import { createContext, useContext, useEffect, useState } from 'react';
import { Toast, ToastProps } from 'components/structure';
import { WithChildren } from 'interfaces/children';

export type ToastContextProps = Omit<ToastProps, 'setShow' | 'show'>;

type ToastContextData = {
  show?: boolean;
  toast?: ToastContextProps;
  setToast: (toast: ToastContextProps) => void;
  setShow: (show: boolean) => void;
};

export const ToastContext = createContext<ToastContextData>({
  show: false,
  toast: {},
  setToast: () => undefined,
  setShow: () => undefined,
});

type ToastProviderProps = WithChildren;

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [show, setShow] = useState(false);
  const [toast, setToast] = useState<ToastContextProps>({});

  useEffect(() => {
    if (toast.description) {
      const timeout = setTimeout(() => setShow(true), 300);

      return () => clearTimeout(timeout);
    }
  }, [toast]);

  return (
    <ToastContext.Provider value={{ toast, setToast, show, setShow }}>
      {children}
      <Toast
        {...toast}
        show={show}
        setShow={setShow}
        handleClose={() => setShow(false)}
      />
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

ToastContext.displayName = 'ToastContext';
