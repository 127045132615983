import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

type TypographyProps = {
  lowercase?: boolean;
  uppercase?: boolean;
  center?: boolean;
  italic?: boolean;
  light?: boolean;
  dark?: boolean;
  bold?: boolean;
  brand?: boolean;
  opacity?: number;
  margin?: string;
};

const typographyModifiers = {
  lowercase: () => css`
    text-transform: lowercase;
  `,
  uppercase: () => css`
    text-transform: uppercase;
  `,
  center: () => css`
    text-align: center;
  `,
  italic: () => css`
    font-style: italic;
  `,
  light: (theme: DefaultTheme) => css`
    color: ${theme.colors.neutral.white};
  `,
  dark: (theme: DefaultTheme) => css`
    color: ${theme.colors.neutral.black};
  `,
  bold: () => css`
    font-weight: bold;
  `,
  brand: (theme: DefaultTheme) => css`
    color: ${theme.colors.primary.main};
  `,
  margin: (margin: string, theme: DefaultTheme) => css`
    margin: ${margin ?? `0 0 ${theme.spacing.sm}`};
  `,
};

const applyModifiers = ({
  lowercase,
  uppercase,
  center,
  italic,
  light,
  dark,
  bold,
  brand,
  margin,
}: TypographyProps) => css`
  ${({ theme }) => css`
    ${lowercase && typographyModifiers.lowercase()}
    ${uppercase && typographyModifiers.uppercase()}
    ${center && typographyModifiers.center()}
    ${italic && typographyModifiers.italic()}
    ${light && typographyModifiers.light(theme)}
    ${dark && typographyModifiers.dark(theme)}
    ${bold && typographyModifiers.bold()}
    ${brand && typographyModifiers.brand(theme)}
    ${margin && typographyModifiers.margin(margin, theme)}
  `}
`;

export const Heading = styled.h2<TypographyProps>`
  ${({ theme, ...props }) => css`
    color: ${theme.colors.grayscale.dark.title};
    font-size: ${theme.typography.sizes.xl};
    font-family: ${theme.typography.family.secondary};
    line-height: ${theme.typography.lineHeight.md};
    font-weight: bold;
    margin-bottom: ${theme.spacing.sm};

    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacing.lg};
    `}

    ${applyModifiers(props)};
  `}
`;

export const SubTitle = styled.h3<TypographyProps>`
  ${({ theme, ...props }) => css`
    color: ${theme.colors.grayscale.dark.title};
    font-size: ${theme.typography.sizes.lg};
    font-family: ${theme.typography.family.secondary};
    font-weight: bold;
    line-height: ${theme.typography.lineHeight.md};
    margin-bottom: ${theme.spacing.sm};

    ${media.lessThan('medium')`
      font-size: ${theme.typography.sizes.md};
      line-height: ${theme.typography.lineHeight.base};
    `}

    ${applyModifiers(props)};
  `}
`;

export const Paragraph = styled.p<TypographyProps>`
  ${({ theme, opacity = 1, ...props }) => css`
    color: ${theme.colors.grayscale.dark.paragraph};
    font-size: ${theme.typography.sizes.base};
    font-family: ${theme.typography.family.primary};
    font-weight: 400;
    line-height: ${theme.typography.lineHeight.base};
    opacity: ${opacity};
    letter-spacing: 0.5px;
    margin-bottom: ${theme.spacing.sm};

    ${applyModifiers(props)};
  `}
`;

export const Anchor = styled.a<TypographyProps>`
  ${({ theme, ...props }) => css`
    color: ${theme.colors.secondary.main};
    font-size: ${theme.typography.sizes.sm};
    font-family: ${theme.typography.family.secondary};
    font-weight: 400;
    transition: all 0.2s linear;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: ${theme.colors.secondary.hover};
    }

    ${applyModifiers(props)};
  `}
`;
