import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  `}
`;

export const HeaderWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.sm};
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;

  & > * {
    flex: 1;
  }

  ${media.lessThan('large')`
    flex-direction: column;
  `}
`;
