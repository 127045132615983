import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: flex-start;
  `}
`;

export const Switch = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing['2xs']};
    margin-right: ${theme.spacing.xs};
    border-radius: 7px;
    min-width: 22px;
    max-width: 22px;
    height: 13px;
    position: relative;
    cursor: pointer;
    transition: 500ms;
    background-color: ${theme.colors.contextual.disabled.main};

    & > div {
      position: absolute;
      top: 3.75px;
      left: 3.75px;
      border-radius: 5.5px;
      width: 5.5px;
      height: 5.5px;
      transition: 500ms;
      background-color: ${theme.colors.neutral.black};
    }
  `}
`;

export const Label = styled.label`
  ${() => css`
    display: inherit;
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    display: none;
    &:checked + ${Label} > ${Switch} {
      background-color: ${theme.colors.primary.main};

      & > div {
        transform: translateX(9px);
      }
    }
  `}
`;
