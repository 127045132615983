import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as ActionsButton from 'components/structure/ActionsButton/ActionsButton.styles';
import * as Form from './Form/Form.styles';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;

  ${media.lessThan('huge')`
      grid-template-columns: 1fr 1.1fr;
  `}
`;

const asideModifiers = {
  hasInterests: () => css`
    ${ActionsButton.Wrapper}, ${Form.Wrapper} {
      opacity: 1;
      visibility: visible;
    }
  `,
};

export const Aside = styled.aside<{ hasInterests: boolean }>`
  ${({ theme, hasInterests }) => css`
    height: calc(100vh - ${theme.sizes.navbar});
    position: relative;

    ${ActionsButton.Wrapper}, ${Form.Wrapper} {
      opacity: 0;
      visibility: hidden;
    }

    ${hasInterests && asideModifiers.hasInterests()}
  `}
`;

export const Scrollable = styled.div`
  ${({ theme }) => css`
    height: 100%;
    overflow-y: auto;
    width: 100%;
    padding: ${theme.spacing.sm};
  `}
`;

export const IconButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    margin-left: 10px;

    svg {
      color: ${theme.colors.grayscale.dark.info};
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > :first-child {
      margin-bottom: 0px;
    }
    margin-bottom: ${theme.spacing.lg};
  `}
`;
