import { useTheme, useTranslate } from 'hooks';
import { Button, Icon, Paragraph, SubTitle } from 'components/structure';
import { SocialNetwork } from 'interfaces/social-network';
import * as S from './EmailPreview.styles';
import { SocialNetworks } from './SocialNetworks/SocialNetworks';

export type EmailPreviewProps = {
  headerImage?: string;
  title?: string;
  paragraph?: string;
  button?: string;
  socialNetworks?: Array<SocialNetwork>;
  footer?: string;
};

const defaultValues: EmailPreviewProps = {
  title: 'Texto do título',
  paragraph: 'Texto do parágrafo',
  button: 'texto do botão',
  socialNetworks: [0, 1, 2, 3].map(() => ({
    icon: 'IcIco',
    url: '',
  })),
  footer: 'Texto do rodapé',
};

export const EmailPreview = ({
  headerImage,
  title,
  paragraph,
  button,
  socialNetworks,
  footer,
}: EmailPreviewProps) => {
  const theme = useTheme();
  const translate = useTranslate();

  const hasContent = !!title || !!paragraph || !!socialNetworks || !!footer;

  return (
    <S.Wrapper>
      <Paragraph>{translate('event.editEvent.emailForm.preview')}</Paragraph>
      <S.PreviewWrapper active={hasContent}>
        <S.Header active={!!headerImage}>
          {headerImage ? (
            <img src={headerImage} alt="event logo" />
          ) : (
            <Icon icon="IcGallery" color={theme.colors.grayscale.light.info} />
          )}
        </S.Header>
        <S.Content>
          <SubTitle bold center>
            {title || defaultValues.title}
          </SubTitle>
          <Paragraph center>{paragraph || defaultValues.paragraph}</Paragraph>
          <Button disabled={!button}>{button || defaultValues.button}</Button>
          <SocialNetworks
            options={socialNetworks || defaultValues.socialNetworks}
          />
          <Paragraph center>{footer || defaultValues.footer}</Paragraph>
        </S.Content>
      </S.PreviewWrapper>
    </S.Wrapper>
  );
};
