import * as Yup from 'yup';
import messages from 'lib/core/errors';
import { URL_DEFAULT_VALUE } from 'constants/enums';

const { requiredParam } = messages;

const validDomain = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;

export const schemaValidate = Yup.object().shape({
  name: Yup.string().required(requiredParam('eventName')),
  defaultLanguage: Yup.string().required(requiredParam('language')),
  domain: Yup.string()
    .matches(validDomain, requiredParam('domain'))
    .not([URL_DEFAULT_VALUE], requiredParam('domain')),
});

export const defaultValues = {
  name: '',
  defaultLanguage: '',
};
