import axios, { AxiosError } from 'axios';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslate } from 'hooks';
import { responseErrors } from 'lib/core/errors';
import { useIntlContext, useModalContext, useToast } from 'contexts';
import { Icon } from 'components/structure';
import { baseURL } from 'constants/endpoints';
import {
  ERRORS_WITHOUT_TOAST,
  RECAPTCHA_TOKEN_ERRORS,
  UNAUTHORIZED_ERRORS,
} from 'constants/enums';
import { ROUTES } from 'constants/urls';

export const useAPI = () => {
  const { setToast } = useToast();
  const { setModalProps, setShowModal } = useModalContext();
  const { language } = useIntlContext();
  const translate = useTranslate();
  const navigate = useNavigate();

  const onRejected = async (error: AxiosError) => {
    const { response } = error ?? {};
    const { data, status } = response ?? {};

    // TODO validate if you will have this behavior
    if (
      status === 401 &&
      !RECAPTCHA_TOKEN_ERRORS.includes(data?.error) &&
      !UNAUTHORIZED_ERRORS.includes(data?.message)
    ) {
      setModalProps({
        title: translate('auth.expiredSession.title'),
        description: translate('auth.expiredSession.description'),
        buttonLabel: translate('labels.understood'),
        onClose: () => navigate(ROUTES.login.getLink('signIn')),
        icon: <Icon icon="IcLogo" />,
        noBackground: true,
      });
      setShowModal(true);
    }

    if (!ERRORS_WITHOUT_TOAST.includes(data?.message)) {
      setToast({
        type: 'danger',
        description: translate(
          responseErrors(data?.message ?? data?.error),
          translate('errors.generic-error'),
        ),
      });
    }

    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      withCredentials: true,
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        language,
      },
    }),
  );

  apiRef.current.interceptors.response.use((response) => response, onRejected);

  return apiRef.current;
};
