import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const navBarPT = {
  navbar: {
    ...pt,
  },
};

export const navBarEN = {
  navbar: {
    ...en,
  },
};

export const navBarES = {
  navbar: {
    ...es,
  },
};
