import { useState } from 'react';
import { UseFormMethods, useController } from 'react-hook-form';
import Select from 'react-select';
import { useTranslate } from 'hooks';
import { HelperText } from 'components/form';
import * as S from './CustomSelect.styles';

export type Option = {
  value: string | boolean | number;
  label: string;
};

export type CustomSelectProps = Pick<UseFormMethods, 'control'> & {
  label: string;
  name: string;
  placeholder?: string;
  error?: string | undefined;
  options: Option[];
  defaultValue?: Option;
};

const isNullOrUndefined = (value: unknown) =>
  value === null || value === undefined || value === '';

export const CustomSelect = ({
  error,
  label,
  placeholder,
  options,
  name,
  control,
  defaultValue,
}: CustomSelectProps) => {
  const translate = useTranslate();
  const [inputSearchValue, setInputSearchValue] = useState(false);
  const {
    field: { ref, value, onChange },
  } = useController({
    control,
    name,
  });

  const onInputChange = (e: string) => setInputSearchValue(Boolean(e));

  return (
    <S.Wrapper hasError={!!error}>
      <Select
        inputRef={ref}
        name={name}
        classNamePrefix="custom-select"
        placeholder={placeholder}
        options={options}
        value={options?.find(({ value: optionValue }) => optionValue === value)}
        onChange={(val) => onChange(val ? val.value : null)}
        defaultValue={defaultValue}
        onInputChange={onInputChange}
      />
      <S.Label hide={isNullOrUndefined(value) && !inputSearchValue}>
        {label ?? placeholder}
      </S.Label>
      {error && <HelperText error={!!error}>{translate(error)}</HelperText>}
    </S.Wrapper>
  );
};

export default CustomSelect;
