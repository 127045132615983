import { isAfter, isBefore } from 'date-fns';
import { Event, EventStatus } from 'useCases/events';

export const getEventStatus = ({ startedAt, endedAt }: Event) => {
  const start = new Date(startedAt);
  const end = new Date(endedAt);
  const now = new Date();

  return isBefore(now, start)
    ? EventStatus.FUTURE
    : isAfter(now, end)
    ? EventStatus.FINISHED
    : EventStatus.ONGOING;
};
