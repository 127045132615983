import { useState } from 'react';
import { mutate } from 'swr';
import { useAPI, useFetch, useTranslate } from 'hooks';
import { ToastContextProps, useToast } from 'contexts';
import { TERMS } from 'constants/endpoints';

export type Term = {
  termsUrl?: string;
  url?: string;
  content?: string;
  id?: string;
  name: string;
  description: string;
  eventId: string;
  isRequiredTerm: boolean;
  priority: number;
};

const toastSaveTerms = (
  translate: (id: string) => string,
): ToastContextProps => ({
  title: translate('event.editEvent.termsForm.saveToast.title'),
  description: translate('event.editEvent.termsForm.saveToast.description'),
  type: 'success',
});

const formTermsParser = (
  terms: Array<Term>,
  files: Array<File>,
  eventId: string,
) => {
  const formData = new FormData();

  terms.forEach((term, index) => {
    if (term.id) formData.append(`terms[${index}][id]`, term.id);
    if (files[index]) formData.append(`${term.name}`, files[index]);
    formData.append(`terms[${index}][name]`, term.name);
    formData.append(`terms[${index}][eventId]`, eventId);
    formData.append(
      `terms[${index}][isRequiredTerm]`,
      `${term.isRequiredTerm}`,
    );
    formData.append(`terms[${index}][description]`, term.description);
    formData.append(`terms[${index}][priority]`, `${term.priority}`);
  });

  return formData;
};

export const useTerms = () => useFetch<{ data: Term[] }>(TERMS.ROOT);

export const useEventTerms = (eventId: string) =>
  useFetch<Term[]>(TERMS.BY_EVENT, {
    headers: { 'event-id': eventId },
  });

export const useCreateTerms = (eventId: string) => {
  const api = useAPI();
  const translate = useTranslate();
  const { setToast } = useToast();
  const [isPending, setIsPending] = useState(false);

  const handleCreateTerms = async (terms: Array<Term>, files: Array<File>) => {
    setIsPending(true);
    try {
      await api.post(TERMS.ROOT, formTermsParser(terms, files, eventId));
      mutate([TERMS.ROOT, eventId]);
      setToast(toastSaveTerms(translate));
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, handleCreateTerms };
};

export const useUpdateTerms = (eventId: string) => {
  const api = useAPI();
  const translate = useTranslate();
  const { setToast } = useToast();
  const [isPending, setIsPending] = useState(false);

  const handleUpdateTerms = async (terms: Array<Term>, files: Array<File>) => {
    setIsPending(true);
    try {
      const termsToPut: Array<Term> = [],
        termsToPost: Array<Term> = [],
        filesToPut: Array<File> = [],
        filesToPost: Array<File> = [];

      for (const index in terms) {
        if (terms[index].id) {
          termsToPut.push(terms[index]);
          filesToPut.push(files[index]);
        } else {
          termsToPost.push(terms[index]);
          filesToPost.push(files[index]);
        }
      }

      const { data } = await api.put(
        TERMS.ROOT,
        formTermsParser(termsToPut, filesToPut, eventId),

        {
          headers: { 'event-id': eventId },
        },
      );
      if (termsToPost.length) {
        await api.post(
          TERMS.ROOT,
          formTermsParser(termsToPost, filesToPost, eventId),
        );
      }
      mutate([TERMS.ROOT, eventId], data);
      setToast(toastSaveTerms(translate));
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, handleUpdateTerms };
};
