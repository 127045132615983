import styled, { css, DefaultTheme } from 'styled-components';

export const FormOptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.xs};
  `}
`;

const optionModifiers = {
  active: (theme: DefaultTheme) => css`
    color: ${theme.colors.primary.main};
    border: 1px solid ${theme.colors.primary.main};
    border-bottom-color: transparent;
    border-top-left-radius: ${theme.shapes.borderRadius.xs};
    border-top-right-radius: ${theme.shapes.borderRadius.xs};
  `,
  error: (theme: DefaultTheme) => css`
    border-bottom-color: ${theme.colors.contextual.danger.active};
    color: ${theme.colors.contextual.danger.active};
  `,
};

export const Option = styled.div<{ active: boolean; error: boolean }>`
  ${({ theme, active, error }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    color: ${theme.colors.primary.inative};
    border: 1px solid transparent;
    border-bottom-color: ${theme.colors.primary.inative};
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    ${active && optionModifiers.active(theme)}
    ${error && !active && optionModifiers.error(theme)}
  `}
`;
