import { Anchor, Icon } from 'components/structure';
import { SocialNetwork } from 'interfaces/social-network';
import * as S from './SocialNetworks.styles';

export type SocialNetworksProps = {
  options?: Array<SocialNetwork>;
};

export const SocialNetworks = ({ options }: SocialNetworksProps) => (
  <S.Wrapper>
    {options?.map(
      (network, index) =>
        network.url && (
          <Anchor
            key={network.url + index}
            href={network.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              icon={network.icon}
              width="24px"
              height="24px"
              color="white"
            />
          </Anchor>
        ),
    )}
  </S.Wrapper>
);
