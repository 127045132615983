import * as yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam, invalidParam } = messages;

const supportedSocialNetworks = [
  'instagram.com/',
  'linkedin.com/',
  'facebook.com/',
  'twitter.com/',
  'youtube.com/',
].join('|');

export const socialNetworkRegex =
  /^((http[s]?|ftp):\/)?\/?([^:/\s]+)?((\/\w+)*\/)([\w\-.]+[^#?\s]+)(.*)?(#[\w-]+)?$/;

export const schema = yup.object().shape({
  headerImage: yup.string().required(requiredParam('requiredField')),
  subject: yup.string().required(requiredParam('requiredField')),
  title: yup.string().required(requiredParam('requiredField')),
  paragraph: yup.string().required(requiredParam('requiredField')),
  button: yup.string().required(requiredParam('requiredField')),
  socialNetworks: yup.array().of(
    yup
      .object()
      .shape({
        value: yup
          .string()
          .matches(socialNetworkRegex, invalidParam('url'))
          .matches(
            new RegExp(supportedSocialNetworks),
            invalidParam('socialNetwork'),
          ),
      })
      .required(requiredParam('requiredField')),
  ),
  footer: yup.string().required(requiredParam('requiredField')),
});

export const isValidImageSizes = (
  imageFile: File,
  sizes: { width: number; height: number },
) =>
  new Promise((resolve) => {
    const url = URL.createObjectURL(imageFile);
    const image = new Image();
    image.onload = function () {
      let isValid = true;
      const height = image.height,
        width = image.width;

      if (height > sizes.width || width > sizes.width) isValid = false;
      resolve(isValid);
    };
    image.src = url;
  });
