import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const sideBarPT = {
  sidebar: {
    ...pt,
  },
};

export const sideBarEN = {
  sidebar: {
    ...en,
  },
};

export const sideBarES = {
  sidebar: {
    ...es,
  },
};
