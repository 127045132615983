import styled, { css } from 'styled-components';
import * as Button from 'components/structure/Button/Button.styles';
import { SubTitle } from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    margin: auto;
    text-align: center;

    ${SubTitle} {
      margin-bottom: ${theme.spacing.xs};
    }

    ${Button.Wrapper} {
      margin: ${theme.spacing.lg} auto 0;
      max-width: 160px;
      white-space: nowrap;
    }
  `}
`;
