import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: grid;
    grid-template-areas: 'editMenu register preview';
    grid-template-columns: ${theme.sizes.editMenu} 1fr;
    padding: 0;
    width: 100%;
  `}
`;
