import { NavLink } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';
import { Icon } from 'components/structure/Icon/Icon';
import { Paragraph } from 'components/structure/Typography/Typography';

type MenuNavProps = {
  disableFocus?: boolean;
};

export const Wrapper = styled.menu`
  ${({ theme }) => css`
    grid-area: head;
    width: 100%;
    background-color: ${theme.colors.background.dark.menu.main};
    border-left: 1px solid ${theme.colors.background.dark.menu.border};
    border-bottom: 1px solid ${theme.colors.background.dark.menu.border};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 ${theme.spacing['2xl']};
    height: ${theme.sizes.navbar};
    transition: max-width 0.2s linear;
    position: fixed;
    left: 0;
    top: 0;
    z-index: ${theme.layers.menu};
  `}
`;

export const MenuLink = styled(NavLink)`
  ${({ theme }) => css`
    position: relative;
    color: ${theme.colors.neutral.white};
    cursor: pointer;
    font-size: ${theme.typography.sizes.base};
    line-height: ${theme.typography.lineHeight.base};
    display: inline-flex;
    align-items: center;
    transition: color 0.3s linear;
    height: 100%;
    padding: 20px ${theme.spacing.md};

    &.active {
      background: ${theme.colors.background.dark.menu.activeItem};

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        border: 3px solid ${theme.colors.primary.main};
      }
    }

    &.active-disable {
      color: ${theme.colors.grayscale.dark.info};
    }

    &:hover {
      color: ${theme.colors.primary.hover};
    }
  `}
`;

export const Logout = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    ${Paragraph} {
      margin-bottom: 0px;
      margin-left: 11px;
    }

    &:hover {
      ${Icon}, ${Paragraph} {
        color: ${theme.colors.primary.main};
      }
    }
  `}
`;

export const MenuGroup = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const menuNavModifiers = {
  disableFocus: (theme: DefaultTheme) => css`
    & ${MenuLink} {
      color: ${theme.colors.grayscale.dark.info};
    }
  `,
};

export const MenuNav = styled.div<MenuNavProps>`
  ${({ theme, disableFocus }) => css`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing.md};
    height: 100%;

    ${disableFocus && menuNavModifiers.disableFocus(theme)}
  `}
`;
