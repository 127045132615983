import styled, { css, DefaultTheme } from 'styled-components';
import { EventStatus as EventStatusType } from 'useCases/events';

const statusWrapperModifiers = {
  [EventStatusType.ONGOING]: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.contextual.success.main};
  `,
  [EventStatusType.FUTURE]: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.contextual.attention.main};
  `,
  [EventStatusType.FINISHED]: () => css`
    background-color: #94a2ab;
  `,
};

export const EventStatus = styled.div<{ status: EventStatusType }>`
  ${({ theme, status }) => css`
    width: 12px;
    height: 12px;
    border-radius: ${theme.shapes.borderRadius.full};
    background-color: ${theme.colors.contextual.success.main};
    ${status && statusWrapperModifiers[status](theme)}
  `}
`;
