import { createContext, useContext, useState } from 'react';
import { WithChildren } from 'interfaces/children';

type ContextType = {
  hasOrganizations: boolean;
  setHasOrganizations: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
};

const defaultValue: ContextType = {
  hasOrganizations: false,
  setHasOrganizations: () => undefined,
  isLoading: false,
  setIsLoading: () => undefined,
};

export const OrganizationContext = createContext<ContextType>(defaultValue);

export const OrganizationProvider = ({ children }: WithChildren) => {
  const [hasOrganizations, setHasOrganizations] = useState(
    defaultValue.hasOrganizations,
  );
  const [isLoading, setIsLoading] = useState(defaultValue.isLoading);

  return (
    <OrganizationContext.Provider
      value={{
        hasOrganizations,
        setHasOrganizations,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizationContext = () => useContext(OrganizationContext);
