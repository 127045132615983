import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslate } from 'hooks';
import { ChoosePhoto } from 'components/contexts/organization';
import { TextField, HelperText } from 'components/form';
import { Button, Divisor, Form, FormGroup } from 'components/structure';
import { NewOrganization } from 'interfaces/organization';
import { ROUTES } from 'constants/urls';
import LogoPlaceholder from 'assets/images/core/logo-placeholder.png';
import {
  schemaValidate,
  defaultValues,
} from './NewOrganizationForm.validation';

type NewOrganizationFormModel = {
  file: Record<string, string>;
  name: string;
};

type NewOrganizationFormProps = {
  onSubmit: (data: NewOrganization) => void;
  isPending: boolean;
};

export const NewOrganizationForm = ({
  onSubmit,
  isPending,
}: NewOrganizationFormProps) => {
  const translate = useTranslate();
  const { register, handleSubmit, errors, control } =
    useForm<NewOrganizationFormModel>({
      resolver: yupResolver(schemaValidate),
      defaultValues,
    });

  return (
    <Form
      maxWidth="462px"
      fullWidth
      textAlign="center"
      alignItems="center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Divisor smMargin center column>
        <Controller
          render={({ onChange }) => (
            <ChoosePhoto
              currentImage={LogoPlaceholder}
              setValue={(_, value) => onChange(value)}
              name="file"
            />
          )}
          control={control}
          name="file"
        />
        {errors.file?.message && (
          <HelperText error={!!errors.file?.message}>
            {translate(String(errors.file?.message))}
          </HelperText>
        )}
      </Divisor>

      <FormGroup shortGap fullWidth>
        <TextField
          ref={register}
          name="name"
          label={translate('organization.newOrganization.organizationName')}
          placeholder={translate(
            'organization.newOrganization.organizationName',
          )}
          autoComplete="off"
          error={!!errors.name?.message}
          helperText={translate(errors.name?.message)}
        />
      </FormGroup>

      <Divisor hasGap hasMaxWidth>
        <Button
          as={Link}
          color="secondary"
          to={ROUTES.organizations.getLink('root')}
        >
          {translate('labels.back')}
        </Button>
        <Button type="submit" disabled={isPending} isLoading={isPending}>
          {translate('organization.firstOrganization.createOrganization')}
        </Button>
      </Divisor>
    </Form>
  );
};
