import { useTranslate } from 'hooks';
import { Heading, Paragraph } from 'components/structure';
import * as S from './EmptyState.styles';

export type EmptyStateProps = {
  onClick: () => void;
};

export const EmptyState = ({ onClick }: EmptyStateProps) => {
  const translate = useTranslate();

  return (
    <S.Wrapper>
      <Heading>{translate('event.eventList.emptyState.title')}</Heading>
      <Paragraph>{translate('event.eventList.emptyState.subtitle')}</Paragraph>
      <S.Link onClick={onClick}>
        {translate('event.eventList.emptyState.action')}
      </S.Link>
    </S.Wrapper>
  );
};
