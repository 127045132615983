import { format } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import ptbr from 'date-fns/locale/pt-BR';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEventStore } from 'store';
import { useEvent } from 'useCases/events';
import { useTranslate } from 'hooks';
import { useIntlContext } from 'contexts';
import { Menu, MenuItem } from 'components/structure';
import { Link, menuOptionsMock } from 'components/structure/Menu/Menu.mock';
import { ROUTES } from 'constants/urls';
import * as S from './EditEvent.styles';

const locales = {
  pt: ptbr,
  es,
  en: enUS,
};

export const EditEvent = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { eventId } = useEventStore();
  const { data: event, isLoading } = useEvent(eventId);
  const { language } = useIntlContext();
  const [active, setActive] = useState<string | null>(null);

  const handleActive = (index: string, link?: Link | string) => {
    setActive((old) => (old === index ? null : index));

    if (link) {
      if (link?.includes('.')) {
        const [key, child] = link.split('.');
        navigate(ROUTES.editEvent.getLink(key as Link, undefined, child));
        return;
      }

      navigate(ROUTES.editEvent.getLink(link as Link));
    }
  };

  const formatDateValue = (date: string) => {
    const timezoneDate = new Date(date);
    const formattedDate = format(timezoneDate, 'dd MMM, yyyy - p', {
      locale: locales[language],
    });
    return formattedDate;
  };

  // TODO add skeleton
  if (isLoading) {
    return <span>Loading...</span>;
  }

  return (
    <S.Wrapper>
      <Menu
        title={event?.name ?? ''}
        subtitle={formatDateValue(event?.startedAt ?? '')}
        headerTitle={translate('event.editEvent.headerTitle')}
        onClickHeader={() => navigate(ROUTES.app.getLink('events'))}
      >
        {menuOptionsMock.map(({ label, subItems }, index) => (
          <MenuItem
            key={translate(label) + index}
            name={translate(label)}
            active={
              active === index.toString() || active?.split('/')[0] === label
            }
            onClick={() => handleActive(index.toString())}
          >
            {subItems?.map(({ label: subItemLabel, link }, index) => (
              <MenuItem
                isSubitem
                key={translate(subItemLabel) + index}
                name={translate(subItemLabel)}
                active={active === `${label}/${index}`}
                onClick={() => handleActive(`${label}/${index}`, link)}
              />
            ))}
          </MenuItem>
        ))}
      </Menu>
      <Outlet />
    </S.Wrapper>
  );
};
