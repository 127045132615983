import { Link } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';

type MenuNavProps = {
  disableFocus?: boolean;
};

export const Wrapper = styled.menu`
  ${({ theme }) => css`
    grid-area: side;
    height: 100%;
    width: ${theme.sizes.sidebar};
    background-color: ${theme.colors.background.dark.menu.sidebar};
    border-right: 1px solid ${theme.colors.background.dark.menu.border};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 13px;
    overflow: hidden;
    transition: max-width 0.2s linear;
    position: fixed;
    left: 0;
    top: 0;
    z-index: ${theme.layers.menu};
    overflow-y: scroll;

    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    & > a {
      margin-top: ${theme.spacing.md};
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  `}
`;

const menuLinkModifiers = {
  active: (theme: DefaultTheme) => css`
    &::before {
      content: '';
      position: absolute;
      left: -15px;
      top: 0;
      opacity: 1;
      bottom: 0;
      border: 2px solid ${theme.colors.primary.main};
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      transition: opacity 0.3s ease-out;
    }

    & > img {
      border: 2px solid ${theme.colors.grayscale.dark.ellipse};
    }
  `,
};

export const MenuLink = styled(Link)<{ active: boolean }>`
  ${({ theme, active }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    width: 52px;
    height: 52px;
    cursor: pointer;
    margin-bottom: ${theme.spacing.md};

    &::before {
      content: '';
      position: absolute;
      left: -15px;
      top: 0;
      opacity: 0;
      bottom: 0;
      border: 2px solid ${theme.colors.primary.main};
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      transition: opacity 0.3s ease-out;
    }

    & > img {
      border-radius: 50%;
      height: 100%;
      width: 100%;
      transition: border-radius 0.3s;
    }

    ${active && menuLinkModifiers.active(theme)}

    &.active-disable {
      color: ${theme.colors.grayscale.dark.info};
    }

    &:hover {
      & > img {
        border-radius: ${theme.shapes.borderRadius.md};
      }

      &:before {
        opacity: 1;
      }
    }
  `}
`;

export const MenuGroup = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const menuNavModifiers = {
  disableFocus: (theme: DefaultTheme) => css`
    & ${MenuLink} {
      color: ${theme.colors.grayscale.dark.info};
    }
  `,
};

export const MenuNav = styled.div<MenuNavProps>`
  ${({ theme, disableFocus }) => css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    ${disableFocus && menuNavModifiers.disableFocus(theme)}
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.grayscale.dark.ellipse};
    border: 1px solid transparent;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    cursor: pointer;
    transition: all 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      left: -15px;
      top: 0;
      opacity: 0;
      bottom: 0;
      border: 2px solid ${theme.colors.primary.main};
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      transition: opacity 0.3s ease-out;
    }

    &:hover {
      border-radius: 16px;
      &:before {
        opacity: 1;
      }
    }
  `}
`;

export const PlusSection = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    & > ${IconWrapper} {
      margin-bottom: ${theme.spacing.md};
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: ${theme.spacing.sm} 0;
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: ${theme.typography.sizes.sm};
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    border-top: 1px solid ${theme.colors.grayscale.dark.ellipse};
  `}
`;
