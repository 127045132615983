import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslate } from 'hooks';
import { PasswordField } from 'components/form';
import {
  Button,
  Form,
  FormActions,
  FormGroup,
  Heading,
  Paragraph,
} from 'components/structure';
import { defaultValues, schema } from './ChangePasswordForm.validations';

export type ChangePassword = Yup.InferType<typeof schema>;
export type ChangePasswordFormProps = {
  onSuccess: () => void;
  onFailure: () => void;
};

type ChangePasswordProps = {
  onSubmit: (payload: ChangePassword) => void;
  isPending?: boolean;
};

export const ChangePasswordForm = ({
  onSubmit,
  isPending,
}: ChangePasswordProps) => {
  const translate = useTranslate();
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: false,
  });

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      alignItems="center"
      fullWidth
      maxWidth="500px"
    >
      <Heading>{translate('auth.changePassword.title')}</Heading>
      <Paragraph>{translate('auth.changePassword.description')}</Paragraph>

      <FormGroup fullWidth>
        <PasswordField
          ref={register}
          name="password"
          autoComplete="new-password"
          label={translate('labels.newPassword')}
          placeholder={translate('labels.newPassword')}
          error={!!errors?.password?.message}
          helperText={translate(errors?.password?.message)}
          showStrength
        />
        <PasswordField
          name="rePassword"
          autoComplete="new-password"
          label={translate('labels.rePassword')}
          placeholder={translate('labels.rePassword')}
          ref={register}
          error={!!errors?.rePassword?.message}
          helperText={translate(errors?.rePassword?.message)}
        />
        <input type="hidden" name="token" ref={register} />
      </FormGroup>

      <FormActions>
        <Button
          fullWidth
          type="submit"
          disabled={isPending}
          isLoading={isPending}
        >
          {translate('labels.updatePassword')}
        </Button>
      </FormActions>
    </Form>
  );
};
