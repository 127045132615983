import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslate } from 'hooks';
import { useAPI } from 'hooks/useAPI';
import { useModalContext, ModalPropsContext } from 'contexts';
import { AccreditationFormModel } from 'components/contexts';
import { Icon, Description } from 'components/structure';
import { USERS } from 'constants/endpoints';
import { ROUTES } from 'constants/urls';

export const useCreateUser = () => {
  const [isPending, setIsPending] = useState(false);
  const api = useAPI();
  const translate = useTranslate();
  const { setModalProps, setShowModal } = useModalContext();
  const navigate = useNavigate();
  const DUPLICATED_USER = 409;

  const handleModalState = (props: ModalPropsContext) => {
    setModalProps(props);
    setShowModal(true);
  };

  const handleCreateUser = async (user: AccreditationFormModel) => {
    setIsPending(true);
    try {
      await api.post(USERS.ROOT, user);
      handleModalState({
        title: translate('accreditation.confirmationEmail.title'),
        description: (
          <Description
            normalPhrase={translate(
              'accreditation.confirmationEmail.description',
            )}
            strongPhrase={translate(
              'accreditation.confirmationEmail.strongDescription',
            )}
          />
        ),
        icon: <Icon icon="IcMail" />,
        buttonLabel: translate('labels.understood'),
        onClose: () => navigate(ROUTES.login.getLink('signIn')),
      });
    } catch (error) {
      if (error.response.status === DUPLICATED_USER) {
        handleModalState({
          title: translate('errors.opsTitle'),
          description: (
            <Description
              normalPhrase={translate('accreditation.userExists')}
              strongPhrase={translate('accreditation.strongUserExists')}
            />
          ),
          icon: <Icon icon="IcAlertBorder" />,
          buttonLabel: translate('labels.understood'),
        });
      }
    } finally {
      setIsPending(false);
    }
  };

  return { handleCreateUser, isPending };
};
