import { useNavigate } from 'react-router-dom';
import { Logo, Button, SubTitle } from 'components/structure';
import * as S from './ErrorGeneric.styles';

export type ErrorGenericProps = {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  description: string | JSX.Element;
  buttonLabel: string | JSX.Element;
  actionNavigate?: string;
  actionReload?: () => void;
};

export const ErrorGeneric = ({
  title,
  subtitle,
  description,
  buttonLabel,
  actionNavigate,
  actionReload,
}: ErrorGenericProps) => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <Logo />

      <S.Content>
        <S.Title>{title}</S.Title>

        {subtitle && <SubTitle>{subtitle}</SubTitle>}

        <p>{description}</p>
        <Button
          to={actionNavigate}
          onClick={() => {
            actionReload && actionReload();
            actionNavigate && navigate(actionNavigate);
          }}
        >
          {buttonLabel}
        </Button>
      </S.Content>
    </S.Container>
  );
};
