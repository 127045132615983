import { Language } from 'contexts';

export const DEFAULT_LANGUAGE = 'pt' as Language;

export const LANGUAGE_KEY = 'language';

export const POSSIBLE_LANGUAGES = ['pt', 'es'];

export const URL_DEFAULT_VALUE = '.livestreaming.com';

export const NOT_CONFIRMED_REGISTRATION = 'not-confirmed-registration';
export const EMAIL_ALREADY_EXIST = 'email-already-exist';
export const INVALID_PASSWORD = 'invalid-password';

export const MAX_INTERESTS_ARRAY_LENGTH = 15;

export const ERRORS_WITHOUT_TOAST = [
  EMAIL_ALREADY_EXIST,
  NOT_CONFIRMED_REGISTRATION,
];

export const RECAPTCHA_ERRORS = {
  TOO_MANY_TRIES: 429,
  TOKEN_IS_MISSING: 're-captcha-token-is-missing',
  TOKEN_IS_INVALID: 'recaptcha-token-is-invalid',
};

export const RECAPTCHA_TOKEN_ERRORS = [
  RECAPTCHA_ERRORS.TOKEN_IS_MISSING,
  RECAPTCHA_ERRORS.TOKEN_IS_INVALID,
];

export const UNAUTHORIZED_ERRORS = [
  'invalid-2fa-token',
  INVALID_PASSWORD,
  NOT_CONFIRMED_REGISTRATION,
];

export enum FormState {
  accreditation = 'accreditation',
  platform = 'platform',
}

export enum LanguagesAcronyms {
  en = 'en',
  pt = 'pt-br',
  es = 'es',
}

export enum DATE_FORMATS {
  'pt-br' = 'dd/MM/yyyy',
  'es' = 'dd/MM/yyyy',
  'en' = 'MM/dd/yyyy',
}

export enum AccessTypeEvent {
  FREE = 'FREE',
  HOTMART = 'HOTMART',
  SYMPLA = 'SYMPLA',
}
