import { useState } from 'react';
import { useEventStore } from 'store';
import { useCreateEmail, useUpdateEmail } from 'useCases/emails';
import { useTranslate } from 'hooks';
import { EmailFormProvider, useEmailFormContext } from 'contexts';
import { EmailPreview } from 'components/contexts';
import { Button } from 'components/structure';
import { EditEventEmailsCategory } from 'interfaces/email';
import { EmailSetupForm } from './EmailSetupForm/EmailSetupForm';
import * as S from './EmailsForm.styles';
import { EmailsTabs } from './EmailTabs/EmailsTabs';
import { EmailTestModal } from './EmailTestModal/EmailTestModal';

const EmailsFormComponent = ({ form }: { form: EditEventEmailsCategory }) => {
  const translate = useTranslate();
  const { filledData, inputData, isLoading, email, setFormData } =
    useEmailFormContext();
  const [isShownTestModal, setIsShownTestModal] = useState(false);
  const { eventId } = useEventStore();
  const { handleCreateEmail, isPending: isPendingCreate } =
    useCreateEmail(eventId);
  const { handleUpdateEmail, isPending: isPendingUpdate } =
    useUpdateEmail(eventId);
  const isPending = isPendingCreate || isPendingUpdate;

  const getHeaderImageUrl = () => {
    if (filledData?.headerImage instanceof File) {
      return URL.createObjectURL(filledData?.headerImage);
    }
    if (email) {
      return email.imageUrl;
    }
    return '';
  };

  if (isLoading) {
    // @TODO skeleton
    return <span>Loading...</span>;
  }

  return (
    <>
      <EmailTestModal show={isShownTestModal} setShow={setIsShownTestModal} />
      <S.EditAreaWrapper>
        <EmailsTabs form={form} />
        <EmailSetupForm
          id={form}
          defaultValues={inputData}
          onChange={(filledData, inputsData) =>
            setFormData(filledData, inputsData)
          }
          onSubmit={(data) =>
            !email?.id
              ? handleCreateEmail(data, form)
              : handleUpdateEmail(email.id, data)
          }
        />
      </S.EditAreaWrapper>
      <S.PreviewAreaWrapper>
        <EmailPreview {...filledData} headerImage={getHeaderImageUrl()} />
        <S.ActionButtonWrapper>
          <Button
            type="button"
            color="secondary"
            disabled={!filledData || isPending}
            isLoading={isPending}
            onClick={() => setIsShownTestModal(true)}
          >
            {translate('event.editEvent.emailForm.testModal.title')}
          </Button>
          <Button
            type="submit"
            form={form}
            disabled={isPending}
            isLoading={isPending}
          >
            {translate('labels.save')}
          </Button>
        </S.ActionButtonWrapper>
      </S.PreviewAreaWrapper>
    </>
  );
};

export const EmailsForm = ({ form }: { form: EditEventEmailsCategory }) => (
  <EmailFormProvider form={form}>
    <EmailsFormComponent form={form} />
  </EmailFormProvider>
);
