import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSettingsContext } from 'contexts';
import * as S from './Tabs.styles';

export type Tab = {
  title: string;
  url: string;
};

export type TabsProps = {
  variant: 'primary' | 'secondary';
  tabs: Array<Tab>;
  onChange?: (tab: Tab) => void;
};

export const Tabs = ({ variant, tabs, onChange }: TabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { preventDefault } = useSettingsContext();
  const [active, setActive] = useState<Tab>(
    tabs.find((tab) => tab.url === location.pathname) || tabs[0],
  );

  const handleActiveTab = (tab: Tab) => () => {
    preventDefault(() => {
      navigate(tab.url);
      setActive(tab);
    })();
  };

  useEffect(() => {
    if (active) {
      onChange?.(active);
    }
  }, [active, onChange]);

  return (
    <S.Wrapper>
      <section className={variant}>
        {tabs.map((tab) => (
          <span
            key={tab.title}
            className={active.url === tab.url ? 'active' : ''}
            onClick={handleActiveTab(tab)}
          >
            {tab.title}
          </span>
        ))}
      </section>
    </S.Wrapper>
  );
};
