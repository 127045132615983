import { forwardRef, InputHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { HelperText } from 'components/form';
import * as S from './Switch.styles';

export type SwitchProps = {
  name: string;
  label?: React.ReactChild;
  error?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ label, error, name, onChange, ...props }, ref) => (
    <S.Wrapper>
      <S.Input
        {...props}
        ref={ref}
        name={name}
        id={name}
        type="checkbox"
        onChange={onChange}
      />
      <S.Label htmlFor={name}>
        <S.Switch>
          <div />
        </S.Switch>
        {label}
      </S.Label>
      {!!error && (
        <HelperText error={!!error}>
          <FormattedMessage id={error} defaultMessage={error} />
        </HelperText>
      )}
    </S.Wrapper>
  ),
);
