import styled, { css, DefaultTheme } from 'styled-components';

type DivisorProps = {
  hasGap?: boolean;
  hasMaxWidth?: boolean;
  lgMargin?: boolean;
  smMargin?: boolean;
  center?: boolean;
  column?: boolean;
};

const divisorModifiers = {
  gap: (theme: DefaultTheme) => css`
    gap: ${theme.spacing.sm};
  `,
  smMargin: (theme: DefaultTheme) => css`
    margin-bottom: ${theme.spacing.sm};
  `,
  lgMargin: (theme: DefaultTheme) => css`
    margin-bottom: ${theme.spacing.lg};
  `,
  maxWidth: () => css`
    max-width: 360px;
    margin: 0 auto;
  `,
  center: () => css`
    justify-content: center;
  `,
  column: () => css`
    flex-direction: column;
  `,
};

export const Divisor = styled.div<DivisorProps>`
  ${({
    theme,
    hasGap,
    lgMargin = false,
    smMargin = false,
    hasMaxWidth = false,
    center = false,
    column = false,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ${hasGap && divisorModifiers.gap(theme)}
    ${lgMargin && divisorModifiers.lgMargin(theme)}
    ${smMargin && divisorModifiers.smMargin(theme)}
    ${hasMaxWidth && divisorModifiers.maxWidth()}
    ${center && divisorModifiers.center()}
    ${column && divisorModifiers.column()}
  `}
`;

export const DefaultScroll = css`
  ${({ theme }) => css`
    overscroll-behavior: contain;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      border-radius: ${theme.shapes.borderRadius.xs};
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px ${theme.colors.grayscale.dark.line};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.grayscale.dark.info};
    }
  `}
`;
