import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-y: auto;
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    > :first-child {
      margin-right: ${theme.spacing.lg};
    }
  `}
`;
