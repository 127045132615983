import styled, { css, DefaultTheme } from 'styled-components';

type WrapperProps = {
  hasError?: boolean;
};

const wrapperModifiers = {
  hasError: (theme: DefaultTheme) => css`
    .custom-select {
      &__control {
        border-color: ${theme.colors.contextual.danger.main};
      }
    }

    &,
    &:active,
    &:focus {
      border-color: ${theme.colors.contextual.danger.main};
    }

    & + ${Label} {
      color: ${theme.colors.contextual.danger.main};
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, hasError = false }) => css`
    position: relative;

    .custom-select {
      &__control {
        background-color: ${theme.colors.background.dark.input};
        border: 1px solid ${theme.colors.grayscale.dark.line};
        min-height: 48px;
        transition: all 0.2s linear;
        outline: none;

        &:hover {
          border-color: ${theme.colors.primary.main};
        }

        &--menu-is-open {
          border-color: ${theme.colors.primary.main};
          background-color: ${theme.colors.background.select.main};

          & &:hover {
            border-color: ${theme.colors.primary.main};
            border-bottom-color: transparent;
          }

          svg {
            color: ${theme.colors.neutral.white};
          }
        }

        &--is-focused {
          box-shadow: none;
        }
      }

      &__value-container {
        padding: 0 20px;
      }

      &__single-value,
      &__input {
        color: ${theme.colors.grayscale.dark.title};
        margin: 0;
        margin-top: 4px;
      }

      &__indicator-separator {
        display: none;
      }

      &__menu {
        margin-top: -4px;
        border-radius: ${theme.shapes.borderRadius.xs};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        position: absolute;
        width: 100%;
        background-color: ${theme.colors.background.select.main};
        border: 1px solid ${theme.colors.primary.main};
        border-top: none;
      }

      &__menu-list {
        padding: 0;
        border-color: ${theme.colors.primary.main};
      }

      &__option {
        color: ${theme.colors.grayscale.dark.title};
        display: flex;
        align-items: center;
        font-size: ${theme.typography.sizes.base};
        line-height: 20px;
        border-radius: none;

        &:hover {
          background: ${theme.colors.background.select.dark};
        }

        &--is-focused {
          background-color: ${theme.colors.background.select.dark};
        }
        &--is-selected {
          background-color: ${theme.colors.background.dark.icon};
        }
      }
    }

    ${hasError && wrapperModifiers.hasError(theme)};
  `}
`;

const labelModifiers = {
  hide: () => css`
    top: 58%;
    opacity: 0;
    visibility: hidden;
  `,
};

export const Label = styled.label<{ hide?: boolean }>`
  ${({ theme, hide = true }) => css`
    color: ${theme.colors.primary.main};
    font-size: 10px;
    font-family: inherit;
    transition: all 0.2s linear;
    position: absolute;
    left: 21px;
    top: 10px;
    transform: translateY(-50%);

    ${hide && labelModifiers.hide()};
  `}
`;
