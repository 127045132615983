import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const accreditationPT = {
  accreditation: pt,
};

export const accreditationEN = {
  accreditation: en,
};

export const accreditationES = {
  accreditation: es,
};
