/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { useIntl } from 'react-intl';

export const useTranslate = () => {
  const { formatMessage } = useIntl();

  // TODO validate if is a problem keep like this
  const translate = (id?: string, defaultMessage?: string): string => {
    if (!id) return '';
    return formatMessage({ id, defaultMessage });
  };

  translate.withValues = (id: string, values?: any) =>
    formatMessage({ id }, values);

  const translateRef = useRef(translate);

  return translateRef.current;
};
