import { Link } from 'react-router-dom';
import { useTranslate } from 'hooks';
import { Button, SubTitle, Paragraph } from 'components/structure';
import * as S from './OrganizationRoot.styles';

type FlowGuideProps = {
  title: string;
  description: string;
  buttonLabel?: string;
  buttonAction?: string;
};

export const OrganizationRoot = ({
  title,
  description,
  buttonLabel,
  buttonAction,
}: FlowGuideProps) => {
  const translate = useTranslate();

  return (
    <S.Wrapper>
      <S.Content>
        <SubTitle>{translate(title)}</SubTitle>
        <Paragraph>{translate(description)}</Paragraph>
        {!!buttonLabel && (
          <Button as={Link} to={buttonAction}>
            {translate(buttonLabel)}
          </Button>
        )}
      </S.Content>
    </S.Wrapper>
  );
};
