import { useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useOrganizationStore } from 'store';
import { useOrganizations } from 'useCases/organizations';
import { logout } from 'lib/core/session';
import { useOrganizationContext, useSettingsContext } from 'contexts';
import { Navbar, ServerErrorGuard, Sidebar } from 'components/structure';
import navbarOptionsMock from 'components/structure/Navbar/Navbar.mock';
import { ROUTES } from 'constants/urls';
import * as S from './Private.styles';

const Private = () => {
  const navigate = useNavigate();
  const { data: organizationsData, isLoading } = useOrganizations();
  const { setHasOrganizations, setIsLoading } = useOrganizationContext();
  const { organizationId, addOrganizationId } = useOrganizationStore();
  const { hasError } = useSettingsContext();

  const navbarOptions = useMemo(() => {
    if (!organizationId) {
      return navbarOptionsMock.filter(({ label }) => label !== 'navbar.events');
    }
    return navbarOptionsMock;
  }, [organizationId]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    if (organizationsData?.length) {
      setHasOrganizations(!!organizationsData.length);
    }
  }, [organizationsData, setHasOrganizations]);

  const handleSelectedOrganization = (organizationId: string) => {
    addOrganizationId(organizationId);
  };

  return (
    <S.Wrapper>
      <Navbar
        options={navbarOptions}
        onLogout={() => {
          logout();
          navigate(ROUTES.login.getLink('signIn'));
        }}
      />
      <Sidebar
        selected={organizationId}
        onSelect={handleSelectedOrganization}
        options={organizationsData || []}
      />

      <S.OutletWrapper>
        <ServerErrorGuard isError={hasError}>
          <Outlet />
        </ServerErrorGuard>
      </S.OutletWrapper>
    </S.Wrapper>
  );
};

export default Private;
