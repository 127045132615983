import { createContext, useContext, useState } from 'react';
import { WithChildren } from 'interfaces/children';
import { Event } from 'interfaces/event';

type ContextType = {
  event?: Event;
  setEvent: (event: Event) => void;
};

const defaultValue: ContextType = {
  event: undefined,
  setEvent: () => undefined,
};

export const EventContext = createContext<ContextType>(defaultValue);

export const EventProvider = ({ children }: WithChildren) => {
  const [event, setEvent] = useState(defaultValue.event);

  return (
    <EventContext.Provider
      value={{
        event,
        setEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEventContext = () => useContext(EventContext);
