import styled, { css } from 'styled-components';

export const FormWrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.xs} 0;
    & > div {
      padding: ${theme.spacing.xs} 0;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: -${theme.spacing.lg};
    padding: 0;
    & > p {
      color: ${theme.colors.grayscale.dark.info};
    }
  `}
`;

export const IconButton = styled.button`
  ${({ theme }) => css`
    position: relative;
    border-radius: 100%;
    background-color: ${theme.colors.grayscale.dark.ellipse};
    width: 30px;
    height: 30px;
    & > * {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 18px;
      height: 18px;
      color: ${theme.colors.neutral.white};
    }
  `}
`;
