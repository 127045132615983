import { useEffect } from 'react';
import { useTranslate } from 'hooks';
import { Icon } from 'components/structure';
import * as S from './Toast.styles';

const typesIcons = {
  default: '',
  success: <Icon icon="IcSuccess" />,
  danger: <Icon icon="IcExclude" />,
  alert: <Icon icon="IcAlert" />,
};

const typesTitles = {
  default: '',
  success: 'labels.success',
  danger: 'labels.danger',
  alert: 'labels.alert',
};

export type ToastProps = {
  show?: boolean;
  title?: string;
  description?: string;
  type?: 'default' | 'success' | 'danger' | 'alert';
  handleClose?: () => void;
  setShow: (show: boolean) => void;
  duration?: number;
  pin?: boolean;
  link?: string;
};

export const Toast = ({
  show = false,
  title,
  description,
  type = 'default',
  duration = 5000,
  pin = false,
  handleClose,
  setShow,
}: ToastProps) => {
  const translate = useTranslate();

  const toastTitle =
    !title && type !== 'default'
      ? translate(typesTitles[type], typesTitles[type])
      : title;

  useEffect(() => {
    if (!pin) {
      const timeout = setTimeout(() => setShow(false), duration);

      return () => clearTimeout(timeout);
    }
  }, [show, duration, pin, setShow]);

  return (
    <S.Wrapper type={type} show={show}>
      {!!typesIcons[type] && <S.IconWrapper>{typesIcons[type]}</S.IconWrapper>}
      <S.ContentWrapper>
        <S.Title>{toastTitle}</S.Title>
        <S.Description>{description}</S.Description>
      </S.ContentWrapper>
      <S.ButtonClose onClick={handleClose}>
        <Icon icon="IcClose" />
      </S.ButtonClose>
    </S.Wrapper>
  );
};
