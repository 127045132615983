import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { DraggableItem } from 'components/structure';

export type SortableItemProps = Omit<DraggableItem<unknown>, 'id'> & {
  id: string;
  element: React.ElementType;
};

export const SortableItem = ({
  code,
  isDraggable = true,
  element: Element,
  ...props
}: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: String(code) });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  } as CSSProperties;

  return isDraggable ? (
    <Element
      isDraggable
      selected={!!props.value}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      {...props}
    />
  ) : (
    <Element {...props} />
  );
};
