import * as Yup from 'yup';
import messages from 'lib/core/errors';
import { Mask } from 'components/form';

export const passwordStrength = {
  hasMinLength: /.{8,}/,
  hasNumber: /(?=.*\d)/,
  hasSymbol: /(?=.*[`~!@#$%^&*=(+){}|'";\\:[><.\],?/-])/,
  hasCapitalLetter: /(?=.*[A-Z])/,
  hasLowercaseLetter: /^(?=.*[a-z])/,
};

export const validatePassword = (password: string | null | undefined) =>
  Yup.string()
    .matches(passwordStrength.hasMinLength, messages.invalidParam('password'))
    .matches(
      passwordStrength.hasNumber,
      messages.invalidParam('passwordHasNumber'),
    )
    .matches(
      passwordStrength.hasSymbol,
      messages.invalidParam('passwordHasSymbol'),
    )
    .matches(
      passwordStrength.hasCapitalLetter,
      messages.invalidParam('passwordHasCapitalLetter'),
    )
    .matches(
      passwordStrength.hasLowercaseLetter,
      messages.invalidParam('passwordHasLowercaseLetter'),
    )
    .validate(password, { abortEarly: false });

export const hourInputMask = (e: React.FormEvent<HTMLInputElement>) => {
  let value = e.currentTarget.value;
  e.currentTarget.maxLength = 5;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/, '$1:$2');
  e.currentTarget.value = value;
};

export const dateInputMask = (e: React.FormEvent<HTMLInputElement>) => {
  let value = e.currentTarget.value;
  e.currentTarget.maxLength = 10;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '$1/$2');
  value = value.replace(/(\d{2})(\d)/, '$1/$2');
  value = value.replace(/(\d{2})(\d{4})$/, '$1/$2');
  e.currentTarget.value = value;
};

export const urlInputMask = (e: React.FormEvent<HTMLInputElement>) => {
  let value = e.currentTarget.value;
  value = value.split('https://').join('');
  value = value.split('http://').join('');
  value = `https://${value}`;
  e.currentTarget.value = value;
};

export const handleInputMask = (
  mask: Mask,
  e: React.FormEvent<HTMLInputElement>,
) => {
  const masks = {
    hour: () => hourInputMask(e),
    date: () => dateInputMask(e),
    url: () => urlInputMask(e),
  };

  return masks[mask]();
};

export const setReadOnlyDefaultValue = (input: HTMLInputElement) => {
  const defaultValueLength = input.value.length;
  const BACKSPACE_KEY = 'Backspace';
  const DELETE_KEY = 'Delete';
  const ARROW_LEFT_KEY = 'ArrowLeft';
  const ARROW_RIGHT_KEY = 'ArrowRight';
  const SPACE_BAR_KEY = ' ';

  const handleKeyEvent = (event: KeyboardEvent) => {
    const currentInputLength = input.value.length;
    const inputDiffLength = currentInputLength - defaultValueLength;

    if (event.key === SPACE_BAR_KEY) event.preventDefault();

    if (
      event.key === BACKSPACE_KEY &&
      input.selectionStart! > inputDiffLength
    ) {
      event.preventDefault();
    }

    if (event.key === DELETE_KEY && input.selectionStart! >= inputDiffLength) {
      event.preventDefault();
    }

    if (
      event.key !== ARROW_LEFT_KEY &&
      event.key !== ARROW_RIGHT_KEY &&
      (input.selectionStart! > inputDiffLength ||
        input.selectionEnd! > inputDiffLength)
    ) {
      event.preventDefault();
    }
  };

  const handlePasteValue = (event: ClipboardEvent) => {
    if (input.selectionStart! > defaultValueLength) {
      event.preventDefault();
    }
  };

  input.addEventListener('keypress', handleKeyEvent);
  input.addEventListener('keydown', handleKeyEvent);
  input.addEventListener('paste', handlePasteValue);

  return () => {
    input.removeEventListener('keypress', handleKeyEvent);
    input.removeEventListener('keydown', handleKeyEvent);
    input.removeEventListener('paste', handlePasteValue);
  };
};
