import styled, { css } from 'styled-components';

export const ActionButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacing.sm};
    & > button {
      margin-left: ${theme.spacing.sm};
    }
    width: 100%;
    justify-content: flex-end;
  `}
`;

const Area = styled.div`
  ${({ theme }) => css`
    height: calc(100vh - ${theme.sizes.navbar});
    overflow-y: auto;
  `}
`;

export const EditAreaWrapper = styled(Area)`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm};
    max-width: 458px;
  `}
`;

export const PreviewAreaWrapper = styled(Area)`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm};
    background-color: ${theme.colors.neutral.filter};
  `}
`;
