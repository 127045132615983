import { useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';

export const usePortal = () => {
  const portalRef = useRef(document.createElement('div'));

  useEffect(() => {
    const portalEffectRef = portalRef.current;
    document.body.appendChild(portalEffectRef);

    return () => {
      if (portalEffectRef && document?.body) {
        document.body.removeChild(portalEffectRef);
      }
    };
  }, [portalRef]);

  const Portal = useCallback(
    ({ children }) =>
      portalRef.current !== null
        ? createPortal(children, portalRef.current)
        : null,
    [portalRef],
  );
  return { Portal, portalRef };
};
