import { useNavigate } from 'react-router-dom';
import { useTheme, useTranslate } from 'hooks';
import { useSettingsContext } from 'contexts';
import { Icon } from 'components/structure/Icon/Icon';
import { Paragraph } from 'components/structure/Typography/Typography';
import * as S from './Navbar.styles';

export type NavbarProps = {
  options?: {
    label: string;
    url: string;
  }[];
  onLogout: () => void;
};

export const Navbar = ({ options = [], onLogout }: NavbarProps) => {
  const translate = useTranslate();
  const theme = useTheme();
  const navigate = useNavigate();
  const { preventDefault } = useSettingsContext();

  return (
    <S.Wrapper>
      <S.MenuGroup>
        <S.MenuNav>
          {options.map(({ label, url }) => (
            <S.MenuLink
              activeClassName="active"
              to={url}
              key={label}
              onClick={(ev) => {
                ev.preventDefault();
                preventDefault(() => navigate(url))();
              }}
            >
              {translate(label)}
            </S.MenuLink>
          ))}
          <S.Logout onClick={preventDefault(onLogout)}>
            <Icon
              icon="IcLogout"
              height="18px"
              width="18px"
              color={theme.colors.neutral.white}
            />
            <Paragraph>{translate('labels.logout')}</Paragraph>
          </S.Logout>
        </S.MenuNav>
      </S.MenuGroup>
    </S.Wrapper>
  );
};
