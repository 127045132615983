import styled, { css, DefaultTheme } from 'styled-components';
import { TextAreaProps } from './TextArea';

type FieldProps = {
  hasError?: boolean;
  success?: boolean;
} & Pick<TextAreaProps, 'disabled'>;

const fieldModifiers = {
  disabled: (theme: DefaultTheme) => css`
    background: ${theme.colors.grayscale.dark.line};
    border: 1px solid ${theme.colors.grayscale.dark.line};
    color: ${theme.colors.grayscale.light.line};
    pointer-events: none;
  `,
  hasError: (theme: DefaultTheme) => css`
    &,
    &:active,
    &:focus {
      border-color: ${theme.colors.contextual.danger.main};
    }

    & + ${Label} {
      color: ${theme.colors.contextual.danger.main};
    }
  `,
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const InputWrapper = styled.div<FieldProps>`
  ${({ theme, disabled, hasError }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: ${theme.colors.background.dark.input};
    font-family: ${theme.typography.family.primary};
    font-weight: 500;
    border-radius: ${theme.shapes.borderRadius.xs};
    border: 1px solid ${theme.colors.grayscale.dark.line};
    position: relative;
    width: 100%;
    text-align: left;
    min-height: 142px;
    outline: none;
    transition: all 0.2s linear;
    transition-property: color, border-color;
    text-overflow: ellipsis;
    padding-top: ${theme.spacing.sm};

    &:active,
    &:focus-within {
      border-color: ${theme.colors.primary.main};

      & > ${Label} {
        color: ${theme.colors.primary.main};
      }
    }

    &:disabled {
      background-color: ${theme.colors.grayscale.dark.line};
      pointer-events: none;
    }

    ${hasError && fieldModifiers.hasError(theme)};
    ${disabled && fieldModifiers.disabled(theme)};
  `}
`;

export const Field = styled.textarea`
  ${({ theme }) => css`
    appearance: none;
    background: none;
    border: none;
    font-family: inherit;
    font-size: ${theme.typography.sizes.base};
    line-height: ${theme.typography.lineHeight.base};
    color: ${theme.colors.grayscale.dark.title};
    min-height: 142px;
    min-width: 100%;
    overflow: hidden;
    outline: none;
    padding: 0 10px 0 20px;
    text-overflow: ellipsis;

    &::placeholder {
      font-family: inherit;
      color: ${theme.colors.grayscale.dark.info};
    }

    &:placeholder-shown {
      & + ${Label} {
        top: 20%;
        opacity: 0;
        visibility: hidden;
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.primary.main};
    font-size: ${theme.typography.sizes['2xs']};
    font-family: inherit;
    transition: all 0.2s linear;
    position: absolute;
    left: 21px;
    top: ${theme.typography.sizes.xs};
    transform: translateY(-50%);
  `}
`;
