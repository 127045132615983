import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const organizationPT = {
  organization: pt,
};

export const organizationEN = {
  organization: en,
};

export const organizationES = {
  organization: es,
};
