import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  SortingStrategy,
} from '@dnd-kit/sortable';
import { SetStateAction } from 'react';
import { SortableItem } from './SortableItem/SortableItem';

export type DraggableItem<T> = {
  id?: string;
  value: T;
  priority: number;
  code: number;
  isDraggable?: boolean;
  selected?: boolean;
};

export type DraggableListProps<T> = {
  items: DraggableItem<T>[];
  setItems: (items: SetStateAction<DraggableItem<T>[]>) => void;
  element: React.ElementType;
  strategy?: SortingStrategy;
  handleSwapItems?: (from: number, to: number) => void;
};

export const DraggableList = <T extends unknown>({
  items,
  setItems,
  element,
  strategy = rectSortingStrategy,
  handleSwapItems,
}: DraggableListProps<T>) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const itemsWithId = items.map((i) => ({ ...i, id: String(i.code) }));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const activeItem = active.id;
    const overItem = over?.id;

    if (activeItem === overItem) return;

    const newIndexByCode = items.find(({ code }) => String(code) === overItem);
    const oldIndexByCode = items.find(
      ({ code }) => String(code) === activeItem,
    );

    const oldIndex = items.indexOf(oldIndexByCode!);
    const newIndex = items.indexOf(newIndexByCode!);

    setItems([...arrayMove(items, oldIndex, newIndex)]);
    handleSwapItems?.(oldIndex, newIndex);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={itemsWithId} strategy={strategy}>
        {itemsWithId.map((item) => (
          <SortableItem key={item.id} element={element} {...item} />
        ))}
      </SortableContext>
    </DndContext>
  );
};
