import { useState } from 'react';
import { useOrganizationStore } from 'store';
import { EventStatus, useEvents } from 'useCases/events';
import { EventList } from 'components/contexts';

const initialStatuses = [EventStatus.ONGOING, EventStatus.FUTURE];
export const Events = () => {
  const [search, setSearch] = useState('');
  const [statuses, setStatuses] = useState<EventStatus[]>(initialStatuses);

  const organizationId = useOrganizationStore((state) => state.organizationId);
  const { data } = useEvents({
    organizationId,
    search,
    statuses,
  });

  return (
    <EventList
      events={data || []}
      search={search}
      onSearch={setSearch}
      statuses={statuses}
      onFilter={setStatuses}
      onResetFilter={() => {
        setSearch('');
        setStatuses(initialStatuses);
      }}
    />
  );
};
