import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatchTestEmail } from 'useCases/emails';
import { useTranslate } from 'hooks';
import { useEmailFormContext } from 'contexts';
import { TextField } from 'components/form';
import { Button, Form, Modal } from 'components/structure';
import * as S from './EmailTestModal.styles';
import { schema } from './EmailTestModal.validations';

type EmailTestModalProps = {
  show: boolean;
  setShow: (value: boolean) => void;
};

type EmailTestFormModel = {
  destination: string;
};

export const EmailTestModal = ({ show, setShow }: EmailTestModalProps) => {
  const translate = useTranslate();
  const { filledData } = useEmailFormContext();
  const { isPending, handleDispatchTestEmail } = useDispatchTestEmail();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailTestFormModel>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const onSubmit = (data: EmailTestFormModel) => {
    if (filledData) {
      handleDispatchTestEmail({ ...data, ...filledData }, () => setShow(false));
    }
  };

  return (
    <Modal
      noIconContainer
      title={translate('event.editEvent.emailForm.testModal.title')}
      description={translate('event.editEvent.emailForm.testModal.description')}
      showModal={show}
      setShowModal={setShow}
      hasButton={false}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.Wrapper>
          <S.FieldWrapper>
            <TextField
              ref={register}
              name="destination"
              error={!!errors.destination}
              helperText={translate(errors.destination?.message)}
              label={translate(
                'event.editEvent.emailForm.testModal.inputLabel',
              )}
              placeholder={translate(
                'event.editEvent.emailForm.testModal.inputPlaceholder',
              )}
            />
          </S.FieldWrapper>
          <S.ButtonsWrapper>
            <Button
              type="button"
              color="secondary"
              onClick={() => setShow(false)}
            >
              {translate('event.editEvent.emailForm.testModal.cancel')}
            </Button>
            <Button type="submit" disabled={isPending} isLoading={isPending}>
              {translate('event.editEvent.emailForm.testModal.send')}
            </Button>
          </S.ButtonsWrapper>
        </S.Wrapper>
      </Form>
    </Modal>
  );
};
