import styled, { css, DefaultTheme } from 'styled-components';
import {
  SubTitle,
  Paragraph,
} from 'components/structure/Typography/Typography';
import { CardProps } from './CreateEvent';

type CardPropsShort = Pick<CardProps, 'color' | 'isOnLeft'>;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.xl};
    padding-top: ${theme.spacing['3xl']};
    gap: 0 ${theme.spacing['2xl']};
  `}
`;

const asideModifiers = {
  left: () => css`
    flex-direction: column;
    max-width: 450px;
    gap: 0;
    padding-top: 0;
  `,
};

export const Aside = styled.div<{ left?: boolean }>`
  ${({ theme, left }) => css`
    text-align: left;
    display: flex;
    gap: 0 ${theme.spacing.xl};
    padding-top: ${theme.spacing['2xl']};

    ${SubTitle} {
      color: #28c76f;
      text-shadow: 0px 5px 16px rgba(46, 229, 119, 0.2);
    }

    ${Paragraph} {
      font-size: ${theme.typography.sizes.sm};
    }

    ${left && asideModifiers.left()}
  `}
`;

const cardModifiers = {
  isOnLeft: () => css`
    background: linear-gradient(135deg, #ce9ffc 0%, #7367f0 100%);

    img {
      top: -20%;
      right: -4%;
    }

    &:hover {
      box-shadow: 0px 4px 36px 11px rgba(149, 124, 244, 0.27);
    }
  `,
  color: (theme: DefaultTheme) => css`
    color: ${theme.colors.grayscale.dark.paragraph};
    ${SubTitle} {
      color: ${theme.colors.grayscale.dark.paragraph};
    }
  `,
};

export const Card = styled.article<CardPropsShort>`
  ${({ theme, color, isOnLeft }) => css`
    display: flex;
    height: 300px;
    width: 320px;
    background: linear-gradient(135deg, #81fbb8 0%, #28c76f 100%);
    border: 1px solid ${theme.colors.grayscale.dark.line};
    border-radius: ${theme.shapes.borderRadius.sm};
    position: relative;
    cursor: pointer;
    transition: box-shadow ease-in-out 0.3s;
    color: ${theme.colors.grayscale.light.title};

    ${SubTitle} {
      color: ${theme.colors.grayscale.light.title};
    }

    img {
      position: absolute;
      top: -48%;
      right: 33%;
    }

    &:hover {
      box-shadow: 0px 4px 36px 11px rgba(46, 229, 119, 0.27);
    }

    ${color === 'light' && cardModifiers.color(theme)}
    ${isOnLeft && cardModifiers.isOnLeft()}
  `}
`;

export const FooterCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 0 ${theme.spacing.sm} ${theme.spacing.xs} ${theme.spacing.lg};
    align-self: flex-end;
    justify-self: end;
    position: relative;
    width: 100%;

    svg {
      position: absolute;
      right: ${theme.spacing.sm};
      background: ${theme.colors.background.dark.icon};
      border-radius: 50%;
      width: 32px;
      height: 32px;
      padding: ${theme.spacing['2xs']};
    }
  `}
`;
