type DescriptionProps = {
  normalPhrase: string;
  strongPhrase: string;
};

export const Description = ({
  normalPhrase,
  strongPhrase,
}: DescriptionProps) => (
  <>
    <p>{normalPhrase}</p>
    <strong>{strongPhrase}</strong>
  </>
);
