import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useEventStore } from 'store';
import { Term } from 'useCases';
import { useTranslate } from 'hooks';
import { FileInput } from 'components/form/FileInput/FileInput';
import * as S from './Terms.styles';
import { schema } from './Terms.validations';

enum TERM_FILE_ID {
  termsFile = 'termsId',
  conditionsFile = 'conditionsId',
}

export type TermsProps = {
  formId: string;
  onSubmit?: (terms: Array<Term>, files: Array<File>) => void;
  onChange?: (
    filledData: TermsFormFiles,
    inputsData: TermsFormInputsType,
  ) => void;
  defaultValues?: TermsFormInputsType & {
    termsId?: string;
    conditionsId?: string;
  };
};

export type TermsFormInputsType = {
  termsFile: string;
  conditionsFile: string;
};

export type TermsFormFiles = {
  termsFile?: File;
  conditionsFile?: File;
};

const files: TermsFormFiles = {};

export const Terms = ({
  formId,
  onChange: onChangeForm,
  defaultValues,
  onSubmit,
}: TermsProps) => {
  const translate = useTranslate();
  const { eventId } = useEventStore();
  const {
    control,
    handleSubmit,
    errors,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm<TermsFormInputsType>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onChange = () => {
    onChangeForm?.(
      {
        termsFile: undefined,
        conditionsFile: undefined,
        ...files,
      },
      { ...defaultValues, ...getValues() },
    );
  };

  const handleFile = (key: 'termsFile' | 'conditionsFile') => (file: File) => {
    files[key] = file;
  };

  const onSubmitForm = (data: TermsFormInputsType) => {
    onSubmit?.(
      Object.keys(files).map((key, index) => ({
        eventId,
        name: key,
        isRequiredTerm: true,
        description: data[key as keyof TermsFormInputsType],
        id: defaultValues?.[TERM_FILE_ID[key as keyof TermsFormInputsType]],
        priority: index,
      })),
      Object.values(files).map((file) => file) as Array<File>,
    );
  };

  useEffect(() => {
    if (!isDirty) {
      reset(defaultValues || {});
    }
  }, [defaultValues, isDirty, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} id={formId} onChange={onChange}>
      <S.Wrapper>
        <Controller
          name="termsFile"
          control={control}
          render={({ value, onChange }) => (
            <FileInput
              value={value}
              onChange={onChange}
              onChangeFile={handleFile('termsFile')}
              fileType="application/pdf"
              label={translate('event.editEvent.termsForm.terms.termsInput')}
              helperText={translate(
                errors.termsFile?.message ||
                  'event.editEvent.termsForm.terms.fileSize',
              )}
              emptyText={translate('event.editEvent.termsForm.empty')}
              error={!!errors.termsFile}
            />
          )}
        />
        <Controller
          name="conditionsFile"
          control={control}
          render={({ value, onChange }) => (
            <FileInput
              value={value}
              onChange={onChange}
              onChangeFile={handleFile('conditionsFile')}
              fileType="application/pdf"
              label={translate(
                'event.editEvent.termsForm.terms.conditionsInput',
              )}
              helperText={translate(
                errors.conditionsFile?.message ||
                  'event.editEvent.termsForm.terms.fileSize',
              )}
              emptyText={translate('event.editEvent.termsForm.empty')}
              error={!!errors.conditionsFile}
            />
          )}
        />
      </S.Wrapper>
    </form>
  );
};
