import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    .react-datepicker {
      border: 1px solid ${theme.colors.grayscale.dark.line};
      border-radius: 4px;
      background: ${theme.colors.neutral.black};
      font-family: ${theme.typography.family.secondary};

      &__input-container {
        input {
          padding: 0 ${theme.spacing.sm};
        }
      }

      &__header,
      &__month,
      &__month-container {
        background: ${theme.colors.neutral.black};
      }

      &__header {
        border-bottom-color: ${theme.colors.grayscale.dark.line};
      }

      &-popper[data-placement^='top'] .react-datepicker__triangle {
        border-top-color: ${theme.colors.neutral.black};

        &::before {
          border-top-color: ${theme.colors.grayscale.dark.line};
        }
      }

      &-popper[data-placement^='bottom'] .react-datepicker__triangle {
        border-bottom-color: ${theme.colors.neutral.black};

        &::before {
          border-bottom-color: ${theme.colors.grayscale.dark.line};
        }
      }

      &__navigation {
        &--previous {
          border-right-color: ${theme.colors.primary.main};
        }

        &--next {
          border-left-color: ${theme.colors.primary.main};
        }
      }

      &__current-month {
        color: ${theme.colors.neutral.white};
      }

      &__day {
        background: ${theme.colors.neutral.black};
        color: ${theme.colors.neutral.white};
        border-radius: 2px;

        &-name,
        &--outside-month {
          color: ${theme.colors.grayscale.dark.info};
        }

        &--selected {
          background: ${theme.colors.primary.main};
          color: ${theme.colors.neutral.black};
        }
      }
    }
  `}
`;
