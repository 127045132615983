import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';

type TimezoneData = {
  date: string;
  hour: string;
};

export const parseTimeZoneToUtc = (
  { date, hour }: TimezoneData,
  timeZone: string,
) => {
  const utcDate = zonedTimeToUtc(`${date} ${hour}`, timeZone);
  const formatToISO = utcDate.toISOString();

  return formatToISO;
};

export const parseUtctoTimeZone = (
  dateValue: string,
  timeZone: string,
): TimezoneData => {
  const utcDate = utcToZonedTime(dateValue, timeZone);

  const formatDate = format(utcDate, 'yyyy-MM-dd HH:mm', {
    timeZone,
  });

  const [date, hour] = formatDate.split(' ');

  return { date, hour };
};
