import { SocialNetwork } from './social-network';

export type EditEventEmailsCategory =
  | 'SUBSCRIBE'
  | 'FORGOT_PASSWORD'
  | 'WELCOME';

export enum EmailNames {
  SUBSCRIBE = 'event.editEvent.emailForm.confirmation.title',
  FORGOT_PASSWORD = 'event.editEvent.emailForm.recoverPass.title',
  WELCOME = 'event.editEvent.emailForm.welcome.title',
}

export type EmailFormInputsType = {
  headerImage: string;
  subject: string;
  title: string;
  paragraph: string;
  button: string;
  socialNetworks: Array<{ value: string }>;
  footer: string;
  id?: string;
};

type WithFile = {
  headerImage?: File | string;
};

type WithSocialNetworks = {
  socialNetworks: Array<SocialNetwork>;
};

export type EmailFormWithFiles = Omit<EmailFormInputsType, 'headerImage'> &
  WithFile;

export type EmailFormWithSocialNetworks = Omit<
  EmailFormInputsType,
  'socialNetworks'
> &
  WithSocialNetworks;

export type EmailFormFilled = Omit<
  EmailFormInputsType,
  'headerImage' | 'socialNetworks'
> &
  WithFile &
  WithSocialNetworks;

export type EmailRequested = {
  baseBoard: string;
  button: string;
  category: EditEventEmailsCategory;
  createdAt: string;
  deletedAt: string | null;
  eventId: string;
  id: string;
  imageHeader: string;
  imageUrl: string;
  language: 'pt' | 'es' | 'en';
  paragraph: string;
  subject: string;
  title: string;
  type: 'event' | 'platform';
  updatedAt: string;
};
