import { MutableRefObject, useEffect } from 'react';
import { useHover } from 'hooks/useHover';
import { usePortal } from 'hooks/usePortal';

export type PopoverPosition = 'bottom'; //| 'bottom-center' | 'bottom-end';

type usePopoverProps<T extends HTMLElement> = {
  anchor: MutableRefObject<T | null>;
  position: PopoverPosition;
};

const positions = {
  bottom: (el: HTMLElement, rect: DOMRect) => {
    const { top, left, height } = rect;
    el.style.display = 'inline';
    el.style.position = 'absolute';
    el.style.top = `${top + height + 20}px`;
    el.style.left = `${left - 23}px`;
  },
};

export const usePopover = <T extends HTMLElement>({
  anchor,
  position,
}: usePopoverProps<T>) => {
  const { Portal, portalRef } = usePortal();
  useHover<T>({
    ref: anchor,
    onMouseOver: () => {
      if (anchor.current) {
        positions[position](
          portalRef.current,
          anchor.current.getBoundingClientRect(),
        );
      }
    },
    onMouseLeave: () => {
      portalRef.current.style.display = 'none';
    },
  });

  useEffect(() => {
    portalRef.current.style.display = 'none';
    portalRef.current.style.position = 'absolute';
  }, [portalRef]);

  return {
    Portal,
  };
};
