const APP_BASE = '';

class Route<T> {
  public base: string;
  public children: T;

  constructor(base: string, children: T) {
    this.base = base;
    this.children = children;
  }

  private linkFactory = (
    key?: keyof T,
    routeParamValue = '',
    childToGet?: T[keyof T],
  ) => {
    if (key) {
      const child = this.children[key];
      let childAsPath = child instanceof Route ? child.base : child;
      if (child instanceof Route && childToGet) {
        childAsPath = `${child.base}\\${child.children[childToGet]}`;
      }

      const routeParams = String(childAsPath).match(/:[A-Za-z]+/g);

      return `${childAsPath}`
        .replace(/\//g, '')
        .replace(/\\/g, '/')
        .replace(routeParams ? routeParams[0] : ':id', `${routeParamValue}/`);
    }
  };

  public getLink = (key?: keyof T, id = '', child?: T[keyof T]) => {
    let link = '';
    const baseUrl = this.base.replace(/\//g, '');

    if (APP_BASE) {
      link += `/${APP_BASE}`;
    }

    if (baseUrl) {
      link += `/${baseUrl}`;
    }

    link += `/${this.linkFactory(key, id, child)}`;

    return link;
  };
}

export const ROUTES = {
  app: new Route('/', {
    events: 'events',
    organizations: 'organizations',
  }),
  editEvent: new Route('edit-event', {
    root: '/',
    interests: 'interests',
    emails: new Route('emails', {
      root: '/',
      recoverPass: 'recover-pass',
      welcome: 'welcome',
    }),
    customization: new Route('customization', {
      root: '/',
      theme: 'theme',
      images: 'images',
    }),
    terms: new Route('terms', {
      root: '/',
      preview: '/:preview',
      otherTerms: 'others',
    }),
  }),
  events: new Route('create-event', {
    root: '/',
    schedule: 'schedule',
    type: 'type',
  }),
  organizations: new Route('organizations', {
    root: '/',
    create: 'create',
    id: ':id',
  }),
  accreditation: new Route('accreditation', {
    signUp: '/',
  }),
  login: new Route('login', {
    signIn: '/',
    resetPassword: ':token/reset-password',
    forgotPassword: 'recover',
    confirmRegistration: ':token/confirm-registration',
  }),
} as const;
