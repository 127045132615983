import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  transition: opacity 0.3s ease-in-out;
`;

export const InterestInput = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 380px;
    padding-bottom: ${theme.spacing.sm};
    > :first-child {
      flex: 1;
    }
    & + div {
      padding-top: ${theme.spacing.lg};
      border-top: 1px solid ${theme.colors.grayscale.dark.line};
    }
  `}
`;
