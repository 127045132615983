import { useNavigate } from 'react-router-dom';
import { useForgotPassword } from 'useCases/auth';
import { useTranslate } from 'hooks';
import { useModalContext } from 'contexts';
import { ForgotPasswordForm } from 'components/contexts/auth';
import { Icon } from 'components/structure';
import { ROUTES } from 'constants/urls';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { setModalProps, setShowModal } = useModalContext();

  const onEmailSended = () => {
    setModalProps({
      icon: <Icon icon="IcMail" />,
      title: translate('auth.forgotPassword.modal.title'),
      description: translate('auth.forgotPassword.modal.description'),
      buttonLabel: translate('labels.understood'),
      onClose: () => navigate(ROUTES.login.getLink('signIn')),
    });
    setShowModal(true);
  };

  const { handleForgotPassword, isPending, showRecaptcha, recaptchaRef } =
    useForgotPassword({
      onEmailSended,
    });

  return (
    <ForgotPasswordForm
      onSubmit={handleForgotPassword}
      isPending={isPending}
      showRecaptcha={showRecaptcha}
      recaptchaRef={recaptchaRef}
    />
  );
};
