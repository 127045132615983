import { useCallback, useEffect, useState } from 'react';
import {
  DeepMap,
  FieldError,
  UseFieldArrayMethods,
  UseFormMethods,
} from 'react-hook-form';
import { useDeleteInterests } from 'useCases';
import { useTranslate } from 'hooks';
import { useToast } from 'contexts';
import {
  InterestType,
  InterestError,
  InterestsFormModel,
} from 'components/contexts';
import { TextField } from 'components/form';
import { ActionsButton } from 'components/structure';
import * as S from './Form.styles';

type FormMethods = Pick<UseFormMethods, 'register'> &
  Pick<UseFieldArrayMethods, 'remove' | 'fields'>;

type FormProps = FormMethods & {
  interests: InterestType[];
  setHasErrors: (error: boolean) => void;
  submittedErrors: DeepMap<InterestsFormModel, FieldError>;
  eventId: string;
};

const FIRST_INDEX = 1;
const MAX_INTEREST_LENGTH = 25;

export const Form = ({
  fields,
  interests,
  register,
  remove,
  setHasErrors,
  submittedErrors,
  eventId,
}: FormProps) => {
  const { setToast } = useToast();
  const translate = useTranslate();
  const { handleDeleteInterest, isDeletePending } = useDeleteInterests();
  const [errors, setErrors] = useState<InterestError[]>([]);

  useEffect(() => {
    const currentArrayLength = interests.length;
    const errorItems = Array.from<unknown, InterestError>(
      { length: currentArrayLength },
      () => ({
        message: '',
      }),
    );
    setErrors([...errorItems]);
  }, [interests.length, setErrors]);

  useEffect(() => {
    const interestArray: InterestType[] = [];
    interests.forEach((item, index) => {
      const hasSameValue = interestArray.find(({ name }) => name === item.name);

      const { interests: errors } = submittedErrors;

      if (errors) {
        return setErrors((prevState) => {
          const items = [...prevState];
          const error = errors[index]?.name;

          if (error?.message) {
            items[index] = {
              message: error.message,
            };
          }
          return items;
        });
      }

      if (hasSameValue) {
        setErrors((prevState) => {
          const items = [...prevState];
          items[index] = {
            message: 'event.editEvent.interestsForm.duplicated',
          };
          return items;
        });
      } else {
        interestArray.push(item);
        setErrors((prevState) => {
          const items = [...prevState];
          items[index] = { message: '' };
          return items;
        });
      }
    });
  }, [interests, submittedErrors]);

  useEffect(() => {
    setHasErrors(errors.some((error) => error.message));
  }, [errors, setHasErrors]);

  const handleRemoveItem = useCallback(
    async (index: number) => {
      const interest = interests[index];
      if (interest.id) await handleDeleteInterest(interest.id, eventId);
      remove(index);
      setToast({
        type: 'alert',
        description: translate.withValues(
          'event.editEvent.interestsForm.delete',
          { interest: interest.name },
        ),
      });
    },
    [interests, handleDeleteInterest, eventId, remove, setToast, translate],
  );

  return (
    <S.Wrapper>
      {fields.map((field, index) => (
        <S.InterestInput key={field.id}>
          <TextField
            ref={register()}
            name={`interests[${index}].name`}
            label={translate('event.editEvent.interestsForm.name')}
            placeholder={translate('event.editEvent.interestsForm.name')}
            autoComplete="off"
            defaultValue={field.name}
            error={!!errors[index]?.message}
            helperText={translate(errors[index]?.message)}
            maxLength={MAX_INTEREST_LENGTH}
          />
          {fields.length !== FIRST_INDEX && (
            <ActionsButton
              icon="IcDelete"
              onClick={() => handleRemoveItem(index)}
              disabled={isDeletePending}
            />
          )}
        </S.InterestInput>
      ))}
    </S.Wrapper>
  );
};
