import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  height: calc(100vh - 230px);
  width: 90%;
`;

export const Document = styled.object`
  ${({ theme }) => css`
    padding: ${theme.spacing.md};
    width: 100%;
    height: 100%;

    ${media.lessThan('medium')`
      padding: ${theme.spacing.md} ${theme.spacing.xs} ${theme.spacing.md};
    `}
  `}
`;
