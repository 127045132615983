export const colors = {
  primary: {
    main: '#2EE577',
    hover: '#2EE577',
    active: '#2EE577',
    inative: '#0d6831',
  },
  secondary: {
    main: '#2EE577',
    hover: '#2EE577',
    active: '#2EE577',
  },
  tertiary: {
    main: '#66d9a3',
    active: '#47c885',
    hover: '#5ad297',
  },
  neutral: {
    black: '#000000',
    filter: 'rgba(0, 0, 0, 0.6)',
    modal: 'rgba(0, 0, 0, 0.8)',
    materialVideoOverlay: 'rgba(0, 0, 0, 0.4)',
    white: '#ffffff',
  },
  grayscale: {
    dark: {
      title: '#ffffff',
      paragraph: '#ffffff',
      info: 'rgba(255, 255, 255, 0.6)',
      line: 'rgba(255, 255, 255, 0.2)',
      ellipse: '#36393F',
    },
    light: {
      title: '#000000',
      paragraph: 'rgba(0, 0, 0, 0.8)',
      info: 'rgba(0, 0, 0, 0.5)',
      line: 'rgba(0, 0, 0, 0.2)',
    },
  },
  background: {
    dark: {
      input: 'rgba(0, 0, 0, 0.5)',
      button: 'rgba(0, 0, 0, 0.01)',
      icon: 'rgba(0, 0, 0, 0.35)',
      menu: {
        main: '#131313',
        activeItem: '#1f1f1f',
        border: '#484848',
        sidebar: '#1A181C',
      },
    },
    light: {
      input: 'rgba(255, 255, 255, 0.5)',
      button: 'rgba(255, 255, 255, 0.01)',
    },
    outlet: {
      public: '#222222',
      private: {
        dark: '#181515',
        light: '#3b3b3b',
      },
    },
    float: {
      solid: '#1d2224',
    },
    modal: {
      gray: '#343434',
      dark: '#1e1b1b ',
    },
    select: {
      main: '#191919',
      dark: '#202020',
    },
    menu: {
      main: '#212121',
      light: '#181818',
      dark: '#131313',
    },
  },
  contextual: {
    info: {
      main: '',
      hover: '',
      active: '',
    },
    success: {
      main: '#38cd3c',
      hover: '#2fc533',
      active: '#22b825',
    },
    attention: {
      main: '#fda700',
      hover: '',
      active: '',
    },
    danger: {
      main: '#ff0202',
      hover: '#cc0202',
      active: '#990303',
    },
    disabled: {
      main: 'rgba(0, 0, 0, 0.4);',
      hover: '',
      active: '',
    },
  },
  skeleton: {
    backgroundColor: '#c4c4c4',
    foregroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  message: {
    light: 'rgba(255, 255, 255, 0.15)',
  },
};
