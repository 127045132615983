import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useEventStore } from 'store';
import { Term } from 'useCases';
import { useTranslate } from 'hooks';
import { Checkbox, TextField, TextArea } from 'components/form';
import { FileInput } from 'components/form/FileInput/FileInput';
import { Icon, ActionsButton, Form } from 'components/structure';
import * as S from './OthersTerms.styles';
import { defaultValues, schema } from './OthersTerms.validations';

const PRIORITY_STARTS_AT = 2;

export type OthersTermsProps = {
  formId: string;
  onSubmit?: (terms: Array<Term>, files: Array<File>) => void;
  onChange?: (
    filledData: OthersTermsFormFilled,
    inputsData: OthersTermsFormModel,
  ) => void;
  defaultValues?: OthersTermsFormModel & {
    othersTerms: Array<{ _id?: string }>;
  };
  handleSwapItems: (swap: (from: number, to: number) => void) => void;
};

export type OthersTermsFormFilled = Array<{
  name: string;
  description: string;
  file?: File;
  check: boolean;
}>;

export type OthersTermsFormModel = {
  othersTerms: Array<{
    name: string;
    description: string;
    file?: string;
    check: boolean;
  }>;
};

type OthersTermsFormFiles = {
  othersTermsFile: Array<File>;
};

const files: OthersTermsFormFiles = { othersTermsFile: [] };

export const OthersTerms = ({
  formId,
  onChange,
  onSubmit,
  defaultValues: defaultValuesProp,
  handleSwapItems,
}: OthersTermsProps) => {
  const translate = useTranslate();
  const {
    control,
    handleSubmit,
    errors,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm<OthersTermsFormModel>({
    resolver: yupResolver(schema),
    defaultValues,
    shouldUnregister: false,
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'othersTerms',
  });

  const { eventId } = useEventStore();

  const handleFile =
    (key: 'othersTermsFile', index: number) => (file: File) => {
      files[key][index] = file;
    };

  const handleChangeForm = () => {
    const dataWithFiles = getValues().othersTerms.map((term, index) => ({
      ...term,
      id: defaultValuesProp?.othersTerms?.[index]?._id,
      file: files.othersTermsFile[index],
    }));

    onChange?.(dataWithFiles, getValues());
  };

  useEffect(() => {
    if (!isDirty && defaultValuesProp?.othersTerms.length) {
      reset(defaultValuesProp);
    }
  }, [defaultValuesProp, isDirty, reset]);

  useEffect(() => {
    handleSwapItems(move);
  }, [handleSwapItems, move]);

  return (
    <>
      <Form
        onSubmit={handleSubmit((data) =>
          onSubmit?.(
            Object.values(data.othersTerms).map((term, index) => ({
              eventId,
              name: term.name,
              isRequiredTerm: term.check,
              description: term.description,
              id: defaultValuesProp?.othersTerms?.[index]?._id,
              priority: index + PRIORITY_STARTS_AT,
            })),
            files.othersTermsFile,
          ),
        )}
        id={formId}
        onChange={handleChangeForm}
      >
        {fields.map((field, index) => (
          <Controller
            key={field.id}
            name={`othersTerms[${index}]`}
            control={control}
            render={({ value, onChange }) => (
              <S.FormWrapper>
                <TextField
                  name={`othersTerms.[${index}].name`}
                  value={value?.name}
                  onChange={({ target: { value: newValue } }) => {
                    onChange({ ...value, name: newValue });
                  }}
                  label={translate('labels.othersTermsName')}
                  placeholder={translate('labels.othersTermsName')}
                  error={!!errors.othersTerms?.[index]?.name?.message}
                  helperText={translate(
                    errors.othersTerms?.[index]?.name?.message,
                  )}
                />
                <TextArea
                  name={`othersTerms.[${index}].description`}
                  value={value?.description}
                  onChange={({ target: { value: newValue } }) => {
                    onChange({ ...value, description: newValue });
                  }}
                  label={translate('labels.othersTermsDescription')}
                  placeholder={translate('labels.othersTermsDescription')}
                  error={!!errors.othersTerms?.[index]?.description?.message}
                  helperText={translate(
                    errors.othersTerms?.[index]?.description?.message,
                  )}
                />
                <FileInput
                  name={`othersTerms.[${index}].file`}
                  value={value?.file}
                  onChange={({ target: { value: newValue } }) => {
                    onChange({ ...value, file: newValue });
                  }}
                  onChangeFile={handleFile('othersTermsFile', index)}
                  fileType="application/pdf"
                  label={translate(
                    'event.editEvent.termsForm.terms.termsInput',
                  )}
                  helperText={translate(
                    errors.othersTerms?.[index]?.file?.message ||
                      'event.editEvent.termsForm.terms.fileSize',
                  )}
                  emptyText={translate('event.editEvent.termsForm.empty')}
                  error={!!errors.othersTerms?.[index]?.file}
                />
                <Checkbox
                  name={`othersTerms.[${index}].check`}
                  value={value?.check}
                  onChange={({ target: { checked: newValue } }) => {
                    onChange({ ...value, check: newValue });
                  }}
                  error={errors.othersTerms?.[index]?.check?.message}
                  placeholder="checkbox"
                  label={translate('labels.othersTermsCheck')}
                />
                {fields.length > 1 && (
                  <S.ButtonWrapper>
                    <S.IconButton type="button" onClick={() => remove(index)}>
                      <Icon icon="IcDelete" />
                    </S.IconButton>
                  </S.ButtonWrapper>
                )}
              </S.FormWrapper>
            )}
          />
        ))}
        <ActionsButton
          icon="IcAdd"
          type="button"
          isAbsolute
          onClick={() => append({ ...defaultValues.othersTerms })}
        />
      </Form>
    </>
  );
};
