import { AccessTypeEvent } from 'constants/enums';

export const statusOptions = [
  {
    value: false,
    label: 'event.newEvent.options.isOpen',
  },
  {
    value: true,
    label: 'event.newEvent.options.isClosed',
  },
];

export const paymentOptions = [
  {
    value: AccessTypeEvent.FREE,
    label: 'event.newEvent.options.isFree',
  },
  {
    value: AccessTypeEvent.HOTMART,
    label: 'event.newEvent.options.isPaidHotmart',
  },
  {
    value: AccessTypeEvent.SYMPLA,
    label: 'event.newEvent.options.isPaidSympla',
  },
];
