import { useCallback, useState } from 'react';
import { mutate } from 'swr';
import { useAPI, useFetch, useTranslate } from 'hooks';
import {
  Language,
  ToastContextProps,
  useIntlContext,
  useToast,
} from 'contexts';
import {
  EditEventEmailsCategory,
  EmailFormFilled,
  EmailRequested,
} from 'interfaces/email';
import { EMAILS } from 'constants/endpoints';

type FormEmailParserParams = {
  email: EmailFormFilled;
  eventId?: string;
  category?: EditEventEmailsCategory;
  language?: Language;
  limitKeys?: Array<string>;
};

const toastSaveEmail = (
  translate: (id: string) => string,
): ToastContextProps => ({
  title: translate('event.editEvent.emailForm.saveToast.title'),
  description: translate('event.editEvent.emailForm.saveToast.description'),
  type: 'success',
});

const toastSendTestEmail = (
  translate: (id: string) => string,
): ToastContextProps => ({
  title: translate('event.editEvent.emailForm.testModal.toast.title'),
  description: translate(
    'event.editEvent.emailForm.testModal.toast.description',
  ),
  type: 'success',
});

const formEmailParser = ({
  email,
  category,
  eventId,
  language,
  limitKeys,
}: FormEmailParserParams) => {
  const parsedData = {
    title: email.title,
    imageHeader: email.headerImage instanceof File ? email.headerImage : null,
    fileContent:
      typeof email.headerImage === 'string' ? email.headerImage : null,
    paragraph: email.paragraph,
    button: email.button,
    baseBoard: email.footer,
    subject: email.subject,
    language,
    category,
    eventId,
  };

  const formData = new FormData();
  Object.entries(parsedData).forEach(([key, data]) => {
    if (data && (limitKeys ? limitKeys.includes(key) : true)) {
      formData.append(key, data);
    }
  });
  return { parsedData, formData };
};

export const useEmails = (eventId: string) =>
  useFetch<EmailRequested[]>(EMAILS.ROOT, {
    headers: {
      'event-id': eventId,
    },
  });

export const useCreateEmail = (eventId: string) => {
  const api = useAPI();
  const { language } = useIntlContext();
  const { setToast } = useToast();
  const translate = useTranslate();
  const [isPending, setIsPending] = useState(false);

  const handleCreateEmail = async (
    email: EmailFormFilled,
    category: EditEventEmailsCategory,
  ) => {
    setIsPending(true);
    try {
      const { data } = await api.post(
        EMAILS.ROOT,
        formEmailParser({ email, eventId, category, language }).formData,
        {
          headers: { 'event-id': eventId },
        },
      );
      mutate([EMAILS.ROOT, eventId], data);
      setToast(toastSaveEmail(translate));
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, handleCreateEmail };
};

export const useUpdateEmail = (eventId: string) => {
  const api = useAPI();
  const { setToast } = useToast();
  const translate = useTranslate();
  const [isPending, setIsPending] = useState(false);

  const handleUpdateEmail = async (emailId: string, email: EmailFormFilled) => {
    setIsPending(true);
    try {
      const body = formEmailParser({ email, limitKeys: ['imageHeader'] });

      let response = await api.put(
        EMAILS.BY_ID(emailId),
        { ...body.parsedData, imageHeader: null },
        {
          headers: { 'event-id': eventId },
        },
      );

      if (body.parsedData.imageHeader) {
        response = await api.patch(EMAILS.LOGO(emailId), body.formData);
      }

      mutate([EMAILS.ROOT, eventId], response.data);

      setToast(toastSaveEmail(translate));
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, handleUpdateEmail };
};

export const useDispatchTestEmail = () => {
  const api = useAPI();
  const { setToast } = useToast();
  const translate = useTranslate();
  const [isPending, setIsPending] = useState(false);

  const handleDispatchTestEmail = async (
    email: EmailFormFilled & { destination: string },
    callback?: () => void,
  ) => {
    setIsPending(true);
    try {
      const body = formEmailParser({ email }).formData;
      body.append('destination', email.destination);
      const image = body.get('imageHeader');
      if (image) {
        body.set('file', image);
        body.delete('imageHeader');
      }
      await api.post(EMAILS.DISPATCH_TEST, body);
      callback?.();
      setToast(toastSendTestEmail(translate));
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, handleDispatchTestEmail };
};

export const useCopyEmail = () => {
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);

  const handleCopyEmail = useCallback(
    async (
      id: string,
      category: EditEventEmailsCategory,
      callback?: (email: EmailRequested) => void,
    ) => {
      setIsPending(true);
      try {
        const { data }: { data: EmailRequested } = await api.post(
          EMAILS.COPY(id),
          {
            category,
          },
        );
        callback?.(data);
      } finally {
        setIsPending(false);
      }
    },
    [api],
  );

  return { isPending, handleCopyEmail };
};
