import { useTheme } from 'hooks';
import { SubTitle, Paragraph } from 'components/structure';
import { Icon, IconsKeys } from 'components/structure/Icon/Icon';
import * as S from './Menu.styles';

export type MenuProps = {
  title: string;
  subtitle: string;
  headerTitle?: string;
  headerIcon?: IconsKeys;
  onClickHeader?: () => void;
  children?: React.ReactNode;
};

export const Menu = ({
  title,
  subtitle,
  children,
  headerIcon = 'IcChevronLeft',
  headerTitle,
  onClickHeader,
}: MenuProps) => {
  const theme = useTheme();
  return (
    <S.Wrapper>
      <S.Section onClick={onClickHeader}>
        <S.HeaderWrapper>
          <Icon
            width="20px"
            height="20px"
            color={theme.colors.grayscale.dark.info}
            icon={headerIcon}
          />
          <span>{headerTitle}</span>
        </S.HeaderWrapper>
      </S.Section>
      <S.Section>
        <S.TitleWrapper>
          <SubTitle>{title}</SubTitle>
          <Paragraph>{subtitle}</Paragraph>
        </S.TitleWrapper>
      </S.Section>
      <S.Section>{children}</S.Section>
    </S.Wrapper>
  );
};
