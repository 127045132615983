import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm} 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  `}
`;

export const ListWrapper = styled(Wrapper)`
  width: 100%;
  align-items: unset;
  padding: unset;

  & > :first-child {
    margin-left: 23px;
    width: calc(100% - 23px);
  }
`;

export const SwitchWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border: 1px ${theme.colors.grayscale.dark.line} solid;
    border-radius: 5px;
    padding: ${theme.spacing.xs};
    margin: ${theme.spacing.sm} 0;
  `};
`;

export const TermWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  cursor: grab;

  :active {
    cursor: grabbing;
  }
`;
