import styled, { css, DefaultTheme } from 'styled-components';
import { EventStatus } from 'useCases/events';
import { Paragraph } from 'components/structure';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 230px;
    min-height: 173px;
    border-radius: ${theme.shapes.borderRadius.sm};
    border: 1px solid ${theme.colors.grayscale.dark.line};
    background: ${theme.colors.neutral.black};
    cursor: pointer;
    :hover {
      border: 1px solid ${theme.colors.primary.hover};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px ${theme.spacing.sm};
  `}
`;
export const Title = styled(Paragraph)`
  letter-spacing: 1px;
  font-size: 18px;
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px ${theme.spacing.sm};
  `}
`;

const statusWrapperModifiers = {
  [EventStatus.ONGOING]: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.contextual.success.main};
  `,
  [EventStatus.FUTURE]: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.contextual.attention.main};
  `,
  [EventStatus.FINISHED]: () => css`
    background-color: #94a2ab;
  `,
};

export const Status = styled.div<{ status: EventStatus }>`
  ${({ theme, status }) => css`
    width: 12px;
    height: 12px;
    border-radius: ${theme.shapes.borderRadius.full};
    background-color: ${theme.colors.contextual.success.main};
    margin: ${theme.spacing.xs} ${theme.spacing.xs} 0px 0px;
    ${status && statusWrapperModifiers[status](theme)}
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: ${theme.spacing.xs};
    ${Paragraph} {
      margin-bottom: 0px;
      margin-left: ${theme.spacing.sm};
    }
  `}
`;

export const Chip = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 19px;
    padding: 0px ${theme.spacing.xs};
    border-radius: ${theme.shapes.borderRadius.lg};
    border: 1px solid ${theme.colors.grayscale.dark.info};
    ${Paragraph} {
      font-size: ${theme.typography.sizes.sm};
      color: ${theme.colors.grayscale.dark.info};
      margin-bottom: 0px;
      margin-left: ${theme.spacing.xs};
    }
  `}
`;

export const IconButton = styled.div`
  cursor: pointer;
  position: relative;
`;

export const Tooltip = styled.div<{ open: boolean }>`
  ${({ theme, open }) => css`
    right: 0px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 51px;
    min-width: 128px;
    visibility: ${open ? 'visible' : 'hidden'};
    opacity: ${open ? 1 : 0};
    background ${theme.colors.neutral.black};
    border-radius: ${theme.shapes.borderRadius.xs};
    border: 1px solid ${theme.colors.grayscale.dark.line};
    transition: all 0.3s ease-in-out, opacity 0.3s 0.05s linear;
    padding-top: ${theme.spacing.xs};
     ::after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: ${theme.colors.neutral.black};
      border-left: 1px solid ${theme.colors.grayscale.dark.line};
      border-bottom: 1px solid ${theme.colors.grayscale.dark.line};
      position: absolute;
      top:-6px;
      right: 8px;
      transform: rotate(135deg);
    }
  `}
`;

export const TooltipItem = styled.div<{ primary?: boolean }>`
  ${({ theme, primary }) => css`
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: ${theme.spacing.xs} ${theme.spacing.sm};

    ${Paragraph} {
      font-size: ${theme.typography.sizes.sm};
      margin-bottom: 0px;
      margin-left: ${theme.spacing.xs};
      color: ${primary
        ? theme.colors.primary.main
        : theme.colors.neutral.white};
    }
  `}
`;
