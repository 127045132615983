import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as Button from 'components/structure/Button/Button.styles';
import { Heading } from 'components/structure/Typography/Typography';
import { TermsProps } from './Terms';

type WrapperProps = Pick<TermsProps, 'show'>;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, show }) => css`
    background-color: ${theme.colors.background.dark.input};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    transition: opacity 0.2s linear;
    padding: ${theme.spacing.xl} ${theme.spacing.md};
    opacity: ${show ? '1' : '0'};
    pointer-events: ${show ? 'all' : 'none'};
    z-index: ${theme.layers.aboveTheTop};
  `}
`;

type ContentProps = {
  ref?: React.Ref<HTMLDivElement | null>;
};

export const Content = styled.div<ContentProps>`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.white};
    font-family: ${theme.typography.family.primary};
    max-width: 1090px;
    max-height: 992px;
    padding: ${theme.spacing.lg} 0;
    height: 100%;
    width: 100%;

    ${Heading} {
      margin: 0 0 ${theme.spacing.md} ${theme.spacing.md};
      color: ${theme.colors.grayscale.light.title};
    }

    > div {
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing.md};

      ${Button.Wrapper} {
        align-self: flex-end;
      }
    }

    ${media.lessThan('medium')`
      word-break: break-word;
    `}
  `}
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const IconWrapper = styled.button`
  ${({ theme }) => css`
    appearance: none;
    color: ${theme.colors.neutral.white};
    margin-left: ${theme.spacing.sm};
    height: 14px;
  `}
`;

export const Document = styled.object`
  ${({ theme }) => css`
    padding: ${theme.spacing.md};
    width: 100%;
    height: 100%;

    ${media.lessThan('medium')`
      padding: ${theme.spacing.md} ${theme.spacing.xs} ${theme.spacing.md};
    `}
  `}
`;
