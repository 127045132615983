import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;

    & > *:first-child {
      width: 100%;
    }

    & > *:nth-child(2) {
      margin-left: ${theme.spacing.sm};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    & > p {
      margin: 0 0 0 ${theme.spacing.sm};
      color: ${theme.colors.grayscale.dark.info};
    }
  `}
`;

export const IconButton = styled.button`
  ${({ theme }) => css`
    position: relative;
    border-radius: 100%;
    background-color: ${theme.colors.grayscale.dark.ellipse};
    width: 30px;
    height: 30px;
    & > * {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 18px;
      height: 18px;
      color: white;
    }
  `}
`;
