import { parseISO, format } from 'date-fns';
import es from 'date-fns/locale/es';
import ptbr from 'date-fns/locale/pt-BR';
import { forwardRef, Ref, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useController, UseFormMethods } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'hooks';
import { TextField, TextFieldProps } from 'components/form';
import { HelperText } from 'components/form';
import { Icon } from 'components/structure';
import { DATE_FORMATS } from 'constants/enums';
import * as S from './Calendar.styles';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt-br', ptbr);
registerLocale('es', es);

export type CalendarProps = {
  name: string;
  placeholder: string;
  error?: string;
  locale: 'pt-br' | 'es' | 'en';
} & Pick<UseFormMethods, 'control'>;

const CustomInput = forwardRef(
  (props: TextFieldProps, ref: Ref<HTMLInputElement>) => {
    const theme = useTheme();

    return (
      <TextField
        {...props}
        ref={ref}
        mask="date"
        icon={
          <Icon
            height={'24px'}
            width={'24px'}
            color={theme.colors.grayscale.dark.info}
            icon="IcCalendar"
          />
        }
      />
    );
  },
);

export const Calendar = ({
  control,
  name,
  placeholder,
  error,
  locale,
}: CalendarProps) => {
  const {
    field: { ref, value, onChange },
  } = useController({
    control,
    name,
  });

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();

  const handleChange = (date: Date) => {
    if (!date) {
      onChange();
      return setSelectedDate(undefined);
    }

    setSelectedDate(date);
    onChange(format(date, 'yyyy-MM-dd'));
  };

  const formatDateValue = () =>
    value && format(parseISO(value), DATE_FORMATS[locale]);

  return (
    <S.Wrapper>
      <DatePicker
        name={name}
        ref={ref}
        dateFormat={DATE_FORMATS[locale]}
        locale={locale}
        selected={selectedDate}
        value={selectedDate ?? formatDateValue()}
        onChange={(date: Date) => handleChange(date)}
        customInput={<CustomInput error={!!error} />}
        placeholderText={placeholder}
        autoComplete="off"
        minDate={new Date()}
      />

      {!!error && (
        <HelperText error={!!error}>
          <FormattedMessage id={error} defaultMessage={error} />
        </HelperText>
      )}
    </S.Wrapper>
  );
};
