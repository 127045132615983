import { useCreateOrganization } from 'useCases/organizations';
import { useTranslate } from 'hooks';
import { NewOrganizationForm } from 'components/contexts';
import { Paragraph, SubTitle } from 'components/structure';
import * as S from './NewOrganization.styles';

export const NewOrganization = () => {
  const translate = useTranslate();
  const { createOrganization, isPending } = useCreateOrganization();

  return (
    <S.Wrapper>
      <SubTitle>{translate('organization.newOrganization.title')}</SubTitle>
      <Paragraph>
        {translate('organization.newOrganization.description')}
      </Paragraph>
      <NewOrganizationForm
        isPending={isPending}
        onSubmit={(values) => createOrganization(values)}
      />
    </S.Wrapper>
  );
};
