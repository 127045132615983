import styled, { css, DefaultTheme } from 'styled-components/macro';
import { HelperText } from 'components/form';
import { CheckboxProps } from './Checkbox';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing.lg};
    }
    ${HelperText} {
      padding: 0;
    }
  `}
`;

type LabelTextProps = Pick<CheckboxProps, 'labelPosition'>;

export const Checkbox = styled.span<LabelTextProps>`
  ${({ theme, labelPosition }) => css`
    border-radius: ${theme.shapes.borderRadius.xs};
    border: 2px solid ${theme.colors.grayscale.dark.info};
    display: inline-block;
    height: 20px;
    width: 20px;
    min-width: 20px;
    position: relative;
    transition: border-color 0.2s linear;
    margin-${labelPosition}: ${theme.spacing.xs};

    &::after {
      content: '';
      width: 8px;
      height: 12px;
      border: 3px solid ${theme.colors.primary.main};
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
      position: absolute;
      left: 25%;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.paragraph};
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.sm};
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    max-width: 600px;

    a {
      font-family: ${theme.typography.family.primary};
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: ${theme.colors.grayscale.dark.paragraph};
      font-family: inherit;
      text-decoration: underline;
    }
  `}
`;

export const LabelText = styled.span<LabelTextProps>`
  ${({ theme, labelPosition }) => css`
    color: ${theme.colors.grayscale.dark.paragraph};
    order: ${labelPosition === 'left' ? '0' : '2'};
  `}
`;

type CheckboxWrapperProps = Pick<CheckboxProps, 'dark'>;

const checkboxWrapperModifiers = {
  dark: (theme: DefaultTheme) => css`
    ${Checkbox} {
      border-color: ${theme.colors.tertiary};

      &::after {
        background-color: ${theme.colors.tertiary};
      }
    }

    ${Label} {
      color: ${theme.colors.grayscale.dark.paragraph};
    }
  `,
};

export const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  ${({ theme, dark }) => css`
    display: flex;
    justify-content: flex-start;

    ${dark && checkboxWrapperModifiers.dark(theme)};
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    display: none;

    &:checked + ${Label} > ${Checkbox} {
      border-color: ${theme.colors.primary.main};

      &::after {
        opacity: 1;
      }
    }
  `}
`;
