import styled, { css } from 'styled-components';
import * as Typography from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: ${theme.colors.background.outlet.public};
    width: 100%;
    height: 100%;
    min-height: 100vh;
    align-items: center;
  `}
`;

export const Apresentation = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${theme.colors.grayscale.dark.line};
    height: 400px;

    ${Typography.SubTitle} {
      margin: ${theme.spacing.xl} 0 0;
      font-weight: 600;
      text-align: center;
    }

    ${Typography.Paragraph} {
      margin: ${theme.spacing.xs} 0 0;
      color: ${theme.colors.grayscale.dark.paragraph};
    }
  `}
`;

export const OutletWrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 ${theme.spacing.lg};
    align-items: center;
    justify-content: center;
  `}
`;
