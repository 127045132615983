import { useNavigate, useParams } from 'react-router-dom';
import { useUpdatePassword } from 'useCases/auth';
import { useTranslate } from 'hooks';
import { useModalContext } from 'contexts';
import { ChangePasswordForm } from 'components/contexts/auth';
import { Icon } from 'components/structure';
import { ROUTES } from 'constants/urls';

export const ChangePassword = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { setModalProps, setShowModal } = useModalContext();
  const { token } = useParams();

  const onPasswordUpdated = () => {
    setModalProps({
      icon: <Icon icon="IcSuccess" />,
      title: translate('auth.changePassword.modal.title'),
      description: translate('auth.changePassword.modal.description'),
      buttonLabel: translate('labels.understood'),
      onClose: () => navigate(ROUTES.login.getLink('signIn')),
    });
    setShowModal(true);
  };

  const { handleUpdatePassword, isPending } = useUpdatePassword({
    onPasswordUpdated,
  });

  return (
    <ChangePasswordForm
      onSubmit={(values) => handleUpdatePassword({ ...values, token })}
      isPending={isPending}
    />
  );
};
