import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslate } from 'hooks';
import { ImagesPreview } from 'components/contexts/event/EditEvent/Customization/ImagesPreview/ImagesPreview';
import { FileInput } from 'components/form/FileInput/FileInput';
import { Button } from 'components/structure';
import * as S from './ImagesForm.styles';
import { schema } from './ImagesForm.validations';

type FormInputsType = {
  logoFile: string;
  sideImageFile: string;
};

type ImageFile = {
  file: File;
  url: string;
};

type FormFiles = {
  logoFile?: ImageFile;
  sideImageFile?: ImageFile;
};

export const ImagesForm = () => {
  const translate = useTranslate();
  const { control, handleSubmit, errors } = useForm<FormInputsType>({
    resolver: yupResolver(schema),
  });
  const [files, setFiles] = useState<FormFiles>({});

  const onSubmit = (data: FormInputsType) => {
    const dataWithFiles = {
      ...data,
      ...files,
    };

    // @TODO integration
    console.log(dataWithFiles);
  };

  const handleFile = (key: 'logoFile' | 'sideImageFile') => (file: File) => {
    setFiles((old) => ({
      ...old,
      [key]: { url: URL.createObjectURL(file), file },
    }));
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.EditAreaWrapper>
        <S.Wrapper>
          <Controller
            name="logoFile"
            control={control}
            render={({ value, onChange }) => (
              <FileInput
                icon="IcGallery"
                value={value}
                onChange={onChange}
                onChangeFile={handleFile('logoFile')}
                fileType="image/*"
                color="secondary"
                label={translate('event.editEvent.imagesForm.imageInput')}
                helperText={translate(
                  errors.logoFile?.message ||
                    'event.editEvent.termsForm.terms.fileSize',
                )}
                emptyText={translate('event.editEvent.termsForm.empty')}
                error={!!errors.logoFile}
              />
            )}
          />
          <Controller
            name="sideImageFile"
            control={control}
            render={({ value, onChange }) => (
              <FileInput
                icon="IcGallery"
                value={value}
                onChange={onChange}
                onChangeFile={handleFile('sideImageFile')}
                color="secondary"
                fileType="image/*"
                label={translate('event.editEvent.imagesForm.imageInput')}
                helperText={translate(
                  errors.sideImageFile?.message ||
                    'event.editEvent.termsForm.terms.fileSize',
                )}
                emptyText={translate('event.editEvent.termsForm.empty')}
                error={!!errors.sideImageFile}
              />
            )}
          />
        </S.Wrapper>
      </S.EditAreaWrapper>
      <S.PreviewAreaWrapper>
        <ImagesPreview
          logoImage={files.logoFile?.url}
          heroImage={files.sideImageFile?.url}
        />
        <S.ActionButtonWrapper>
          <Button type="submit">{translate('labels.save')}</Button>
        </S.ActionButtonWrapper>
      </S.PreviewAreaWrapper>
    </S.Form>
  );
};
