import { NavbarItem } from 'interfaces/navbar';

export default [
  {
    label: 'navbar.events',
    url: '/events',
  },
  {
    label: 'navbar.organizations',
    url: '/organizations',
  },
  {
    label: 'navbar.help',
    url: '/help',
  },
] as NavbarItem[];
