import { useTranslate } from 'hooks';
import { Anchor, Paragraph } from 'components/structure';

export const DefaultTermsLabel = () => {
  const translate = useTranslate();
  return (
    <Paragraph margin={'0'}>
      {translate('event.editEvent.termsForm.others.acceptDefaultTerms.accept')}{' '}
      <Anchor>
        {translate(
          'event.editEvent.termsForm.others.acceptDefaultTerms.conditions',
        )}
      </Anchor>{' '}
      {translate('event.editEvent.termsForm.others.acceptDefaultTerms.and')}{' '}
      <Anchor>
        {translate(
          'event.editEvent.termsForm.others.acceptDefaultTerms.policies',
        )}
      </Anchor>
    </Paragraph>
  );
};
