import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useOrganizationStore } from 'store';
import { useTranslate } from 'hooks';
import { useNewEventContext, NewEvent } from 'contexts';
import { CustomSelect, Option } from 'components/form';
import {
  Button,
  Form,
  FormGroup,
  SubTitle,
  Divisor,
} from 'components/structure';
import { ROUTES } from 'constants/urls';
import { schemaValidate, defaultValues } from './EventType.validation';
import { paymentOptions, statusOptions } from './EventTypeForm.mock';

type EventTypeProps = {
  onSubmit: (value: NewEvent) => void;
  isPending: boolean;
};

type EventTypeFormModel = {
  isClosed: boolean;
  accessType: string;
};

export const EventTypeForm = ({ onSubmit, isPending }: EventTypeProps) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const { newEventProps, setFormProgress, setNewEventProps } =
    useNewEventContext();
  const { control, errors, handleSubmit, reset, watch } =
    useForm<EventTypeFormModel>({
      resolver: yupResolver(schemaValidate),
      defaultValues,
    });

  const isClosed = watch('isClosed');
  const isFree = watch('isFree');

  const handleOnSubmit = (data: EventTypeFormModel) => {
    onSubmit({
      ...newEventProps,
      ...data,
      organizationId,
    });
  };

  useEffect(() => {
    const { isClosed, accessType } = newEventProps;

    if (typeof isClosed === 'boolean' && typeof accessType === 'number') {
      reset({
        isClosed,
        accessType,
      });
    }
  }, [reset, newEventProps]);

  const handleGoBack = () => {
    setNewEventProps((prevState) => ({
      ...prevState,
      isClosed,
      isFree,
    }));
    setFormProgress((prevState) => prevState - 1);
    navigate(ROUTES.events.getLink('schedule'));
  };

  const translatedOptions = (options: Option[]) =>
    options.map((status) => ({
      ...status,
      label: translate(status.label),
    }));

  return (
    <Form
      maxWidth="360px"
      fullWidth
      textAlign="center"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <SubTitle>{translate('event.newEvent.type')}</SubTitle>
      <FormGroup shortGap>
        <CustomSelect
          control={control}
          name="isClosed"
          label={translate('event.newEvent.category')}
          placeholder={translate('event.newEvent.category')}
          error={errors.isClosed?.message}
          options={translatedOptions(statusOptions)}
        />
        <CustomSelect
          control={control}
          name="accessType"
          label={translate('event.newEvent.accessType')}
          placeholder={translate('event.newEvent.accessType')}
          error={errors.accessType?.message}
          options={translatedOptions(paymentOptions)}
        />
      </FormGroup>

      <Divisor>
        <Button color="secondary" onClick={handleGoBack}>
          {translate('labels.back')}
        </Button>
        <Button type="submit" disabled={isPending} isLoading={isPending}>
          {translate('event.firstEvent.createEvent')}
        </Button>
      </Divisor>
    </Form>
  );
};
