import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Term } from 'useCases';
import { useTheme } from 'hooks';
import { useTranslate } from 'hooks';
import { Terms, LabelTerms } from 'components/contexts';
import { TextField, Checkbox, PasswordField } from 'components/form';
import {
  Anchor,
  Form,
  FormActions,
  FormGroup,
  Paragraph,
  SubTitle,
} from 'components/structure';
import Button from 'components/structure/Button/Button';
import { ROUTES } from 'constants/urls';
import { defaultValues, schemaValidate } from './AccreditationForm.validations';

export type AccreditationFormModel = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  rePassword: string;
  terms: boolean;
  termsId: string[];
};

type AccreditationFormProps = {
  onSubmit: (payload: AccreditationFormModel) => void;
  isPending: boolean;
  terms?: Term[];
};

export const AccreditationForm = ({
  onSubmit,
  isPending,
  terms,
}: AccreditationFormProps) => {
  const translate = useTranslate();
  const theme = useTheme();
  const { register, errors, handleSubmit } = useForm<AccreditationFormModel>({
    resolver: yupResolver(schemaValidate),
    defaultValues,
  });

  const [showTerms, setShowTerms] = useState(false);
  const [term, setTerm] = useState<Term>({} as Term);

  const handleSetCurrentTerm = (indexTerm: number) => {
    if (!terms) return;

    setTerm(terms[indexTerm]);
    setShowTerms(true);
  };

  const handleOnSubmit = (data: AccreditationFormModel) => {
    const payload = {
      ...data,
      termIds: terms?.map((term) => term.id),
    };

    onSubmit(payload);
  };

  return (
    <Form
      padding="xlg"
      fullWidth
      maxWidth="520px"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <SubTitle margin={`0 auto ${theme.spacing.lg}`}>
        {translate('accreditation.createAccount')}
      </SubTitle>

      <FormGroup shortMargin>
        <TextField
          name="firstName"
          ref={register}
          label={translate('labels.requiredName')}
          placeholder={translate('labels.name')}
          error={!!errors.firstName?.message}
          helperText={translate(errors.firstName?.message)}
        />
        <TextField
          name="lastName"
          ref={register}
          label={translate('labels.requiredLastName')}
          placeholder={translate('labels.lastName')}
          error={!!errors.lastName?.message}
          helperText={translate(errors.lastName?.message)}
        />
      </FormGroup>

      <FormGroup shortMargin>
        <TextField
          name="email"
          ref={register}
          label={translate('labels.requiredEmail')}
          placeholder={translate('labels.email')}
          error={!!errors.email?.message}
          helperText={translate(errors.email?.message)}
        />
      </FormGroup>

      <FormGroup>
        <PasswordField
          name="password"
          autoComplete="new-password"
          ref={register}
          label={translate('labels.requiredPassword')}
          placeholder={translate('labels.password')}
          error={!!errors.password?.message}
          helperText={translate(errors.password?.message)}
          minLength={8}
          showStrength
        />
        <PasswordField
          name="rePassword"
          autoComplete="new-password"
          ref={register}
          label={translate('labels.requiredPasswordConfirmation')}
          placeholder={translate('labels.passwordConfirmation')}
          error={!!errors.rePassword?.message}
          helperText={translate(errors.rePassword?.message)}
          minLength={8}
        />
      </FormGroup>

      <Checkbox
        ref={register}
        name="terms"
        error={errors.terms?.message}
        placeholder="checkbox"
        label={
          <LabelTerms
            handleSetIndexTerm={handleSetCurrentTerm}
            names={terms?.map((term) => term.name)}
          />
        }
      />
      <Terms
        term={term}
        show={showTerms}
        onConfirm={() => setShowTerms(false)}
      />
      <FormActions center>
        <Button isLoading={isPending} disabled={isPending} type="submit">
          {translate('labels.register')}
        </Button>
      </FormActions>

      <Paragraph margin={`${theme.spacing.lg} auto 0`}>
        {translate('accreditation.hasAccount')} {''}
        <Anchor as={Link} to={ROUTES.login.getLink('signIn')}>
          {translate('accreditation.login')}
        </Anchor>
      </Paragraph>
    </Form>
  );
};
