import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const authPT = {
  auth: pt,
};

export const authEN = {
  auth: en,
};

export const authES = {
  auth: es,
};
