import { InputHTMLAttributes, forwardRef, useCallback } from 'react';
import { useTheme } from 'hooks';
import { handleInputMask } from 'lib/contexts/input';
import { HelperText } from 'components/form';
import { Icon } from 'components/structure';
import * as S from './TextField.styles';

export type Mask = 'hour' | 'date' | 'url';

export type TextFieldProps = {
  label?: string;
  type?: string;
  error?: boolean;
  helperText?: string;
  icon?: React.ReactElement;
  mask?: Mask;
  showAlert?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      error,
      label,
      name,
      type,
      helperText,
      icon: IconProp,
      mask,
      showAlert = true,
      style,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();

    const handleKeyPress = useCallback(
      (e: React.FormEvent<HTMLInputElement>) => {
        handleInputMask(mask!, e);
      },
      [mask],
    );

    return (
      <S.Container style={style}>
        <S.InputWrapper hasError={error} disabled={props.disabled}>
          <S.Field
            type={type}
            ref={ref}
            id={name}
            name={name}
            onKeyPress={mask ? handleKeyPress : undefined}
            {...props}
          />
          {!!label && <S.Label htmlFor={name}>{label}</S.Label>}
          {error && showAlert ? (
            <S.IconContainer>
              <Icon
                color={theme.colors.contextual.danger.main}
                icon="IcAlert"
              />
            </S.IconContainer>
          ) : (
            !!IconProp && <S.IconContainer>{IconProp}</S.IconContainer>
          )}
        </S.InputWrapper>
        {helperText && <HelperText error={error}>{helperText}</HelperText>}
      </S.Container>
    );
  },
);
