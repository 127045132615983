import * as S from './MenuItem.styles';

export type MenuItemProps = {
  name: string;
  active?: boolean;
  onClick?: () => void;
  isSubitem?: boolean;
  children?: React.ReactNode;
};

export const MenuItem = ({
  children,
  name,
  active,
  onClick,
  isSubitem,
}: MenuItemProps) => (
  <>
    <S.Section active={active} onClick={onClick} isSubitem={isSubitem}>
      {name}
    </S.Section>
    {!!children && (
      <S.ChildrenWrapper active={!!active}>{children}</S.ChildrenWrapper>
    )}
  </>
);
