import { useNewEvent } from 'useCases';
import { EventTypeForm } from 'components/contexts';

export const EventType = () => {
  const { handleCreateNewEvent, isPending } = useNewEvent();

  return (
    <EventTypeForm isPending={isPending} onSubmit={handleCreateNewEvent} />
  );
};
