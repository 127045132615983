import { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { HelperText } from 'components/form';
import * as S from './Checkbox.styles';

export type CheckboxProps = {
  label?: string | JSX.Element;
  name: string;
  error?: string;
  dark?: boolean;
  labelPosition?: 'right' | 'left';
  noCheckOnLabel?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label = '',
      error = '',
      labelPosition = 'right',
      dark = false,
      name,
      noCheckOnLabel,
      ...props
    },
    ref,
  ) => (
    <S.Wrapper>
      <S.CheckboxWrapper dark={dark}>
        <S.Input ref={ref} type="checkbox" name={name} id={name} {...props} />
        <S.Label htmlFor={name}>
          {!!label && (
            <S.LabelText
              onClick={(e) => noCheckOnLabel && e.preventDefault()}
              labelPosition={labelPosition}
            >
              {label}
            </S.LabelText>
          )}
          <S.Checkbox labelPosition={labelPosition} />
        </S.Label>
      </S.CheckboxWrapper>
      {!!error && (
        <HelperText error={!!error}>
          <FormattedMessage id={error} defaultMessage={error} />
        </HelperText>
      )}
    </S.Wrapper>
  ),
);
