import styled, { css } from 'styled-components';
import { Paragraph } from 'components/structure';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.sm} ${theme.spacing.lg};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: ${theme.spacing.xl};
  `}
`;

export const FilterContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    ${Paragraph} {
      font-size: ${theme.typography.sizes.md};
      margin-bottom: 0px;
      margin-right: 14px;
    }
  `}
`;

export const SearchContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    > div {
      margin-right: ${theme.spacing.sm};
    }
  `}
`;

export const Tooltip = styled.div<{ open: boolean }>`
  ${({ theme, open }) => css`
    right: -7px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 51px;
    min-width: 128px;
    opacity: ${open ? 1 : 0};
    background ${theme.colors.neutral.black};
    border-radius: ${theme.shapes.borderRadius.xs};
    border: 1px solid ${theme.colors.grayscale.dark.line};
    transition: all 0.3s ease-in-out, opacity 0.3s 0.05s linear;
    padding-top: ${theme.spacing.xs};
     ::after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: ${theme.colors.neutral.black};
      border-left: 1px solid ${theme.colors.grayscale.dark.line};
      border-bottom: 1px solid ${theme.colors.grayscale.dark.line};
      position: absolute;
      top:-6px;
      right: 8px;
      transform: rotate(135deg);
    }
  `}
`;

export const Legend = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 10px;
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: ${theme.spacing.xs};
      ${Paragraph} {
        margin-left: ${theme.spacing.xs};
        margin-bottom: 0px;
      }
    }
  `}
`;

export const IconButton = styled.button`
  position: relative;
`;

export const EventContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: ${theme.spacing.sm};
    align-items: center;
  `}
`;
