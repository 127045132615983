import { useTranslate } from 'hooks';
import * as S from './LabelTerms.styles';

type LabelTermsProps = {
  handleSetIndexTerm: (index: number) => void;
  names?: Array<string>;
};

export const LabelTerms = ({ handleSetIndexTerm, names }: LabelTermsProps) => {
  const translate = useTranslate();

  return (
    <>
      {translate('accreditation.acceptTerms.accept')}
      <S.Button
        onClick={() => {
          const indexTerm = 1;
          handleSetIndexTerm(indexTerm);
        }}
        type="button"
      >
        {names?.[1]}
      </S.Button>
      {translate('accreditation.acceptTerms.and')}
      <S.Button
        onClick={() => {
          const indexTerm = 0;
          handleSetIndexTerm(indexTerm);
        }}
        type="button"
      >
        {names?.[0]}
      </S.Button>
    </>
  );
};
