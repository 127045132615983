import { useTheme } from 'hooks';
import { useComponentId } from 'hooks';

export const AvatarFallback = (props: React.SVGProps<SVGSVGElement>) => {
  const uniqueId = useComponentId();
  const { colors } = useTheme();

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...props}
    >
      <circle
        cx="24"
        cy="24"
        r="23.5"
        fill={colors.neutral.black}
        fillOpacity=".6"
        stroke="currentColor"
      />
      <mask id={String(uniqueId)} fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.451 40.372C8.971 33.28 15.742 28.2 23.7 28.2c8.13 0 15.02 5.3 17.406 12.634A23.927 23.927 0 0124 48c-6.927 0-13.168-2.934-17.549-7.628z"
        />
      </mask>
      <path
        d="M6.451 40.372l-.942-.334-.204.572.415.445.731-.683zm34.655.462l.713.702.426-.433-.188-.578-.951.31zm-33.713-.127C9.776 34 16.18 29.2 23.7 29.2v-2c-8.394 0-15.534 5.358-18.191 12.838l1.884.669zM23.7 29.2c7.683 0 14.2 5.01 16.455 11.944l1.902-.62C39.54 32.793 32.275 27.2 23.7 27.2v2zm16.693 10.933A22.927 22.927 0 0124 47v2a24.927 24.927 0 0017.819-7.464l-1.426-1.403zM24 47c-6.638 0-12.618-2.81-16.818-7.31L5.72 41.055C10.282 45.942 16.785 49 24 49v-2z"
        fill="currentColor"
        mask={`url(#${uniqueId})`}
      />
      <path
        d="M31.9 16.8a7.9 7.9 0 11-15.8 0 7.9 7.9 0 0115.8 0z"
        stroke="currentColor"
      />
    </svg>
  );
};
