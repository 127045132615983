import { useRef, useState } from 'react';
import { useTranslate } from 'hooks';
import { Avatar, CropPhoto } from 'components/contexts/organization';
import * as S from './ChoosePhoto.styles';

export type ChoosePhotoProps = {
  alt?: string;
  currentImage?: string;
  name?: string;
  isPending?: boolean;
  setValue: (name: string, value: File) => void;
  onImgPreviewChange?: (imgPreview: string) => void;
  onUploadProgress?: (progress: number) => void;
};

export const ChoosePhoto = ({
  alt,
  currentImage = '',
  name = 'userImage',
  setValue,
  onImgPreviewChange,
}: ChoosePhotoProps) => {
  const translate = useTranslate();
  const inputFile = useRef<HTMLInputElement>(null);
  const [imgPreview, setImgPreview] = useState(currentImage);
  const [showCrop, setShowCrop] = useState(false);
  const [photoToCrop, setPhotoToCrop] = useState<File>();

  const handleChooseImage = () => {
    if (inputFile?.current) {
      inputFile.current.value = '';
      inputFile.current.click();
    }
  };

  const handleCroppedImage = (file: File) => {
    const photoUrl = URL.createObjectURL(file);
    setImgPreview(photoUrl);
    onImgPreviewChange && onImgPreviewChange(photoUrl);
    setValue(name, file);
  };

  const handleOnChange = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {
    const image = fileEvent.target?.files?.item(0);

    if (image) {
      updateImagePreview(URL.createObjectURL(image));
      setPhotoToCrop(image);
      setShowCrop(true);
    }
  };

  const updateImagePreview = (image: string) => {
    onImgPreviewChange && onImgPreviewChange(image);
  };

  return (
    <>
      <S.Wrapper>
        <S.UploadWrapper onClick={handleChooseImage}>
          <Avatar src={imgPreview} alt={alt} size="large" />
          <S.UploadMask>
            {translate('organization.newOrganization.changeLogo')}
          </S.UploadMask>
        </S.UploadWrapper>

        <input
          onChange={handleOnChange}
          ref={inputFile}
          accept="image/*"
          type="file"
        />
      </S.Wrapper>

      <CropPhoto
        showModal={showCrop}
        setShowModal={setShowCrop}
        imageToCrop={photoToCrop!}
        setValue={handleCroppedImage}
      />
    </>
  );
};
