import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useEventStore } from 'store';
import { useEvent } from 'useCases/events';
import {
  useCreateInterests,
  useInterests,
  useUpdateInterests,
} from 'useCases/interests';
import { useTranslate } from 'hooks';
import { PreviewInterests } from 'components/contexts';
import { Checkbox } from 'components/form';
import {
  ActionsButton,
  BreadCrumb,
  Icon,
  Paragraph,
  SubTitle,
  Tooltip,
} from 'components/structure';
import { MAX_INTERESTS_ARRAY_LENGTH } from 'constants/enums';
import { Form } from './Form/Form';
import * as S from './InterestsForm.styles';
import { schemaValidate, defaultValues } from './InterestsForm.validation';

export type InterestType = {
  id?: string;
  name: string;
  priority: number;
  code: number;
  fieldId?: string;
};

export type InterestsFormModel = {
  interests: InterestType[];
};

export type InterestError = {
  message: string;
};

export const InterestsForm = () => {
  const translate = useTranslate();
  const { eventId } = useEventStore();
  const { data: event, isLoading: isEventLoading } = useEvent(eventId);
  const { data: interestsData, isLoading } = useInterests(eventId);
  const { handleCreateInterests, isPending } = useCreateInterests();
  const { handleUpdateInterests, isUpdatePending } = useUpdateInterests();

  const [hasInterests, setHasInterests] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const { register, control, reset, watch, errors, handleSubmit } =
    useForm<InterestsFormModel>({
      defaultValues,
      resolver: yupResolver(schemaValidate),
    });
  const { fields, swap, remove, append } = useFieldArray<InterestType>({
    name: 'interests',
    control,
  });
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (interestsData?.length && hasInterests) {
      const interestsWithProps = interestsData.map(
        ({ name, priority, id }) => ({
          value: name,
          priority,
          code: priority,
          fieldId: id,
        }),
      );

      reset({
        interests: interestsWithProps,
      });
    }
  }, [reset, interestsData, hasInterests]);

  useEffect(() => {
    if (!hasInterests) return remove();
  }, [hasInterests, remove]);

  const interests = useMemo(() => {
    const interestsFields = watch('interests');

    const interestsWithProps = interestsFields.reduce(
      (items: InterestType[], item, index) => {
        const interest = { ...fields[index] };

        const interestWithProps = {
          ...fields[index],
          id: interest.fieldId,
          name: item.name,
          priority: index,
          code: index,
        };
        items.push(interestWithProps);

        return items;
      },
      [],
    );

    return interestsWithProps;
  }, [watch, fields]);

  const handleAppendItems = () => {
    append({
      name: '',
      priority: fields.length,
      code: fields.length,
    });
  };

  const handleSwapItems = (from: number, to: number) => {
    swap(from, to);
  };

  const onSubmit = handleSubmit(() => {
    if (interestsData?.length) {
      return handleUpdateInterests(interests, eventId);
    }
    handleCreateInterests(interests, eventId);
  });

  const handleChange = (checked: boolean) => {
    if (checked) {
      append({
        name: '',
        priority: fields.length,
        code: fields.length,
      });
    }
    setHasInterests((prevState) => !prevState);
  };

  // TODO add skeleton
  if (isLoading || isEventLoading) {
    return <span>Loading...</span>;
  }

  return (
    <S.Wrapper>
      <S.Aside hasInterests={hasInterests}>
        <S.Scrollable>
          <SubTitle>
            {translate('event.editEvent.menu.accreditation.root')}
          </SubTitle>
          <BreadCrumb
            screens={[
              event?.name ?? '',
              translate('event.editEvent.menu.accreditation.root'),
              translate('event.editEvent.menu.accreditation.interests'),
            ]}
          />
          <S.Container>
            <Checkbox
              name="hasInterests"
              placeholder={translate(
                'event.editEvent.interestsForm.hasInterests',
              )}
              label={translate('event.editEvent.interestsForm.hasInterests')}
              checked={hasInterests}
              onChange={({ target: { checked } }) => handleChange(checked)}
            />
            <S.IconButton ref={buttonRef}>
              <Icon icon="IcHelpOutline" width="20px" height="20px" />
            </S.IconButton>
            <Tooltip anchor={buttonRef} position="bottom">
              <Paragraph>
                {translate('event.editEvent.interestsForm.helperMessage')}
              </Paragraph>
            </Tooltip>
          </S.Container>
          <Form
            register={register}
            remove={remove}
            fields={fields}
            interests={interests}
            setHasErrors={setHasErrors}
            submittedErrors={errors}
            eventId={eventId}
          />
          {fields.length < MAX_INTERESTS_ARRAY_LENGTH && (
            <ActionsButton
              icon="IcAdd"
              isAbsolute
              onClick={handleAppendItems}
            />
          )}
        </S.Scrollable>
      </S.Aside>
      <PreviewInterests
        interests={interests}
        hasInterests={hasInterests}
        handleSwapItems={handleSwapItems}
        hasErrors={hasErrors}
        onSubmit={onSubmit}
        isPending={isPending || isUpdatePending}
      />
    </S.Wrapper>
  );
};
