import { useState } from 'react';
import { mutate } from 'swr';
import { useAPI, useFetch } from 'hooks';
import { InterestType } from 'components/contexts';
import { INTERESTS } from 'constants/endpoints';

export const useInterests = (eventId: string) =>
  useFetch<InterestType[]>([INTERESTS.ROOT, eventId], {
    headers: {
      'event-id': eventId,
    },
  });

export const useCreateInterests = () => {
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);

  const handleCreateInterests = async (
    interests: InterestType[],
    eventId: string,
  ) => {
    setIsPending(true);
    try {
      const { data } = await api.post(
        INTERESTS.ROOT,
        { interests },
        {
          headers: {
            'event-id': eventId,
          },
        },
      );
      mutate([INTERESTS.ROOT, eventId], data);
    } finally {
      setIsPending(false);
    }
  };

  return { handleCreateInterests, isPending };
};

export const useUpdateInterests = () => {
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);

  const handleUpdateInterests = async (
    interests: InterestType[],
    eventId: string,
  ) => {
    setIsPending(true);
    try {
      const { data } = await api.put(
        INTERESTS.ROOT,
        { interests },
        {
          headers: {
            'event-id': eventId,
          },
        },
      );
      mutate([INTERESTS.ROOT, eventId], data);
    } finally {
      setIsPending(false);
    }
  };

  return { handleUpdateInterests, isUpdatePending: isPending };
};

export const useDeleteInterests = () => {
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);

  const handleDeleteInterest = async (id: string, eventId: string) => {
    setIsPending(true);
    try {
      await api.delete(INTERESTS.BY_ID(id), {
        headers: {
          'event-id': eventId,
        },
      });
    } finally {
      setIsPending(false);
    }
  };

  return { handleDeleteInterest, isDeletePending: isPending };
};
