import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslate } from 'hooks';
import messages from 'lib/core/errors';
import { useEmailFormContext, useToast } from 'contexts';
import { FieldArray, TextArea, TextField } from 'components/form';
import { FileInput } from 'components/form/FileInput/FileInput';
import { Form, IconsKeys, Paragraph } from 'components/structure';
import { EmailFormInputsType, EmailFormFilled } from 'interfaces/email';
import { SocialNetwork } from 'interfaces/social-network';
import * as S from './EmailSetupForm.styles';
import {
  schema,
  socialNetworkRegex,
  isValidImageSizes,
} from './EmailSetupForm.validations';

const { invalidParam } = messages;

const getSocialNetworksByDomains = (
  translate: (id: string) => string,
): {
  [x: string]: { name: string; icon: IconsKeys };
} => ({
  ['linkedin.com']: { name: 'Linkedin', icon: 'IcLinkedin' },
  ['instagram.com']: { name: 'Instagram', icon: 'IcInstagram' },
  ['twitter.com']: { name: 'Twitter', icon: 'IcTwitter' },
  ['youtube.com']: { name: 'Youtube', icon: 'IcYoutube' },
  ['facebook.com']: { name: 'Facebook', icon: 'IcFacebook' },
  notSupported: {
    name: translate('event.editEvent.emailForm.inputs.socialNetworksLabel'),
    icon: 'IcIco',
  },
});

export type EmailSetupFormProps = {
  id: string;
  onSubmit?: (data: EmailFormFilled) => void;
  onChange?: (
    filledData: EmailFormFilled,
    inputsData: EmailFormInputsType,
  ) => void;
  defaultValues?: EmailFormInputsType;
};

type FormFiles = {
  headerImage?: File;
};

const files: FormFiles = {};
const selectedSocialNetworks: Array<{
  url: string;
  name: string;
  icon: IconsKeys;
}> = [];

export const EmailSetupForm = ({
  id,
  onSubmit: onSubmitForm,
  onChange: onChangeForm,
  defaultValues,
}: EmailSetupFormProps) => {
  const translate = useTranslate();
  const { setToast } = useToast();
  const { setPreventLeave } = useEmailFormContext();

  const {
    control,
    register,
    errors,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm<EmailFormInputsType>({
    defaultValues: defaultValues || {
      headerImage: '',
      socialNetworks: [{ value: '' }],
    },
    resolver: yupResolver(schema),
  });
  const socialNetworks = watch('socialNetworks');

  const fillData = (data: EmailFormInputsType): EmailFormFilled => {
    const filledSocialNetworks: Array<SocialNetwork> =
      selectedSocialNetworks.map((network, index) => {
        detectSocialNetwork(network.url, index);
        return {
          url: network.url,
          icon: network.icon,
        };
      });
    return {
      ...data,
      ...files,
      socialNetworks: filledSocialNetworks,
    } as EmailFormFilled;
  };

  const onSubmit = async (data: EmailFormInputsType) => {
    for (const [key, file] of Object.entries(files)) {
      const isValid = await isValidImageSizes(file as File, {
        width: 300,
        height: 200,
      });
      if (!isValid) {
        setError(key, {
          type: 'manual',
          message: invalidParam('imageDimensions'),
        });
        return;
      }
    }

    const dataWithFiles = fillData(data);
    onSubmitForm?.(dataWithFiles);
  };

  const onChange = () => {
    const inputsData = getValues();
    const filledData = fillData(inputsData);
    onChangeForm?.(filledData, inputsData);
  };

  const handleFile = (key: 'headerImage') => async (file: File) => {
    files[key] = file;
    clearErrors('headerImage');
    const isValid = await isValidImageSizes(file, {
      width: 300,
      height: 200,
    });
    if (!isValid) {
      setError('headerImage', {
        type: 'manual',
        message: invalidParam('imageDimensions'),
      });
    }
  };

  const detectSocialNetwork = (value: string, index: number) => {
    const socialNetworksByDomains = getSocialNetworksByDomains(translate);
    const domain = value.match(socialNetworkRegex)?.[3] || 'notSupported';
    selectedSocialNetworks[index] = {
      ...(socialNetworksByDomains[domain] ||
        socialNetworksByDomains.notSupported),
      url: value,
    };
  };

  const handleRemoveSocialNetwork = (index: number) => {
    selectedSocialNetworks.splice(index, 1);
    onChange();
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      setToast({
        title: translate('errors.errorTitle'),
        description: translate('errors.checkFields'),
        type: 'danger',
      });
    }
  }, [errors, setToast, translate]);

  useEffect(() => {
    if (isDirty) {
      setPreventLeave(true);
    }
  }, [isDirty, setPreventLeave]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setPreventLeave(false);
    }
  }, [isSubmitSuccessful, setPreventLeave]);

  useEffect(() => {
    if (!isDirty) {
      reset(defaultValues || {});
    }
  }, [defaultValues, isDirty, reset]);

  useEffect(() => {
    delete files.headerImage;
  }, [id]);

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmit)} onChange={onChange}>
      <S.Wrapper>
        <Controller
          name="headerImage"
          control={control}
          render={({ value, onChange }) => (
            <div>
              <Paragraph>Header</Paragraph>
              <FileInput
                icon="IcGallery"
                emptyText={translate(
                  'event.editEvent.emailForm.inputs.emptyImage',
                )}
                label={translate(
                  'event.editEvent.emailForm.inputs.imageUpload',
                )}
                fileType="image/*"
                helperText={`${translate(errors.headerImage?.message)}
                  ${translate('event.editEvent.emailForm.inputs.imageSize')}`}
                value={value}
                onChange={onChange}
                onChangeFile={handleFile('headerImage')}
                error={!!errors.headerImage}
              />
            </div>
          )}
        />
        <TextField
          name="subject"
          ref={register}
          label={translate('event.editEvent.emailForm.inputs.subject')}
          placeholder={translate('event.editEvent.emailForm.inputs.subject')}
          error={!!errors.subject?.message}
          helperText={translate(errors.subject?.message)}
        />
        <TextField
          name="title"
          ref={register}
          label={translate('event.editEvent.emailForm.inputs.title')}
          placeholder={translate('event.editEvent.emailForm.inputs.title')}
          error={!!errors.title?.message}
          helperText={translate(errors.title?.message)}
        />
        <TextArea
          name="paragraph"
          ref={register}
          label={translate('event.editEvent.emailForm.inputs.paragraph')}
          placeholder={translate('event.editEvent.emailForm.inputs.paragraph')}
          error={!!errors.paragraph?.message}
          helperText={translate(errors.paragraph?.message)}
        />
        <TextField
          name="button"
          ref={register}
          label={translate('event.editEvent.emailForm.inputs.button')}
          placeholder={translate('event.editEvent.emailForm.inputs.button')}
          error={!!errors.button?.message}
          helperText={translate(errors.button?.message)}
        />
        <Paragraph margin="0 0 -15px 0">
          {translate('event.editEvent.emailForm.inputs.socialNetworksTitle')}
        </Paragraph>
        <FieldArray
          control={control}
          name="socialNetworks"
          labelAdd={translate(
            'event.editEvent.emailForm.inputs.addSocialNetwork',
          )}
          watch={socialNetworks}
          register={register}
          labels={selectedSocialNetworks.map(({ name }) => name)}
          onChange={detectSocialNetwork}
          onRemove={handleRemoveSocialNetwork}
          errors={errors.socialNetworks}
          errorParser={translate}
          input={
            <TextField
              placeholder={translate(
                'event.editEvent.emailForm.inputs.socialNetworksLabel',
              )}
              mask="url"
              autoComplete="off"
            />
          }
        />
        <TextArea
          name="footer"
          ref={register}
          label={translate('event.editEvent.emailForm.inputs.footer')}
          placeholder={translate('event.editEvent.emailForm.inputs.footer')}
          error={!!errors.footer?.message}
          helperText={translate(errors.footer?.message)}
        />
      </S.Wrapper>
    </Form>
  );
};
