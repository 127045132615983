import { EventStatus } from 'useCases/events';

export const base = process.env.REACT_APP_API_BASE_PATH;

const version = '';
const authPrefix = 'auth';
const usersPrefix = 'users';
const organizationsPrefix = 'organizations';
const eventsPrefix = 'events';

export const baseURL = `${base}/${version}/`;

export const AUTH = {
  SIGN_IN: `${base}/${authPrefix}/sessions`,
  FORGOT_PASSWORD: `${base}/${authPrefix}/forgot-password`,
  REFRESH_TOKEN: `${base}/${authPrefix}/refresh-token`,
  LOGOUT: `${base}/${authPrefix}/logout`,
  UPDATE_PASSWORD: `${base}/${authPrefix}/reset-password`,
  CONFIRM_REGISTRATION: `${base}/${authPrefix}/2fa`,
};

export const USERS = {
  ROOT: `${base}/users`,
  BY_ID: (id: string) => `${base}/${usersPrefix}/${id}`,
};

export const TERMS = {
  ROOT: `${base}/terms`,
  BY_ID: (id: string) => `${base}/terms/${id}`,
  BY_EVENT: () => `${TERMS.ROOT}/events`,
};

export const ORGANIZATIONS = {
  ROOT: `${base}/${organizationsPrefix}`,
  BY_ID: (id: string) => `${base}/${organizationsPrefix}/${id}`,
};

export const EVENTS = {
  ROOT: `${base}/events`,
  SEARCH: (search?: string, statuses?: EventStatus[]) => {
    const urlParams = new URLSearchParams();
    if (!!search && search.length > 0) {
      urlParams.append('search', search);
    }
    if (!!statuses && statuses.length > 0) {
      urlParams.append('status', statuses.join(','));
    }
    const params = urlParams.toString();

    return `${base}/events${params.length == 0 ? '' : `?${params}`}`;
  },
  BY_ID: (id: string) => `${base}/${eventsPrefix}/${id}`,
};

export const INTERESTS = {
  ROOT: `${base}/interests`,
  BY_ID: (id: string) => `${base}/interests/${id}`,
};

export const EMAILS = {
  ROOT: `${base}/emails`,
  BY_ID: (id: string) => `${base}/emails/${id}`,
  LOGO: (id: string) => `${EMAILS.BY_ID(id)}/logos`,
  DISPATCH_TEST: `${base}/emails/dispatch`,
  COPY: (id: string) => `${base}/emails/${id}`,
};
