import { useEffect, useState } from 'react';
import { validatePassword } from 'lib/contexts/input';

const MAX_PASSWORD_STRENGH = 5;
export const usePasswordStrength = (password: string) => {
  const [validations, setValidations] = useState([]);
  useEffect(() => {
    validatePassword(password)
      .then(() => setValidations([]))
      .catch(({ errors }) => setValidations(errors));
  }, [password]);

  const strength = MAX_PASSWORD_STRENGH - validations.length;

  return {
    strength,
  };
};
