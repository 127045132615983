import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const eventPT = {
  event: pt,
};

export const eventEN = {
  event: en,
};

export const eventES = {
  event: es,
};
