import { useTranslate } from 'hooks';
import { useNewEventContext } from 'contexts';
import * as S from './ProgressBar.styles';

export const ProgressBar = () => {
  const translate = useTranslate();
  const { formProgress } = useNewEventContext();

  const steps = [
    {
      name: translate('event.newEvent.event'),
      step: 1,
      hasProgressRight: true,
    },
    {
      name: translate('event.newEvent.schedule'),
      step: 2,
    },
    {
      name: translate('event.newEvent.type'),
      step: 3,
      hasProgressLeft: true,
    },
  ];

  return (
    <S.Wrapper>
      {steps.map(({ name, step, hasProgressLeft, hasProgressRight }, index) => {
        const currentStepOnList = index + 1;

        return (
          <S.StepBox
            key={name}
            current={formProgress === currentStepOnList}
            active={formProgress >= currentStepOnList}
          >
            {name}
            <S.StepName
              hasProgressLeft={hasProgressLeft}
              hasProgressRight={hasProgressRight}
            >
              <span>{step}</span>
            </S.StepName>
          </S.StepBox>
        );
      })}
    </S.Wrapper>
  );
};
