import { Term } from 'useCases/terms';
import { usePortal, useTheme } from 'hooks';
import { generateDocumentPreviewUrl } from 'lib/contexts';
import { Heading, Icon } from 'components/structure';
import * as S from './Terms.styles';

export type TermsProps = {
  show: boolean;
  onConfirm: () => void;
  term?: Term;
};

export const Terms = ({
  show = false,
  term = {} as Term,
  onConfirm,
}: TermsProps) => {
  const { Portal } = usePortal();
  const { colors } = useTheme();
  return (
    <Portal>
      <S.Wrapper show={show}>
        <S.Content>
          <Heading dark>{term.name}</Heading>
          <S.Document
            data={term.termsUrl && generateDocumentPreviewUrl(term.termsUrl)}
            type="text/html"
          />
        </S.Content>
        <S.IconWrapper onClick={onConfirm}>
          <Icon
            width="14px"
            height="14px"
            icon="IcClose"
            color={colors.neutral.white}
          />
        </S.IconWrapper>
      </S.Wrapper>
    </Portal>
  );
};
