import { Link, useNavigate } from 'react-router-dom';
import { useOrganizationStore } from 'store';
import { useSettingsContext } from 'contexts';
import { Icon } from 'components/structure';
import { Organization } from 'interfaces/organization';
import { ROUTES } from 'constants/urls';
import * as S from './Sidebar.styles';

export type SidebarProps = {
  options: Organization[];
  selected?: string;
  onSelect: (org: string) => void;
};

export const Sidebar = ({ options, selected, onSelect }: SidebarProps) => {
  const navigate = useNavigate();
  const { preventDefault } = useSettingsContext();
  const removeOrganizationId = useOrganizationStore(
    (state) => state.removeOrganizationId,
  );

  return (
    <S.Wrapper>
      <Link
        to={ROUTES.organizations.getLink('root')}
        onClick={(ev) => {
          ev.preventDefault();
          preventDefault(() => {
            navigate(ROUTES.organizations.getLink('root'));
          })();
        }}
      >
        <S.IconWrapper>
          <Icon icon="IcBigLogo" height="50px" width="33px" />
        </S.IconWrapper>
      </Link>
      <S.Divider />
      <S.MenuGroup>
        <S.MenuNav>
          {options.map(({ id, name, logoUrl }) => (
            <S.MenuLink
              key={id}
              active={selected === id}
              to={ROUTES.app.getLink('events')}
              onClick={(ev) => {
                ev.preventDefault();
                preventDefault(() => {
                  onSelect(id);
                  navigate(ROUTES.app.getLink('events'));
                })();
              }}
            >
              <img src={logoUrl} alt={name} />
            </S.MenuLink>
          ))}
        </S.MenuNav>
        <S.PlusSection>
          <Link
            onClick={(ev) => {
              ev.preventDefault();
              preventDefault(() => {
                removeOrganizationId();
                navigate(ROUTES.organizations.getLink('create'));
              })();
            }}
            to={ROUTES.organizations.getLink('create')}
          >
            <S.IconWrapper>
              <Icon icon="IcPlus" />
            </S.IconWrapper>
          </Link>
        </S.PlusSection>
      </S.MenuGroup>
    </S.Wrapper>
  );
};
