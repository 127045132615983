import { useState } from 'react';
import { mutate } from 'swr';
import { useAPI, useFetch } from 'hooks';
import { EVENTS } from 'constants/endpoints';
import { AccessTypeEvent } from 'constants/enums';

export enum EventStatus {
  ONGOING = 'ongoing',
  FUTURE = 'future',
  FINISHED = 'finished',
}

export type Event = {
  id: string;
  name: string;
  description: string;
  accreditationStartedAt: string;
  accreditationEndedAt: string;
  startedAt: string;
  endedAt: string;
  features: string[];
  isClosed: boolean;
  accessType: AccessTypeEvent;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};

export type useEventParams = {
  organizationId?: string;
  search?: string;
  statuses?: EventStatus[];
};

const getHeaders = (organizationId: string) => ({
  headers: {
    'organization-id': organizationId,
  },
});

export const useEvents = ({
  organizationId,
  search,
  statuses,
}: useEventParams) => {
  const url = EVENTS.SEARCH(search, statuses);
  return useFetch<Event[]>([url, organizationId], getHeaders(organizationId!));
};

export const useEvent = (id: string) => useFetch<Event>(EVENTS.BY_ID(id));

export const useDeleteEvent = () => {
  const [isPending, setIsPending] = useState(false);
  const api = useAPI();

  const deleteEvent = async ({
    eventId,
    organizationId,
  }: {
    eventId: string;
    organizationId: string;
  }) => {
    setIsPending(true);

    try {
      await api.delete(EVENTS.BY_ID(eventId), getHeaders(organizationId));
      mutate(
        [EVENTS.ROOT, organizationId],
        async (events: Event[]) => events.filter(({ id }) => id !== eventId),
        false,
      );
    } catch {
      setIsPending(false);
    }
  };

  return { deleteEvent, isPending };
};
