import { PropsWithChildren } from 'react';
import { Button, Icon } from 'components/structure';
import * as S from './Modal.styles';

export type ModalProps = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  icon?: JSX.Element;
  content?: JSX.Element;
  noBackground?: boolean;
  noIconContainer?: boolean;
  title: string;
  description?: JSX.Element | string;
  name?: string;
  buttonLabel?: string;
  hasButton?: boolean;
  isShort?: boolean;
  isStrong?: boolean;
  onClose?: () => void;
  customButtons?: JSX.Element;
};

export const Modal = ({
  showModal,
  setShowModal,
  icon,
  title,
  description,
  buttonLabel,
  noBackground,
  noIconContainer,
  name,
  hasButton = true,
  isShort,
  isStrong,
  onClose,
  children,
  content,
  customButtons: CustomButtons,
}: PropsWithChildren<ModalProps>) => {
  function setStrongOnFirstWord(description: string) {
    const firstWord = description.split(' ')[0];
    const formattedDescription = description.replace(firstWord, '');

    return (
      <>
        <strong>{firstWord}</strong>
        {formattedDescription}
      </>
    );
  }

  const handleOnClose = () => {
    setShowModal(false);
    onClose && onClose();
  };

  return (
    <S.Wrapper aria-label="wrapper" showModal={showModal}>
      <S.Container noIconContainer={noIconContainer} isShort={isShort}>
        <S.Header>
          <S.IconWrapper onClick={handleOnClose}>
            <Icon icon="IcClose" aria-label={buttonLabel} />
          </S.IconWrapper>
        </S.Header>

        <S.Content
          noBackground={noBackground}
          noIconContainer={noIconContainer}
        >
          {!noIconContainer && <S.IconContainer>{icon}</S.IconContainer>}
          <S.Title>{name ? `${title} ${name}!` : title}</S.Title>

          <S.Description>
            {typeof description === 'string' && isStrong
              ? setStrongOnFirstWord(description)
              : description}
          </S.Description>

          {hasButton && (
            <S.ButtonContainer>
              {CustomButtons ? (
                CustomButtons
              ) : (
                <Button onClick={handleOnClose} fullWidthMobile>
                  {buttonLabel}
                </Button>
              )}
            </S.ButtonContainer>
          )}

          {showModal && (content ?? children)}
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};
