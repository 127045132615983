import { MutableRefObject } from 'react';
import { usePopover } from 'hooks/usePopover';
import { WithChildren } from 'interfaces/children';
import * as S from './Tooltip.styles';

export type TooltipProps = {
  anchor: MutableRefObject<HTMLElement | null>;
  position: 'bottom';
} & WithChildren;

export const Tooltip = ({ anchor, children, position }: TooltipProps) => {
  const { Portal } = usePopover<HTMLElement>({ anchor, position });

  return (
    <Portal>
      <S.Tooltip position={position}>{children}</S.Tooltip>
    </Portal>
  );
};
