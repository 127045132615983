import { navBarPT, navBarEN, navBarES } from './navbar';
import { sideBarEN, sideBarES, sideBarPT } from './sidebar';

export const structurePT = {
  ...navBarPT,
  ...sideBarPT,
};

export const structureEN = {
  ...navBarEN,
  ...sideBarEN,
};

export const structureES = {
  ...navBarES,
  ...sideBarES,
};
