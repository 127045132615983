import { useParams } from 'react-router-dom';
import { useTranslate } from 'hooks';
import { Paragraph, SubTitle } from 'components/structure';
import { Tabs } from 'components/structure/Tabs/Tabs';
import { ROUTES } from 'constants/urls';
import { PdfViewer } from './PdfViewer/PdfViewer';
import * as S from './PreviewDocuments.styles';

export type Document = {
  key: string;
  name: string;
  file: File | string;
};

export type PreviewDocumentsProps = {
  documents?: Document[];
};

export const PreviewDocuments = ({ documents }: PreviewDocumentsProps) => {
  const { preview = 'terms' } = useParams();
  const translate = useTranslate();

  if (!documents || !documents.length) {
    return (
      <S.EmptyWrapper>
        <SubTitle>
          {translate('event.editEvent.termsForm.terms.empty.title')}
        </SubTitle>
        <Paragraph>
          {translate('event.editEvent.termsForm.terms.empty.subtitle')}
        </Paragraph>
      </S.EmptyWrapper>
    );
  }

  const file = documents?.find((term) => term.key === preview)?.file;

  return (
    <S.Wrapper>
      <Tabs
        variant="secondary"
        tabs={
          documents?.map((document) => ({
            title: document.name,
            url: ROUTES.editEvent.getLink('terms', document.key, 'preview'),
          })) || []
        }
      />
      {file && <PdfViewer file={file} />}
    </S.Wrapper>
  );
};
