import { ROUTES } from 'constants/urls';

export type Link = keyof typeof ROUTES.editEvent.children;

type MenuOption = {
  label: string;
  link?: Link | string;
};

type MenuOptionsType = MenuOption & {
  subItems?: MenuOption[];
};

export const menuOptionsMock: MenuOptionsType[] = [
  { label: 'event.editEvent.menu.general' },
  {
    label: 'event.editEvent.menu.customization.root',
    subItems: [
      {
        label: 'event.editEvent.menu.customization.theme',
        link: 'customization.theme',
      },
      {
        label: 'event.editEvent.menu.customization.images',
        link: 'customization.images',
      },
    ],
  },
  { label: 'event.editEvent.menu.preparation' },
  {
    label: 'event.editEvent.menu.accreditation.root',
    subItems: [
      { label: 'event.editEvent.menu.accreditation.visitCard' },
      { label: 'event.editEvent.menu.accreditation.terms', link: 'terms' },
      {
        label: 'event.editEvent.menu.accreditation.interests',
        link: 'interests',
      },
      { label: 'event.editEvent.menu.accreditation.email', link: 'emails' },
    ],
  },
  { label: 'event.editEvent.menu.interactions' },
  { label: 'event.editEvent.menu.schedules' },
  { label: 'event.editEvent.menu.participants' },
];
