import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { useAPI, useFetch } from 'hooks';
import { getFormData } from 'lib/core/api';
import { NewOrganization } from 'interfaces/organization';
import { ORGANIZATIONS } from 'constants/endpoints';
import { ROUTES } from 'constants/urls';

export type Organization = {
  id: string;
  name: string;
  description: string;
  logo: string;
  logoUrl: string;
};

export const useOrganizations = () =>
  useFetch<Organization[]>(ORGANIZATIONS.ROOT);

export const useOrganization = (organizationId: string) =>
  useFetch<Organization>(ORGANIZATIONS.BY_ID(organizationId));

export const useCreateOrganization = () => {
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const api = useAPI();

  const createOrganization = async (data: NewOrganization) => {
    setIsPending(true);

    try {
      const { data: organization } = await api.post(
        ORGANIZATIONS.ROOT,
        getFormData(data),
      );
      navigate(ROUTES.organizations.getLink('root'));
      mutate(
        ORGANIZATIONS.ROOT,
        (organizations: NewOrganization[]) => [...organizations, organization],
        false,
      );
    } catch {
      setIsPending(false);
    }
  };

  return { createOrganization, isPending };
};
