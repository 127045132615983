import create from 'zustand';
import { persist } from 'zustand/middleware';

type OrganizationStore = {
  organizationId: string;
  addOrganizationId: (value: string) => void;
  removeOrganizationId: () => void;
};

const ORGANIZATION_ID = 'organizationId';

export const useOrganizationStore = create<OrganizationStore>(
  persist(
    (set) => ({
      organizationId: '',
      addOrganizationId: (organizationId) => set({ organizationId }),
      removeOrganizationId: () => set({ organizationId: '' }),
    }),
    {
      name: ORGANIZATION_ID,
    },
  ),
);
