import styled, { css, DefaultTheme } from 'styled-components';
import { TextFieldProps } from './TextField';

type FieldProps = {
  hasError?: boolean;
  success?: boolean;
  newPassword?: boolean;
} & Pick<TextFieldProps, 'disabled'>;

const fieldModifiers = {
  disabled: (theme: DefaultTheme) => css`
    background: ${theme.colors.grayscale.dark.line};
    border: 1px solid ${theme.colors.grayscale.dark.line};
    color: ${theme.colors.grayscale.light.line};
    pointer-events: none;
  `,
  hasError: (theme: DefaultTheme) => css`
    &,
    &:active,
    &:focus {
      border-color: ${theme.colors.contextual.danger.main};
    }

    & + ${Label} {
      color: ${theme.colors.contextual.danger.main};
    }
  `,
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const InputWrapper = styled.div<FieldProps>`
  ${({ theme, disabled, hasError }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: ${theme.colors.background.dark.input};
    font-family: ${theme.typography.family.primary};
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid ${theme.colors.grayscale.dark.line};
    position: relative;
    width: 100%;
    text-align: left;
    height: 48px;
    min-height: 48px;
    outline: none;
    transition: all 0.2s linear;
    transition-property: color, border-color;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:active,
    &:focus-within {
      border-color: ${theme.colors.primary.main};

      & > ${Label} {
        color: ${theme.colors.primary.main};
      }
    }

    &:disabled {
      background-color: ${theme.colors.grayscale.dark.line};
      pointer-events: none;
    }

    ${hasError && fieldModifiers.hasError(theme)};
    ${disabled && fieldModifiers.disabled(theme)};
  `}
`;

export const Field = styled.input`
  ${({ theme }) => css`
    appearance: none;
    background: none;
    border: none;
    font-family: inherit;
    font-size: ${theme.typography.sizes.base};
    line-height: ${theme.typography.lineHeight.base};
    color: ${theme.colors.grayscale.dark.title};
    height: 48px;
    min-height: 48px;
    overflow: hidden;
    outline: none;
    padding: 10px 10px 0 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    &::placeholder {
      font-family: inherit;
      color: ${theme.colors.grayscale.dark.info};
    }

    &:placeholder-shown {
      padding: ${theme.spacing.sm} 20px;

      & + ${Label} {
        top: 58%;
        opacity: 0;
        visibility: hidden;
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.primary.main};
    font-size: 10px;
    font-family: inherit;
    transition: all 0.2s linear;
    position: absolute;
    left: 21px;
    top: 10px;
    transform: translateY(-50%);
  `}
`;
export const Icon = styled.div`
  display: flex;
  position: relative;
  white-space: nowrap;
  height: 100%;
`;

export const IconContainer = styled(Icon)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.xs};
    user-select: none;
  `}
`;

export const PasswordStreng = styled.div<{ step: number }>`
  ${({ theme, step = 0 }) => css`
    background-color: rgba(0, 0, 0, 0.2);
    margin: 8px auto 0;
    height: 4px;
    width: calc(100% - 40px);
    color: ${theme.colors.contextual.success};
    position: relative;

    &::after {
      content: '';
      background-color: ${theme.colors.primary.main};
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      transition: width 0.2s linear;
      width: ${`${(step / 5) * 100}%` || '0%'};
    }
  `}
`;
