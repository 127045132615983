import styled, { css, DefaultTheme } from 'styled-components';
import { EventContentCardProps } from 'components/contexts/event/EventContentCard/EventContentCard';
import { Paragraph } from 'components/structure';

const wrapperModifiers = {
  selected: (theme: DefaultTheme) => css`
    box-shadow: 0px 8px 8px rgba(46, 229, 119, 0.1);
    border-color: ${theme.colors.primary.active};
  `,
};

export const Wrapper = styled.div<Pick<EventContentCardProps, 'selected'>>`
  ${({ theme, selected }) => css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background: radial-gradient(
      78.33% 78.33% at 12.98% 1.88%,
      #333131 0%,
      #292828 100%
    );
    border-radius: ${theme.shapes.borderRadius.sm};
    border: 1px solid ${theme.colors.grayscale.dark.line};
    width: 120px;
    height: 120px;

    ${selected && wrapperModifiers.selected(theme)}
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding-top: ${theme.spacing.xs};
    padding-right: ${theme.spacing.xs};
  `}
`;

export const Tooltip = styled.div`
  ${({ theme }) => css`
    top: -16px;
    left: 30px;
    display: flex;
    align-items: center;
    min-height: 51px;
    min-width: 128px;
    position: absolute;
    background ${theme.colors.neutral.black};
    border-radius: ${theme.shapes.borderRadius.xs};
    border: 1px solid ${theme.colors.grayscale.dark.line};
    transition: all 0.3s ease-in-out, opacity 0.3s 0.05s linear;
    pointer-events: none;
    ${Paragraph} {
      z-index: 2;
      width: 100%;
      height: 100%;
    }
     ::after {
      z-index: 1;
      content: '';
      width: 15px;
      height: 15px;
      background-color: ${theme.colors.neutral.black};
      border-left: 1px solid ${theme.colors.grayscale.dark.line};
      border-bottom: 1px solid ${theme.colors.grayscale.dark.line};
      position: absolute;
      left:-8px;
      transform: rotate(45deg);
    }
  `}
`;

export const IconButton = styled.button`
  position: relative;
  ${Tooltip} {
    opacity: 0;
  }
  svg {
    &:hover {
      & + ${Tooltip} {
        opacity: 1;
      }
    }
  }
`;
export const Body = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    ${Paragraph} {
      font-size: ${theme.typography.sizes.sm};
      margin-top: ${theme.spacing.xs};
      padding: 0px 12px;
    }
  `}
`;
