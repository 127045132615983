import { MutableRefObject, useEffect } from 'react';

type useHoverProps<T> = {
  ref: MutableRefObject<T | null>;
  onMouseOver?: (ev: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
};

export const useHover = <T extends HTMLElement>({
  ref,
  onMouseOver,
  onMouseLeave,
}: useHoverProps<T>) => {
  useEffect(() => {
    const current = ref.current;

    onMouseOver && current?.addEventListener('mouseover', onMouseOver);
    onMouseLeave && current?.addEventListener('mouseleave', onMouseLeave);

    return () => {
      onMouseOver && current?.removeEventListener('mouseover', onMouseOver);
      onMouseLeave && current?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [onMouseLeave, onMouseOver, ref]);
};
