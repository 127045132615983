import { forwardRef, TextareaHTMLAttributes } from 'react';
import { HelperText } from 'components/form';
import * as S from './TextArea.styles';

export type TextAreaProps = {
  label?: string;
  error?: boolean;
  helperText?: string;
  showAlert?: boolean;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ error, label, name, helperText, ...props }, ref) => (
    <S.Container>
      <S.InputWrapper hasError={error} disabled={props.disabled}>
        <S.Field ref={ref} id={name} name={name} {...props} />
        {!!label && <S.Label htmlFor={name}>{label}</S.Label>}
      </S.InputWrapper>
      {helperText && <HelperText error={error}>{helperText}</HelperText>}
    </S.Container>
  ),
);
