import { ChangeEvent, forwardRef, useState } from 'react';
import { useTheme } from 'hooks';
import { HelperText, TextField, TextFieldProps } from 'components/form';
import { Icon } from 'components/structure';
import * as S from './PasswordField.styles';
import { usePasswordStrength } from './usePasswordStrength';

export type PasswordFieldProps = {
  showStrength?: boolean;
} & Omit<TextFieldProps, 'icon' | 'type'>;

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({ showStrength = false, error, helperText, ...props }, ref) => {
    const theme = useTheme();
    const [toggle, setToggle] = useState(false);
    const [value, setValue] = useState<string>((props.value as string) ?? '');
    const { strength } = usePasswordStrength(value);

    const IconToggle = () => {
      const props = {
        height: '24px',
        width: '24px',
        color: theme.colors.grayscale.dark.info,
      };
      return toggle ? (
        <Icon {...props} icon="IcEyeShow" />
      ) : (
        <Icon {...props} icon="IcEyeHide" />
      );
    };

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(ev);
      }
      setValue(ev.target.value);
    };

    return (
      <S.Container>
        <TextField
          {...props}
          error={error}
          type={toggle ? 'text' : 'password'}
          ref={ref}
          onChange={handleChange}
          showAlert={false}
          icon={
            <S.IconContainer onClick={() => setToggle(!toggle)}>
              <IconToggle />
            </S.IconContainer>
          }
        />
        {showStrength && <S.PasswordStrength strength={strength} />}
        {helperText && <HelperText error={error}>{helperText}</HelperText>}
      </S.Container>
    );
  },
);
