import styled, { css, DefaultTheme } from 'styled-components';
import { InterestProps } from './Interest';

type ContentProps = Pick<InterestProps, 'selected'>;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;

    > svg {
      margin-right: ${theme.spacing['2xs']};
      width: 10px;
      height: 16px;
    }
  `}
`;

const contentModifiers = {
  selected: (theme: DefaultTheme) => css`
    background: ${theme.colors.primary.main};
    color: ${theme.colors.grayscale.light.paragraph};
  `,
};

export const Content = styled.div<ContentProps>`
  ${({ theme, selected }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.grayscale.dark.line};
    color: ${theme.colors.grayscale.dark.paragraph};
    border-radius: ${theme.shapes.borderRadius.lg};
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    min-width: 100px;
    min-height: 40px;

    ${selected && contentModifiers.selected(theme)}
  `}
`;
