import { ImagesForm } from 'components/contexts/event/EditEvent/Customization/ImagesForm/ImagesForm';
import { SectionLayout } from 'components/contexts/event/EditEvent/SectionLayout/SectionLayout';

export const Images = () => (
  <SectionLayout
    breadcrumb={[
      'event.editEvent.menu.customization.root',
      'event.editEvent.menu.customization.images',
    ]}
    subTitle="event.editEvent.menu.customization.images"
  >
    <ImagesForm />
  </SectionLayout>
);
