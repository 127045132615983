import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const EditAreaWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm};
    position: relative;
    width: 100%;
    max-width: 458px;
  `}
`;

export const PreviewAreaWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm};
    background-color: ${theme.colors.neutral.filter};
    ${media.greaterThan('large')`
      margin-top: -171px;
    `}
    & > * {
      max-height: 100vh;
    }
  `}
`;

export const ActionButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.sm};
    ${media.greaterThan('large')`
      position: fixed;
      right: ${theme.spacing.sm};
      bottom: ${theme.spacing.sm};
    `};
  `}
`;
