import styled, { css, DefaultTheme } from 'styled-components';

type StepBoxProps = {
  current: boolean;
  active: boolean;
};

type StepNameProps = {
  hasProgressLeft?: boolean;
  hasProgressRight?: boolean;
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    aligin-items: center;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    margin: ${theme.spacing.sm} 0 ${theme.spacing.xl};
  `}
`;

const stepBoxModifiers = {
  active: (theme: DefaultTheme) => css`
    color: ${theme.colors.primary.inative};

    ${StepName} {
      background: ${theme.colors.primary.inative};
      color: ${theme.colors.grayscale.light.paragraph};

      > span {
        opacity: 1;
      }

      &::after,
      &::before {
        background: linear-gradient(
          90deg,
          ${theme.colors.primary.inative} 0.31%,
          ${theme.colors.primary.main} 100%
        );
      }
    }
  `,
  current: (theme: DefaultTheme) => css`
    color: ${theme.colors.primary.main};

    ${StepName} {
      background: ${theme.colors.primary.main};
      &::after {
        background: linear-gradient(
          90deg,
          ${theme.colors.primary.main} 0%,
          #0a7434 25.83%,
          #154327 50.31%,
          ${theme.colors.background.outlet.public} 98.43%
        );
      }
    }
  `,
};

export const StepBox = styled.div<StepBoxProps>`
  ${({ theme, active, current }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${theme.colors.grayscale.dark.info};
    width: 120px;
    font-size: ${theme.typography.sizes.sm};
    position: relative;

    ${active && stepBoxModifiers.active(theme)}
    ${current && stepBoxModifiers.current(theme)}
  `}
`;

const stepNameModifiers = {
  left: (theme: DefaultTheme) => css`
    &::before {
      opacity: 1;
      background: ${theme.colors.background.outlet.public};
      right: 100%;
    }
  `,
  right: (theme: DefaultTheme) => css`
    &::after {
      opacity: 1;
      background: ${theme.colors.background.outlet.public};
      left: 100%;
    }
  `,
};

export const StepName = styled.div<StepNameProps>`
  ${({ theme, hasProgressLeft, hasProgressRight }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.background.outlet.public};
    border-radius: ${theme.shapes.borderRadius.xs};
    color: ${theme.colors.neutral.white};
    transition: all 0.2s ease-in-out;
    margin-top: ${theme.spacing.xs};
    position: relative;
    height: 30px;
    width: 30px;

    &::after,
    &::before {
      opacity: 0;
      content: '';
      position: absolute;
      height: 2px;
      bottom: 50%;
      width: 90px;
    }

    > span {
      opacity: 0.5;
    }

    ${hasProgressLeft && stepNameModifiers.left(theme)}
    ${hasProgressRight && stepNameModifiers.right(theme)}
  `}
`;
