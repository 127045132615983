import { format } from 'date-fns';
import { useState } from 'react';
import { EventStatus } from 'useCases/events';
import { useClickOutside, useTheme, useTranslate } from 'hooks';
import { Paragraph, Icon } from 'components/structure';
import * as S from './EventCard.styles';

export type EventCardProps = {
  name: string;
  status: EventStatus;
  startDate: string;
  isFree: boolean;
  onEdit: () => void;
  onDelete: () => void;
};

const preventDefault =
  (callback: () => void) =>
  (ev: React.MouseEvent<HTMLElement | SVGElement>) => {
    ev.stopPropagation();
    callback();
  };

export const EventCard = ({
  name,
  status,
  isFree,
  startDate,
  onEdit,
  onDelete,
}: EventCardProps) => {
  const theme = useTheme();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const ref = useClickOutside(() => setOpen(false));

  const date = format(new Date(startDate), 'PP');
  const time = format(new Date(startDate), 'p');

  return (
    <S.Wrapper onClick={onEdit}>
      <S.Status status={status} />
      <S.Content>
        <S.Title bold>{name}</S.Title>
        <S.Info>
          <Icon
            height={'18px'}
            width={'18px'}
            color={theme.colors.grayscale.dark.info}
            icon="IcCalendar"
          />
          <Paragraph center>{date}</Paragraph>
        </S.Info>
        <S.Info>
          <Icon
            height={'18px'}
            width={'18px'}
            color={theme.colors.grayscale.dark.info}
            icon="IcClock"
          />
          <Paragraph center>{time}</Paragraph>
        </S.Info>
      </S.Content>
      <S.Footer>
        <S.Chip>
          <Icon
            color={theme.colors.grayscale.dark.info}
            height={'11px'}
            width={'11px'}
            icon={isFree ? 'IcGift' : 'IcMoney'}
          />
          <Paragraph center>
            {isFree
              ? translate('event.eventList.free')
              : translate('event.eventList.purchased')}
          </Paragraph>
        </S.Chip>
        <S.IconButton>
          <Icon
            onClick={preventDefault(() => setOpen(!open))}
            height={'24px'}
            width={'32px'}
            icon="IcMore"
            color={theme.colors.grayscale.dark.info}
          />
          <S.Tooltip ref={ref} open={open}>
            <S.TooltipItem onClick={preventDefault(onEdit)} primary>
              <Icon
                height={'12px'}
                width={'12px'}
                color={theme.colors.primary.main}
                icon="IcEdit"
              />
              <Paragraph>{translate('labels.edit')}</Paragraph>
            </S.TooltipItem>
            <S.TooltipItem onClick={preventDefault(onDelete)}>
              <Icon
                height={'12px'}
                width={'12px'}
                color={theme.colors.neutral.white}
                icon="IcDelete"
              />
              <Paragraph>{translate('labels.delete')}</Paragraph>
            </S.TooltipItem>
          </S.Tooltip>
        </S.IconButton>
      </S.Footer>
    </S.Wrapper>
  );
};
