import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.md} 0;

    & > * {
      margin: 0 ${theme.spacing.xs};
    }
  `}
`;
