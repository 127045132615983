import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam } = messages;

const isValidHour = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

const defaultSchema = Yup.object().shape({
  start: Yup.object().shape({
    date: Yup.string().required(requiredParam('date')).nullable(),
    hour: Yup.string()
      .required(requiredParam('hour'))
      .matches(isValidHour, requiredParam('validHour')),
  }),
  end: Yup.object().shape({
    date: Yup.string().required(requiredParam('date')).nullable(),
    hour: Yup.string()
      .required(requiredParam('hour'))
      .matches(isValidHour, requiredParam('validHour')),
  }),
});

export const schemaValidate = Yup.object().shape({
  accreditation: defaultSchema,
  platform: defaultSchema,
  timezone: Yup.string().required(requiredParam('timeZone')),
});

const defaultValue = {
  start: {
    date: '',
    hour: '',
  },
  end: {
    date: '',
    hour: '',
  },
};

export const defaultValues = {
  accreditation: defaultValue,
  platform: defaultValue,
  timezone: '',
};
