import { yupResolver } from '@hookform/resolvers/yup';
import { RefObject } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useTranslate } from 'hooks';
import { useEnv } from 'hooks/useEnv';
import { TextField, PasswordField } from 'components/form';
import {
  Anchor,
  Form,
  FormActions,
  FormGroup,
  Paragraph,
  SubTitle,
} from 'components/structure';
import Button from 'components/structure/Button/Button';
import { SignInFormModel } from 'interfaces/auth';
import { ROUTES } from 'constants/urls';
import { defaultValues, schemaValidate } from './SignInFormValidation';

type SignInFormProps = {
  onSubmit: (data: SignInFormModel) => void;
  isPending: boolean;
  showRecaptcha?: boolean;
  recaptchaRef?: RefObject<ReCAPTCHA>;
};

export const SignInForm = ({
  onSubmit,
  isPending,
  recaptchaRef,
  showRecaptcha,
}: SignInFormProps) => {
  const translate = useTranslate();
  const theme = useTheme();
  const { googleRecaptcha } = useEnv();

  const { register, errors, handleSubmit } = useForm<SignInFormModel>({
    resolver: yupResolver(schemaValidate),
    defaultValues,
  });

  return (
    <>
      <Form fullWidth maxWidth="260px" onSubmit={handleSubmit(onSubmit)}>
        <SubTitle margin={`0 auto ${theme.spacing.lg}`}>
          {translate('auth.signIn.login')}
        </SubTitle>

        <FormGroup hasMargin={false}>
          <TextField
            name="email"
            ref={register}
            autoComplete="email"
            label={translate('labels.email')}
            placeholder={translate('labels.email')}
            error={!!errors.email?.message}
            helperText={translate(errors.email?.message)}
          />
          <PasswordField
            name="password"
            autoComplete="new-password"
            ref={register}
            label={translate('labels.password')}
            placeholder={translate('labels.password')}
            error={!!errors.password?.message}
            helperText={translate(errors.password?.message)}
            minLength={8}
          />
        </FormGroup>

        <Anchor
          as={Link}
          to={ROUTES.login.getLink('forgotPassword')}
          margin={`${theme.spacing['2xs']} 0 ${theme.spacing.lg}`}
        >
          {translate('auth.signIn.forgotPassword')}
        </Anchor>

        {showRecaptcha && (
          <ReCAPTCHA ref={recaptchaRef} sitekey={googleRecaptcha} />
        )}

        <FormActions justifyContent="center">
          <Button isLoading={isPending} disabled={isPending} type="submit">
            {translate('labels.login')}
          </Button>
        </FormActions>
      </Form>

      <Paragraph margin={`${theme.spacing['2xl']} 0 0`}>
        {translate('auth.signIn.dontHaveAccount')}
        <Anchor
          as={Link}
          to={ROUTES.accreditation.getLink('signUp')}
          margin={`0 0 0 ${theme.spacing['2xs']}`}
        >
          {translate('auth.signIn.signUpHere')}
        </Anchor>
      </Paragraph>
    </>
  );
};
