import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslate } from 'hooks';
import { setReadOnlyDefaultValue } from 'lib/contexts';
import { useNewEventContext } from 'contexts';
import { CustomSelect, TextField } from 'components/form';
import { Button, Divisor, Form, FormGroup } from 'components/structure';
import { URL_DEFAULT_VALUE } from 'constants/enums';
import { ROUTES } from 'constants/urls';
import { languages } from './EventInfoForm.mock';
import { schemaValidate, defaultValues } from './EventInfoForm.validation';

type EventInfoFormModel = {
  name: string;
  defaultLanguage: string;
  domain: string;
};

export const EventInfoForm = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { setFormProgress, setNewEventProps, newEventProps } =
    useNewEventContext();
  const urlInputRef = useRef<HTMLInputElement | null>(null);

  const { register, errors, handleSubmit, reset, control, getValues } =
    useForm<EventInfoFormModel>({
      resolver: yupResolver(schemaValidate),
      defaultValues,
    });

  useEffect(() => {
    if (!urlInputRef.current) return;

    const cleanUpEvents = setReadOnlyDefaultValue(urlInputRef.current);

    return () => cleanUpEvents();
  }, []);

  useEffect(() => {
    const { name, domain, defaultLanguage } = newEventProps;

    if (!name) return;

    reset({
      name,
      defaultLanguage,
      domain,
    });
  }, [reset, getValues, newEventProps]);

  const onSubmit = ({ name, domain, defaultLanguage }: EventInfoFormModel) => {
    setNewEventProps((prevState) => ({
      ...prevState,
      name,
      domain,
      defaultLanguage,
    }));

    setFormProgress((prevState) => prevState + 1);
    navigate(ROUTES.events.getLink('schedule'));
  };

  const translatedLangs = useMemo(
    () =>
      languages.map((lang) => ({
        ...lang,
        label: translate(lang.label),
      })),
    [translate],
  );

  return (
    <>
      <Form fullWidth maxWidth="460px" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup shortGap>
          <TextField
            ref={register}
            name="name"
            label={translate('event.newEvent.eventName')}
            placeholder={translate('event.newEvent.eventName')}
            autoComplete="off"
            error={!!errors.name?.message}
            helperText={translate(errors.name?.message)}
          />
          <CustomSelect
            control={control}
            name="defaultLanguage"
            label={translate('event.newEvent.language')}
            placeholder={translate('event.newEvent.language')}
            error={errors.defaultLanguage?.message}
            options={translatedLangs}
          />
          <TextField
            ref={(e) => {
              register(e);
              urlInputRef.current = e;
            }}
            name="domain"
            label={translate('event.newEvent.url')}
            placeholder={translate('event.newEvent.url')}
            autoComplete="off"
            error={!!errors.domain?.message}
            helperText={translate(errors.domain?.message)}
            defaultValue={URL_DEFAULT_VALUE}
          />
        </FormGroup>

        <Divisor center>
          <Button type="submit">{translate('labels.advance')}</Button>
        </Divisor>
      </Form>
    </>
  );
};
