import { createContext, useContext, useState, useRef, useEffect } from 'react';
import { WithChildren } from 'interfaces/children';

type ContextType = {
  isSpaceFull: boolean;
  setIsSpaceFull: (isOpen: boolean) => void;
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
  preventDefault: (callback: () => void) => () => void;
  setPreventDefaultInterceptor: (fn?: PreventDefaultCallback) => void;
  setPreventCloseTabMessage: (message?: string) => void;
};

export type PreventDefaultCallback = (callback: () => void) => void;

export const defaultValue: ContextType = {
  isSpaceFull: false,
  setIsSpaceFull: () => undefined,
  hasError: true,
  setHasError: () => undefined,
  preventDefault: () => () => undefined,
  setPreventDefaultInterceptor: () => undefined,
  setPreventCloseTabMessage: () => undefined,
};

export const SettingsContext = createContext<ContextType>(defaultValue);

export const SettingsProvider = ({ children }: WithChildren) => {
  const [isSpaceFull, setIsSpaceFull] = useState(defaultValue.isSpaceFull);
  const [hasError, setHasError] = useState(false);
  const [preventCloseTabMessage, setPreventCloseTabMessage] =
    useState<string | undefined>();
  const preventDefaultInterceptor = useRef<PreventDefaultCallback>();
  const setPreventDefaultInterceptor = (fn?: PreventDefaultCallback) => {
    preventDefaultInterceptor.current = fn;
  };

  const preventDefault = (callback: () => void) => () => {
    if (preventDefaultInterceptor.current) {
      preventDefaultInterceptor.current(callback);
    } else {
      callback();
    }
  };
  useEffect(() => {
    if (preventCloseTabMessage) {
      window.onbeforeunload = (ev: BeforeUnloadEvent) => {
        ev.preventDefault();
        return preventCloseTabMessage;
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [preventCloseTabMessage]);
  return (
    <SettingsContext.Provider
      value={{
        isSpaceFull,
        setIsSpaceFull,
        hasError,
        setHasError,
        preventDefault,
        setPreventDefaultInterceptor,
        setPreventCloseTabMessage,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => useContext(SettingsContext);
