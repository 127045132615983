import styled, { css } from 'styled-components';
import * as Button from 'components/structure/Button/Button.styles';

const wrapperModifiers = {
  hasInterests: () => css`
    opacity: 1;

    ${Button.Wrapper} {
      opacity: 1;
      visibility: visible;
    }
  `,
};

export const Wrapper = styled.div<{ hasInterests: boolean }>`
  ${({ theme, hasInterests }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    background: #0c0c0c;
    padding: ${theme.spacing.lg} ${theme.spacing.md};
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;

    ${Button.Wrapper} {
      align-self: flex-end;
      opacity: 0;
      visibility: hidden;
    }

    ${hasInterests && wrapperModifiers.hasInterests()}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: 100%:
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${theme.spacing.md};
  `}
`;
