import * as Yup from 'yup';
import { passwordStrength } from 'lib/contexts/input';
import messages from 'lib/core/errors';

const { requiredParam, paramsDontMatch, invalidParam } = messages;

export const schemaValidate = Yup.object().shape({
  firstName: Yup.string().required(requiredParam('firstName')),
  lastName: Yup.string().required(requiredParam('lastName')),
  email: Yup.string().email().required(requiredParam('email')),
  password: Yup.string()
    .min(8, invalidParam('password'))
    .required(requiredParam('password'))
    .matches(
      passwordStrength.hasNumber,
      messages.invalidParam('passwordHasNumber'),
    )
    .matches(
      passwordStrength.hasSymbol,
      messages.invalidParam('passwordHasSymbol'),
    )
    .matches(
      passwordStrength.hasCapitalLetter,
      messages.invalidParam('passwordHasCapitalLetter'),
    )
    .matches(
      passwordStrength.hasLowercaseLetter,
      messages.invalidParam('passwordHasLowercaseLetter'),
    ),
  rePassword: Yup.string()
    .required(requiredParam('rePassword'))
    .oneOf([Yup.ref('password'), null], paramsDontMatch('password')),
  terms: Yup.bool().oneOf([true], requiredParam('terms')),
});

export const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  rePassword: '',
  terms: false,
};
