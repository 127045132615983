import { Link } from 'react-router-dom';
import { Icon } from 'components/structure';
import { ROUTES } from 'constants/urls';
import * as S from './Logo.styles';

export type LogoProps = {
  url?: string;
  alt?: string;
  center?: boolean;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  hideOnMobile?: boolean;
  light?: boolean;
};

export const Logo = ({
  center = false,
  large = false,
  medium = false,
  hideOnMobile = false,
  light = false,
  small = false,
  url,
  alt,
}: LogoProps) => (
  <Link to={ROUTES.organizations.getLink('root')}>
    <S.Wrapper
      center={center}
      large={large}
      medium={medium}
      small={small}
      hideOnMobile={hideOnMobile}
      light={light}
    >
      {url ? <img src={url} alt={alt} /> : <Icon icon="IcBigLogo" />}
    </S.Wrapper>
  </Link>
);
