import styled, { css, DefaultTheme } from 'styled-components';
import { BreadCrumbProps } from './BreadCrumb';

type WrapperProps = Pick<BreadCrumbProps, 'isAbsolute'>;

const wrapperModifiers = {
  isAbsolute: (theme: DefaultTheme) => css`
    position: absolute;
    top: ${theme.spacing.sm};
    left: ${theme.spacing.sm};
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isAbsolute }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.sm};

    svg {
      margin: 0 ${theme.spacing['2xs']};
    }

    ${isAbsolute && wrapperModifiers.isAbsolute(theme)}
  `}
`;

export const ScreenText = styled.span<{ active?: boolean }>`
  ${({ theme, active }) => css`
    font-size: ${theme.typography.sizes.sm};
    color: ${active
      ? theme.colors.grayscale.dark.title
      : theme.colors.grayscale.dark.info};
  `}
`;
