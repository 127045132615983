import styled, { css } from 'styled-components';
import { HelperText as HT } from 'components/form/HelperText/HelperText';

export const Wrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;

    & > input {
      display: none;
    }
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    & > * {
      margin: ${theme.spacing['2xs']};
    }
  `}
`;

export const FileName = styled(HT)`
  ${({ theme }) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.colors.grayscale.dark.info};
    padding: 0;
  `}
`;

export const HelperText = styled(HT)`
  ${({ theme, error }) => css`
    color: ${!error && theme.colors.neutral.white};
    padding: 0;
  `}
`;
