import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam } = messages;

export const schema = Yup.object().shape({
  othersTerms: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(requiredParam('othersTermsName')),
      description: Yup.string().required(
        requiredParam('othersTermsDescription'),
      ),
      file: Yup.string().required(requiredParam('pdfFile')),
      check: Yup.boolean(),
    }),
  ),
});

export const defaultValues = {
  othersTerms: [
    {
      name: '',
      description: '',
      file: undefined,
      check: false,
    },
  ],
};
