import { createContext, SetStateAction, useContext, useState } from 'react';
import { WithChildren } from 'interfaces/children';

export type NewEvent = {
  organizationId: string;
  name: string;
  domain: string;
  accreditationStartedAt: string;
  accreditationEndedAt: string;
  startedAt: string;
  endedAt: string;
  isClosed?: boolean;
  accessType?: string;
  timezone: string;
  defaultLanguage: string;
};

type ContextType = {
  newEventProps: NewEvent;
  setNewEventProps: (value: SetStateAction<NewEvent>) => void;
  formProgress: number;
  setFormProgress(value: SetStateAction<number>): void;
};

const defaultValue: ContextType = {
  newEventProps: {
    organizationId: '',
    name: '',
    domain: '',
    accreditationStartedAt: '',
    accreditationEndedAt: '',
    startedAt: '',
    endedAt: '',
    isClosed: undefined,
    accessType: '',
    timezone: '',
    defaultLanguage: '',
  },
  setNewEventProps: () => undefined,
  formProgress: 1,
  setFormProgress: () => undefined,
};

export const NewEventContext = createContext<ContextType>(defaultValue);

export const NewEventProvider = ({ children }: WithChildren) => {
  const [newEventProps, setNewEventProps] = useState<NewEvent>(
    defaultValue.newEventProps,
  );
  const [formProgress, setFormProgress] = useState(defaultValue.formProgress);

  const contextValue = {
    newEventProps,
    setNewEventProps,
    formProgress,
    setFormProgress,
  };

  return (
    <NewEventContext.Provider value={contextValue}>
      {children}
    </NewEventContext.Provider>
  );
};

export const useNewEventContext = () => useContext(NewEventContext);

NewEventContext.displayName = 'NewEventContext';
