import { yupResolver } from '@hookform/resolvers/yup';
import { isAfter, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslate } from 'hooks';
import { parseTimeZoneToUtc, parseUtctoTimeZone } from 'lib/core/date';
import { useNewEventContext } from 'contexts';
import { FormContent } from 'components/contexts';
import { CustomSelect } from 'components/form';
import { Button, Divisor, Form, FormGroup } from 'components/structure';
import { FormState } from 'constants/enums';
import { ROUTES } from 'constants/urls';
import * as S from './EventSchedule.styles';
import { defaultValues, schemaValidate } from './EventSchedule.validation';
import { timezones } from './timezones';

type DefaultSchedule = {
  date: string;
  hour: string;
};

type FormModel = {
  start: DefaultSchedule;
  end: DefaultSchedule;
};

export type EventScheduleFormModel = {
  accreditation: FormModel;
  platform: FormModel;
  timezone: string;
};

export const EventScheduleForm = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { setNewEventProps, setFormProgress, newEventProps } =
    useNewEventContext();
  const [formState, setFormState] = useState(FormState.accreditation);

  const { control, register, errors, handleSubmit, reset, setError } =
    useForm<EventScheduleFormModel>({
      resolver: yupResolver(schemaValidate),
      defaultValues,
    });

  useEffect(() => {
    if (errors[FormState.platform] && !errors[FormState.accreditation]) {
      setFormState(FormState.platform);
    } else if (errors[FormState.accreditation] && !errors[FormState.platform]) {
      setFormState(FormState.accreditation);
    }
  }, [errors]);

  useEffect(() => {
    const {
      accreditationStartedAt,
      accreditationEndedAt,
      startedAt,
      endedAt,
      timezone,
    } = newEventProps;

    if (newEventProps.accreditationStartedAt) {
      const inputValues: EventScheduleFormModel = {
        accreditation: {
          start: parseUtctoTimeZone(accreditationStartedAt, timezone),
          end: parseUtctoTimeZone(accreditationEndedAt, timezone),
        },
        platform: {
          start: parseUtctoTimeZone(startedAt, timezone),
          end: parseUtctoTimeZone(endedAt, timezone),
        },
        timezone,
      };

      reset({
        ...inputValues,
      });
    }
  }, [reset, newEventProps]);

  const formOptions = [
    {
      name: translate('event.newEvent.accreditation'),
      formName: FormState.accreditation,
      show: formState === FormState.accreditation,
    },
    {
      name: translate('event.newEvent.theEvent'),
      formName: FormState.platform,
      show: formState === FormState.platform,
    },
  ];

  const handleScheduleValidation = (
    data: Omit<EventScheduleFormModel, 'timezone'>,
  ) => {
    const { accreditation, platform } = data;
    const scheduleErrors: string[] = [];

    Object.entries(data).map(([key, value]) => {
      const { start, end } = value;

      const isEndDateAfterInitial = isAfter(
        new Date(`${end.date} ${end.hour}`),
        new Date(`${start.date} ${start.hour}`),
      );

      if (!isEndDateAfterInitial) {
        const errorMessage = {
          message: 'errors.required.isDateBefore',
        };
        scheduleErrors.push(errorMessage.message);
        setError(`${key}.end.date`, errorMessage);
      }

      return isEndDateAfterInitial;
    });

    const isPlatformBeforeAccreditation = isBefore(
      new Date(`${platform.start.date} ${platform.start.hour}`),
      new Date(`${accreditation.start.date} ${accreditation.start.hour}`),
    );

    if (isPlatformBeforeAccreditation) {
      const errorMessage = {
        message: 'errors.required.isDateBeforeAccreditation',
      };
      scheduleErrors.push(errorMessage.message);
      setError('platform.start.date', errorMessage);
    }

    return scheduleErrors;
  };

  const onSubmit = (data: EventScheduleFormModel) => {
    const { accreditation, platform, timezone } = data;

    const scheduleValidations = handleScheduleValidation({
      accreditation,
      platform,
    });

    if (scheduleValidations.length) return;

    const scheduleValues = {
      accreditationStartedAt: parseTimeZoneToUtc(accreditation.start, timezone),
      accreditationEndedAt: parseTimeZoneToUtc(accreditation.end, timezone),
      startedAt: parseTimeZoneToUtc(platform.start, timezone),
      endedAt: parseTimeZoneToUtc(platform.end, timezone),
      timezone,
    };

    setNewEventProps((prevState) => ({
      ...prevState,
      ...scheduleValues,
    }));
    setFormProgress((prevState) => prevState + 1);
    navigate(ROUTES.events.getLink('type'));
  };

  const handleGoBack = () => {
    setFormProgress((prevState) => prevState - 1);
    navigate(ROUTES.events.getLink('root'));
  };

  return (
    <Form maxWidth="360px" fullWidth onSubmit={handleSubmit(onSubmit)}>
      <S.FormOptions>
        {formOptions.map(({ name, formName }) => (
          <S.Option
            key={name}
            active={formState === formName}
            error={!!errors[formName]}
            onClick={() => setFormState(formName)}
          >
            {name}
          </S.Option>
        ))}
      </S.FormOptions>

      {formOptions.map(({ formName, show }) => (
        <FormContent
          key={formName}
          ref={register}
          control={control}
          errors={errors}
          name={formName}
          show={show}
        />
      ))}

      <FormGroup>
        <CustomSelect
          control={control}
          name="timezone"
          label={translate('event.newEvent.timeZone')}
          placeholder={translate('event.newEvent.timeZone')}
          error={errors.timezone?.message}
          options={timezones}
        />
      </FormGroup>

      <Divisor>
        <Button color="secondary" onClick={handleGoBack}>
          {translate('labels.back')}
        </Button>
        <Button type="submit">{translate('labels.advance')}</Button>
      </Divisor>
    </Form>
  );
};
