import { useEffect, useState } from 'react';
import * as S from './Avatar.styles';
import { AvatarFallback } from './Fallback/AvatarFallback';

export type AvatarProps = {
  icon?: JSX.Element | null;
  alt?: string;
  src?: string;
  size?: 'xs' | 'small' | 'medium' | 'large' | 'full';
};

export const Avatar = ({ icon, src, alt, size }: AvatarProps) => {
  const [showFallback, setShowFallback] = useState(false);
  const onError = () => setShowFallback(true);

  useEffect(() => {
    setShowFallback(typeof src === 'undefined' || src === '');
  }, [src]);

  if (icon) {
    return <S.Wrapper size={size}>{icon}</S.Wrapper>;
  }

  return (
    <S.Wrapper data-testid="avatar-wrapper" size={size}>
      {showFallback ? (
        <AvatarFallback aria-label={alt} />
      ) : (
        <img src={src} alt={alt} onError={onError} />
      )}
    </S.Wrapper>
  );
};
