import { useCreateUser, useTerms } from 'useCases';
import { AccreditationForm } from 'components/contexts';

export const Accreditation = () => {
  const { handleCreateUser, isPending } = useCreateUser();
  const { data: { data: terms } = {} } = useTerms();

  return (
    <AccreditationForm
      terms={terms}
      onSubmit={handleCreateUser}
      isPending={isPending}
    />
  );
};
