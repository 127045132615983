import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { mutate } from 'swr';
import { useAPI, useTranslate } from 'hooks';
import { useRecaptcha } from 'hooks/useRecaptcha';
import { setUserId } from 'lib/core/session';
import { useModalContext } from 'contexts';
import { Icon } from 'components/structure';
import { Description } from 'components/structure';
import { ForgotPassword, SignInFormModel } from 'interfaces/auth';
import { User } from 'interfaces/user';
import { AUTH, USERS } from 'constants/endpoints';
import { NOT_CONFIRMED_REGISTRATION } from 'constants/enums';
import { ROUTES } from 'constants/urls';

export type UseForgotPassword = {
  onEmailSended: () => void;
};

export type UpdateForgotPassword = {
  token?: string;
  password: string;
  rePassword: string;
};

export type UseUpdatePassword = {
  onPasswordUpdated: () => void;
};

type SignInResponse = {
  user: User;
};

export const useSignIn = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const translate = useTranslate();
  const { setShowModal, setModalProps } = useModalContext();
  const [isPending, setIsPending] = useState(false);
  const { recaptchaRef, showRecaptcha, onRecaptchaError } = useRecaptcha();

  const handleAuthentication = async (data: SignInFormModel) => {
    setIsPending(true);
    const tokenRecaptcha = recaptchaRef.current?.getValue();

    try {
      const {
        data: { user },
      } = await api.post<SignInResponse>(AUTH.SIGN_IN, {
        ...data,
        ...(tokenRecaptcha && { tokenRecaptcha }),
      });

      setUserId(user.id);
      mutate(USERS.BY_ID(user.id), { user });
      navigate(ROUTES.organizations.getLink('root'));
    } catch (error) {
      setIsPending(false);
      if (error.response.data.message === NOT_CONFIRMED_REGISTRATION) {
        setModalProps({
          title: translate('errors.opsTitle'),
          description: (
            <Description
              normalPhrase={translate('auth.signIn.incompleteRegister')}
              strongPhrase={translate('auth.signIn.incompleteRegisterStrong')}
            />
          ),
          icon: <Icon icon="IcAlertBorder" />,
          buttonLabel: translate('labels.understood'),
        });
        setShowModal(true);
      }

      onRecaptchaError(error.response);
    }
  };

  return { handleAuthentication, isPending, recaptchaRef, showRecaptcha };
};

export const useForgotPassword = ({ onEmailSended }: UseForgotPassword) => {
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);
  const { recaptchaRef, showRecaptcha, onRecaptchaError } = useRecaptcha();

  const handleForgotPassword = async ({ email }: ForgotPassword) => {
    setIsPending(true);
    const tokenRecaptcha = recaptchaRef.current?.getValue();

    try {
      await api.post(AUTH.FORGOT_PASSWORD, {
        email,
        ...(tokenRecaptcha && { tokenRecaptcha }),
      });
      onEmailSended();
    } catch (error) {
      onRecaptchaError(error.response);
    } finally {
      setIsPending(false);
    }
  };

  return { handleForgotPassword, isPending, showRecaptcha, recaptchaRef };
};

export const useUpdatePassword = ({ onPasswordUpdated }: UseUpdatePassword) => {
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);

  const handleUpdatePassword = async ({
    token,
    password,
    rePassword,
  }: UpdateForgotPassword) => {
    setIsPending(true);

    try {
      await api.post(AUTH.UPDATE_PASSWORD, { token, password, rePassword });

      onPasswordUpdated();
    } finally {
      setIsPending(false);
    }
  };

  return { handleUpdatePassword, isPending };
};

export const useConfirmRegistration = () => {
  const api = useAPI();
  const navigate = useNavigate();

  const handleConfirmRegistration = useCallback(
    async (token: string) => {
      try {
        await api.post(AUTH.CONFIRM_REGISTRATION, { token });
      } finally {
        navigate(ROUTES.login.getLink('signIn'));
      }
    },
    [api, navigate],
  );

  return { handleConfirmRegistration };
};
