import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { ToastProps } from './Toast';

type WrapperProps = Pick<ToastProps, 'show' | 'type'>;

const wrapperModifiers = {
  show: () => css`
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  `,
  default: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.primary.main};
  `,
  success: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.contextual.success.main};
  `,
  danger: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.contextual.danger.main};
  `,
  alert: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.contextual.attention.main};
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, show, type }) => css`
    border-radius: ${theme.shapes.borderRadius.xs};
    font-family: ${theme.typography.family.primary};
    position: fixed;
    bottom: calc(${theme.sizes.footer} + 20px);
    right: 36px;
    opacity: 0;
    padding: ${theme.spacing.sm};
    display: flex;
    justify-content: space-between;
    min-height: 80px;
    width: 416px;
    transform: translateX(100%);
    pointer-events: none;
    transition: all 0.3s ease-in-out, opacity 0.3s 0.05s linear;
    will-change: transform, opacity;
    z-index: ${theme.layers.alwaysOnTop};

    a {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }

    ${media.lessThan('medium')`
      max-height: 80px;
      border-radius: 0;
      top:0;
      right:0;
      width: 100%;
    `}

    ${show && wrapperModifiers.show()};
    ${!!type && wrapperModifiers[type](theme)};
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light.title};
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.base};
    font-weight: bold;
    line-height: 20px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light.title};
    font-size: ${theme.typography.sizes.sm};
    font-family: ${theme.typography.family.primary};
    font-weight: 500;
    display: inline-block;
    overflow: hidden;
    width: calc(100% - 20px);

    ${media.lessThan('medium')`
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      max-width: 250px;
    `}
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 20px);
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    border: none;
    line-height: 1;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;

    svg {
      color: ${theme.colors.grayscale.light.info};
      height: 14px;
      width: 14px;
    }
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    height: 14px;
    width: 14px;
    margin-right: 8px;

    svg {
      color: ${theme.colors.neutral.black};
      height: 100%;
      width: 100%;
    }
  `}
`;
