import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
