import { useState, useEffect, useCallback, useRef } from 'react';
import { useMatch } from 'react-router-dom';
import { useEventStore } from 'store';
import { Term, useCreateTerms, useEventTerms, useUpdateTerms } from 'useCases';
import { useTranslate } from 'hooks';
import { PreviewDocuments, Document } from 'components/contexts';
import { Button } from 'components/structure';
import { ROUTES } from 'constants/urls';
import {
  OthersTerms,
  OthersTermsFormFilled,
  OthersTermsFormModel,
} from './OthersTerms/OthersTerms';
import { Terms, TermsFormFiles, TermsFormInputsType } from './Terms/Terms';
import * as S from './TermsForm.styles';
import { TermsPreview } from './TermsPreview/TermsPreview';

type DocumentsState = {
  defaultTerms?: Array<Document>;
  otherTerms?: OthersTermsFormFilled & Array<{ _id?: string }>;
};

const DEFAULT_TERMS_NAMES = {
  termsFile: 'event.editEvent.termsForm.terms.title',
  conditionsFile: 'event.editEvent.termsForm.terms.conditions',
};

const DEFAULT_TERMS_KEYS = {
  termsFile: 'terms',
  conditionsFile: 'conditions',
};

export const TermsForm = ({ form }: { form: keyof typeof forms }) => {
  const translate = useTranslate();
  const matchOthers = useMatch(
    ROUTES.editEvent.getLink('terms', '', 'otherTerms'),
  );
  const [documents, setDocuments] = useState<DocumentsState>({});
  const [inputs, setInputs] = useState<{
    defaultTerms?: TermsFormInputsType & {
      termsId?: string;
      conditionsId?: string;
    };
    otherTerms?: OthersTermsFormModel & {
      othersTerms: Array<{ _id?: string }>;
    };
  }>({});
  const { eventId } = useEventStore();
  const { data: terms, isLoading } = useEventTerms(eventId);
  const { handleCreateTerms, isPending: isPendingCreate } =
    useCreateTerms(eventId);
  const { handleUpdateTerms, isPending: isPendingUpdate } =
    useUpdateTerms(eventId);
  const isPending = isPendingCreate || isPendingUpdate;
  const swapOtherTerms =
    useRef<((from: number, to: number) => void) | undefined>(undefined);

  const handleChangeDocument =
    (name: keyof typeof documents) =>
    (
      files: TermsFormFiles | OthersTermsFormFilled,
      inputs: TermsFormInputsType | OthersTermsFormModel,
    ) => {
      const filesArray = Array.isArray(files)
        ? files
        : Object.entries(files).map(([key, file]) => {
            const name = translate(
              DEFAULT_TERMS_NAMES[key as keyof typeof DEFAULT_TERMS_NAMES],
            );
            const termKey =
              DEFAULT_TERMS_KEYS[key as keyof typeof DEFAULT_TERMS_KEYS];
            const termFile = file
              ? URL.createObjectURL(file)
              : documents.defaultTerms?.find((doc) => doc.key === termKey)
                  ?.file || '';
            return {
              name,
              file: termFile,
              key: termKey,
            };
          });

      setInputs((old) => ({ ...old, [name]: inputs }));
      setDocuments((old) => ({ ...old, [name]: filesArray }));
    };

  const getDefaultTerms = useCallback(
    () =>
      terms
        ?.filter((term) => Object.keys(DEFAULT_TERMS_NAMES).includes(term.name))
        .sort((a, b) => {
          if (a.name[0] > b.name[0]) return -1;
          if (a.name[0] < b.name[0]) return 1;
          return 0;
        }),
    [terms],
  );

  const getOtherTerms = useCallback(
    () =>
      terms
        ?.filter(
          (term) => !Object.keys(DEFAULT_TERMS_NAMES).includes(term.name),
        )
        .sort((a, b) => {
          if (a.priority > b.priority) return 1;
          if (a.priority < b.priority) return -1;
          return 0;
        }),
    [terms],
  );

  const parseDefaultDocuments = (defaultTerms: Array<Term>) =>
    defaultTerms.map((term) => ({
      key: { termsFile: 'terms', conditionsFile: 'conditions' }[
        term.name
      ] as string,
      name: { termsFile: 'Termos', conditionsFile: 'Condições' }[
        term.name
      ] as string,
      file: term.termsUrl as string,
    })) || [];

  const parseOtherDocuments = (otherTerms: Array<Term>) =>
    otherTerms.map((term) => ({
      check: term.isRequiredTerm,
      description: term.description,
      name: term.name,
      _id: term.id,
    })) || [];

  const getDefaultTermByName = (defaultTerms: Array<Term>, name: string) =>
    defaultTerms?.find((term) => term.name === name);

  useEffect(() => {
    const defaultTerms = getDefaultTerms();
    const otherTerms = getOtherTerms();
    const conditions = getDefaultTermByName(
      defaultTerms || [],
      'conditionsFile',
    );
    const terms = getDefaultTermByName(defaultTerms || [], 'termsFile');

    setDocuments({
      defaultTerms: parseDefaultDocuments(defaultTerms || []),
      otherTerms: parseOtherDocuments(otherTerms || []),
    });
    setInputs({
      defaultTerms: {
        conditionsFile: conditions?.content || '',
        conditionsId: conditions?.id,
        termsFile: terms?.content || '',
        termsId: terms?.id,
      },
      otherTerms: {
        othersTerms:
          otherTerms?.map((term) => ({
            _id: term.id,
            check: term.isRequiredTerm,
            description: term.description,
            name: term.name,
            file: term.content,
          })) || [],
      },
    });
  }, [getDefaultTerms, getOtherTerms, terms]);

  if (isLoading) {
    // @TODO skeleton
    return <span>Loading...</span>;
  }

  const forms = {
    defaultTerms: (
      <Terms
        formId={form}
        onChange={handleChangeDocument('defaultTerms')}
        onSubmit={
          !getDefaultTerms()?.length ? handleCreateTerms : handleUpdateTerms
        }
        defaultValues={inputs.defaultTerms}
      />
    ),
    otherTerms: (
      <OthersTerms
        formId={form}
        onChange={handleChangeDocument('otherTerms')}
        onSubmit={
          !getOtherTerms()?.length ? handleCreateTerms : handleUpdateTerms
        }
        defaultValues={inputs.otherTerms}
        handleSwapItems={(swap) => {
          swapOtherTerms.current = swap;
        }}
      />
    ),
  };

  return (
    <>
      <S.EditAreaWrapper>{forms[form]}</S.EditAreaWrapper>
      <S.PreviewAreaWrapper>
        {matchOthers ? (
          <TermsPreview
            terms={documents.otherTerms || []}
            handleSwapItems={swapOtherTerms.current}
          />
        ) : (
          <PreviewDocuments documents={documents.defaultTerms} />
        )}
        <S.ActionButtonWrapper>
          <Button
            type="submit"
            form={form}
            disabled={isPending}
            isLoading={isPending}
          >
            {translate('labels.save')}
          </Button>
        </S.ActionButtonWrapper>
      </S.PreviewAreaWrapper>
    </>
  );
};
