import { Outlet } from 'react-router-dom';
import { useEventStore } from 'store';
import { useEvent } from 'useCases/events';
import { useTranslate } from 'hooks';
import { BreadCrumb, SubTitle } from 'components/structure';
import { Tabs } from 'components/structure/Tabs/Tabs';
import { ROUTES } from 'constants/urls';
import * as S from './TermsTabs.styles';

export const TermsTabs = () => {
  const translate = useTranslate();
  const { eventId } = useEventStore();
  const { data: event } = useEvent(eventId);
  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <SubTitle>
          {translate('event.editEvent.menu.accreditation.root')}
        </SubTitle>
        <BreadCrumb
          screens={[
            event?.name ?? '',
            translate('event.editEvent.menu.accreditation.terms'),
          ]}
        />
        <Tabs
          variant="primary"
          tabs={[
            {
              title: translate('event.editEvent.termsForm.terms.title'),
              url: ROUTES.editEvent.getLink('terms'),
            },
            {
              title: translate('event.editEvent.termsForm.others.title'),
              url: ROUTES.editEvent.getLink('terms', undefined, 'otherTerms'),
            },
          ]}
        />
      </S.HeaderWrapper>
      <S.OutletWrapper>
        <Outlet />
      </S.OutletWrapper>
    </S.Wrapper>
  );
};
