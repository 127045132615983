import styled, { css } from 'styled-components';

export const Section = styled.nav<{ active?: boolean; isSubitem?: boolean }>`
  ${({ theme, active, isSubitem }) => css`
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    background-color: ${isSubitem
      ? theme.colors.background.menu.light
      : active
      ? theme.colors.background.menu.dark
      : 'none'};
    color: ${active
      ? theme.colors.grayscale.dark.title
      : theme.colors.grayscale.dark.info};
    font-weight: ${active && !isSubitem && 'bold'};
    transition: 0.3s;
    cursor: pointer;
    :hover {
      background-color: ${isSubitem
        ? theme.colors.background.menu.main
        : theme.colors.background.menu.light};
    }
  `}
`;

export const ChildrenWrapper = styled.div<{ active: boolean }>`
  ${({ active, theme }) => css`
    height: ${active ? 'auto' : 0};
    overflow: hidden;
    background-color: ${theme.colors.background.menu.light};
    & > nav {
      padding: ${theme.spacing.xs} ${theme.spacing.lg};
    }
  `}
`;
