import styled, { css } from 'styled-components';
import * as CheckboxStyles from 'components/form/Checkbox/Checkbox.styles';

export const IconButton = styled.button`
  position: relative;
`;

export const Tooltip = styled.div<{ open: boolean }>`
  ${({ theme, open }) => css`
  right: -7px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 51px;
  min-width: 128px;
  visibility: ${open ? 'visible' : 'hidden'};
  opacity: ${open ? 1 : 0};
  background ${theme.colors.neutral.black};
  border-radius: ${theme.shapes.borderRadius.xs};
  border: 1px solid ${theme.colors.grayscale.dark.line};
  transition: all 0.3s ease-in-out, opacity 0.3s 0.05s linear;
  padding-top: ${theme.spacing.xs};
   ::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${theme.colors.neutral.black};
    border-left: 1px solid ${theme.colors.grayscale.dark.line};
    border-bottom: 1px solid ${theme.colors.grayscale.dark.line};
    position: absolute;
    top:-6px;
    right: 8px;
    transform: rotate(135deg);
  }

  ${CheckboxStyles.Checkbox}{
    &::after {
      border-color: ${theme.colors.neutral.white};
    }
  }

  ${CheckboxStyles.Input} {
    &:checked + ${CheckboxStyles.Label} {
      font-weight: bold;
    }
    &:checked + ${CheckboxStyles.Label} > ${CheckboxStyles.Checkbox} {
      border-color: ${theme.colors.neutral.white};
    }
  }
`}
`;

export const TooltipItem = styled.div<{ active?: boolean; ended?: boolean }>`
  ${({ theme }) => css`
    padding: ${theme.spacing.xs};
    width: 100%;
    display: flex;
    align-items: flex-start;
  `}
`;
