import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { isAuthenticated } from 'lib/core/session';
import { Accreditation } from 'pages/accreditation/Accreditation';
import {
  SignIn,
  ForgotPassword,
  ChangePassword,
  ConfirmRegistration,
} from 'pages/authentication';
import * as EditEvent from 'pages/edit-event';
import { EventsRoot } from 'pages/events/EventsRoot';
import * as NewEvent from 'pages/new-event';
import { NewOrganization } from 'pages/new-organization/NewOrganization';
import { Organizations } from 'pages/organizations/Organizations';
import { PublicRoutes } from 'components/contexts';
import { Private } from 'components/contexts/routes';
import { ROUTES } from 'constants/urls';

const { app, login, accreditation, events, organizations, editEvent } = ROUTES;

export interface RouteProps {
  children?: React.ReactNode;
  element?: React.ReactElement | null;
  path?: string;
}

const PrivateRoute = ({ path, element, children, ...props }: RouteProps) => {
  if (!isAuthenticated()) {
    return <Navigate to={login.getLink('signIn')} />;
  }

  return (
    <Route path={path} element={element} {...props}>
      {children}
    </Route>
  );
};

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <PrivateRoute path={app.base} element={<Private />}>
        <Route
          path={app.base}
          element={<Navigate to={app.children.organizations} />}
        />
        <Route path={app.children.events} element={<EventsRoot />} />
        <Route path={organizations.base}>
          <Route
            path={organizations.children.root}
            element={<Organizations />}
          />
          <Route
            path={organizations.children.create}
            element={<NewOrganization />}
          />
        </Route>
        <Route path={events.base} element={<NewEvent.Root />}>
          <Route path={events.children.root} element={<NewEvent.EventInfo />} />
          <Route
            path={events.children.schedule}
            element={<NewEvent.EventSchedule />}
          />
          <Route path={events.children.type} element={<NewEvent.EventType />} />
        </Route>
        <Route path={editEvent.base} element={<EditEvent.Root />}>
          <Route
            path={editEvent.children.terms.base}
            element={<EditEvent.Terms />}
          >
            <Route
              path={editEvent.children.terms.children.root}
              element={<EditEvent.DefaultTerms />}
            />
            <Route
              path={editEvent.children.terms.children.preview}
              element={<EditEvent.DefaultTerms />}
            />
            <Route
              path={editEvent.children.terms.children.otherTerms}
              element={<EditEvent.OtherTerms />}
            />
          </Route>
          <Route
            path={editEvent.children.interests}
            element={<EditEvent.Interests />}
          />
          <Route
            path={editEvent.children.interests}
            element={<EditEvent.Interests />}
          />
          <Route path={editEvent.children.customization.base}>
            <Route
              path={editEvent.children.customization.children.images}
              element={<EditEvent.Customization.Images />}
            />
          </Route>
          <Route
            path={editEvent.children.emails.base}
            element={<EditEvent.Emails />}
          >
            <Route
              path={editEvent.children.emails.children.root}
              element={<EditEvent.Confirmation />}
            />
            <Route
              path={editEvent.children.emails.children.recoverPass}
              element={<EditEvent.RecoverPass />}
            />
            <Route
              path={editEvent.children.emails.children.welcome}
              element={<EditEvent.Welcome />}
            />
          </Route>
          <Route
            path={editEvent.children.emails.children.root}
            element={<EditEvent.Confirmation />}
          />
          <Route
            path={editEvent.children.emails.children.recoverPass}
            element={<EditEvent.RecoverPass />}
          />
        </Route>
      </PrivateRoute>

      <Route path={accreditation.base} element={<PublicRoutes />}>
        <Route
          path={accreditation.children.signUp}
          element={<Accreditation />}
        />
      </Route>
      <Route path={login.base} element={<PublicRoutes />}>
        <Route path={login.children.signIn} element={<SignIn />} />
        <Route
          path={login.children.forgotPassword}
          element={<ForgotPassword />}
        />
        <Route
          path={login.children.resetPassword}
          element={<ChangePassword />}
        />
        <Route
          path={login.children.confirmRegistration}
          element={<ConfirmRegistration />}
        />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
