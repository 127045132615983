import styled, { css } from 'styled-components';
import { Paragraph } from 'components/structure';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Link = styled(Paragraph)`
  ${({ theme }) => css`
    color: ${theme.colors.primary.main};
    text-decoration: underline;
    cursor: pointer;
  `}
`;
