import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.background.menu.main};
    width: 222px;
  `}
`;

export const Section = styled.section`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm} 0;
  `}
`;

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderWrapper = styled(SectionWrapper)`
  ${({ theme }) => css`
    cursor: pointer;
    padding: 0 ${theme.spacing.sm};

    & :last-child {
      margin-left: ${theme.spacing.xs};
      color: ${theme.colors.grayscale.dark.info};
    }
  `}
`;

export const TitleWrapper = styled(SectionWrapper)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.sm};
    flex-direction: column;
    align-items: flex-start;
    & > p {
      margin-bottom: 0;
    }
  `}
`;
