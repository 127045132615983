import { errorsEN, errorsES, errorsPT } from './errors';
import { labelsEN, labelsES, labelsPT } from './labels';

export const corePT = {
  ...labelsPT,
  ...errorsPT,
};

export const coreEN = {
  ...labelsEN,
  ...errorsEN,
};

export const coreES = {
  ...labelsES,
  ...errorsES,
};
