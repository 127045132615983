import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam } = messages;

export const schemaValidate = Yup.object().shape({
  interests: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(requiredParam('interests')),
      priority: Yup.number(),
      code: Yup.number(),
    }),
  ),
});

export const defaultValues = {
  interests: [{ name: '', priority: 0, code: 0 }],
};
