import { useState, useEffect } from 'react';
import { useTranslate } from 'hooks';
import { Interest, InterestType } from 'components/contexts';
import {
  Button,
  DraggableItem,
  DraggableList,
  Paragraph,
} from 'components/structure';
import { MAX_INTERESTS_ARRAY_LENGTH } from 'constants/enums';
import * as S from './PreviewInterets.styles';

type PreviewInterestsProps = {
  interests: InterestType[];
  hasInterests: boolean;
  handleSwapItems: (from: number, to: number) => void;
  hasErrors: boolean;
  onSubmit: () => void;
  isPending: boolean;
};

const DEFAULT_INTEREST_NAME = 'event.editEvent.menu.accreditation.interests';

export const PreviewInterests = ({
  interests,
  hasInterests,
  handleSwapItems,
  hasErrors,
  onSubmit,
  isPending,
}: PreviewInterestsProps) => {
  const translate = useTranslate();
  const [interestList, setInterestList] = useState<DraggableItem<string>[]>([]);

  useEffect(() => {
    const currentArrayLength = interests.length;
    const currentArray = interests.map((interest) => ({
      ...interest,
      value: interest.name,
    }));

    if (currentArrayLength < MAX_INTERESTS_ARRAY_LENGTH) {
      const arrayDiffLength = MAX_INTERESTS_ARRAY_LENGTH - currentArrayLength;

      const previewItems = Array.from<unknown, DraggableItem<string>>(
        { length: arrayDiffLength },
        (_, index) => ({
          id: String(index),
          value: translate(DEFAULT_INTEREST_NAME),
          priority: currentArrayLength + index,
          code: currentArrayLength + index,
          isDraggable: false,
        }),
      );

      const result = [...currentArray, ...previewItems];
      return setInterestList(result);
    }

    setInterestList(currentArray);
  }, [interests, translate]);

  return (
    <S.Wrapper hasInterests={hasInterests}>
      <Paragraph>
        {translate('event.editEvent.interestsForm.preview')}
      </Paragraph>
      <S.Content>
        <DraggableList
          items={interestList}
          setItems={setInterestList}
          element={Interest}
          handleSwapItems={handleSwapItems}
        />
      </S.Content>
      <Button
        disabled={hasErrors || isPending}
        isLoading={isPending}
        onClick={onSubmit}
      >
        {translate('labels.save')}
      </Button>
    </S.Wrapper>
  );
};
