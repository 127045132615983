import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    & > section {
      display: flex;
      user-select: none;
      width: 100%;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      & > span {
        cursor: pointer;
        transition: 0.3s;
        padding: ${theme.spacing.sm};
        font-size: ${theme.typography.sizes.base};
        color: ${theme.colors.grayscale.dark.info};
      }

      & > span:hover {
        color: ${theme.colors.grayscale.dark.title};
      }

      & > span[class*='active'] {
        color: ${theme.colors.grayscale.dark.title};
      }
    }

    .primary {
      & > span {
        box-shadow: inset 0 -1px ${theme.colors.grayscale.dark.line};
      }
      & > span[class*='active'] {
        box-shadow: inset 0 -3px ${theme.colors.primary.main};
      }
    }

    .secondary {
      justify-content: center;
    }
  `}
`;

export const Content = styled.section`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm};
  `}
`;
