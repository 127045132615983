import { ButtonHTMLAttributes } from 'react';
import { Icon, IconsKeys } from 'components/structure';
import * as S from './ActionsButton.styles';

export type ActionsButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: IconsKeys;
  isAbsolute?: boolean;
};

export const ActionsButton = ({
  icon,
  isAbsolute = false,
  ...props
}: ActionsButtonProps) => (
  <S.Wrapper isAbsolute={isAbsolute} {...props}>
    <Icon icon={icon} />
  </S.Wrapper>
);
