import styled, { css, DefaultTheme } from 'styled-components/macro';
import media from 'styled-media-query';

const alignment = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

type AlignItems = keyof typeof alignment;
type TextAlign = 'center' | 'end' | 'start' | 'initial' | 'justify';

type FormProps = {
  fullWidth?: boolean;
  maxWidth?: string;
  padding?: 'lg' | 'xlg';
  alignItems?: AlignItems;
  textAlign?: TextAlign;
};

const formModifiers = {
  full: () => css`
    width: 100%;
  `,
  maxWidth: (maxWidth: string) => css`
    max-width: ${maxWidth};
  `,
  lg: (theme: DefaultTheme) => css`
    padding-top: ${theme.spacing.lg};
  `,
  xlg: (theme: DefaultTheme) => css`
    padding-top: ${theme.spacing['2xl']};
  `,
  alignItems: (value: AlignItems) => css`
    align-items: ${alignment[value]};
  `,
  textAlign: (value: TextAlign) => css`
    text-align: ${value};
  `,
};

export const Form = styled.form<FormProps>`
  ${({ theme, fullWidth, maxWidth, padding, alignItems, textAlign }) => css`
    display: flex;
    flex-direction: column;

    ${media.lessThan('medium')`
      padding-bottom: ${theme.spacing['2xl']};
    `}

    ${alignItems && formModifiers.alignItems(alignItems)};
    ${textAlign && formModifiers.textAlign(textAlign)};
    ${fullWidth && formModifiers.full()};
    ${maxWidth && formModifiers.maxWidth(maxWidth)};
    ${padding && formModifiers[padding](theme)};
  `}
`;

type FormGroupProps = {
  half?: boolean;
  hasMargin?: boolean;
  shortMargin?: boolean;
  center?: boolean;
  fullWidth?: boolean;
  shortGap?: boolean;
};

const formGroupModifiers = {
  half: () => css`
    width: 50%;

    ${media.lessThan('medium')`
      width: 100%;
    `}
  `,
  withMargin: (theme: DefaultTheme) => css`
    margin-bottom: ${theme.spacing.xl};

    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacing.lg};
    `}
  `,
  shortMargin: (theme: DefaultTheme) => css`
    margin-bottom: ${theme.spacing.lg};
  `,
  center: () => css`
    margin: 0 auto;
  `,
  fullWidth: () => css`
    width: 100%;
  `,
  shortGap: (theme: DefaultTheme) => css`
    grid-gap: ${theme.spacing.sm};
  `,
};

export const FormGroup = styled.div<FormGroupProps>`
  ${({
    theme,
    half = false,
    hasMargin = true,
    shortMargin = false,
    center = false,
    fullWidth = false,
    shortGap,
  }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: ${theme.spacing.lg};

    ${media.lessThan('small')`
      grid-template-columns: 1fr;
    `}

    ${center && formGroupModifiers.center()}
    ${half && formGroupModifiers.half()};
    ${fullWidth && formGroupModifiers.fullWidth()};
    ${hasMargin && !shortMargin && formGroupModifiers.withMargin(theme)};
    ${shortMargin && formGroupModifiers.shortMargin(theme)};
    ${shortGap && formGroupModifiers.shortGap(theme)};
  `}
`;

type FormActionsProps = {
  justifyContent?: 'center';
  center?: boolean;
};

export const formActionsModifers = {
  justifyContent: (value: FormActionsProps['justifyContent']) => css`
    justify-content: ${value};
  `,
  center: () => css`
    margin: 0 auto;
  `,
};

export const FormActions = styled.div<FormActionsProps>`
  ${({ theme, justifyContent, center }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    grid-gap: ${theme.spacing.md} ${theme.spacing.lg};

    ${justifyContent && formActionsModifers.justifyContent(justifyContent)}
    ${center && formActionsModifers.center()}
  `}
`;

export const TrickSpace = styled.span`
  ${media.lessThan('large')`
    display: none;
  `}
`;
