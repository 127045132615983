import { useTranslate } from 'hooks';
import { ErrorGeneric } from 'components/structure';

type ErrorGuard = {
  isError: boolean;
  children: React.ReactNode;
};

export const ServerErrorGuard = ({ isError, children }: ErrorGuard) => {
  const translate = useTranslate();

  if (isError)
    return (
      <ErrorGeneric
        title={translate('errorGuard.title')}
        subtitle={translate('errorGuard.subtitle')}
        description={translate('errorGuard.description')}
        buttonLabel={translate('errorGuard.buttonLabel')}
        actionReload={() => location.reload()}
      />
    );

  return <>{children}</>;
};
