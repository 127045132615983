import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useEventStore } from 'store';
import { useAPI, useTranslate } from 'hooks';
import { NewEvent, useModalContext } from 'contexts';
import { Button, Divisor, Icon } from 'components/structure';
import { EVENTS } from 'constants/endpoints';
import { ROUTES } from 'constants/urls';

export const useNewEvent = () => {
  const translate = useTranslate();
  const api = useAPI();
  const navigate = useNavigate();
  const { addEventId } = useEventStore();
  const { setModalProps, setShowModal } = useModalContext();
  const [isPending, setIsPending] = useState(false);

  const onEditEvent = (eventId: string) => {
    setShowModal(false);
    addEventId(eventId);
    navigate(ROUTES.editEvent.getLink('root'));
  };

  const CustomButtons = ({ eventId }: { eventId: string }) => (
    <Divisor center hasGap>
      <Button onClick={() => onEditEvent(eventId)}>
        {translate('event.eventList.eventUpdate')}
      </Button>
      <Button color="secondary" onClick={() => setShowModal(false)}>
        {translate('event.eventList.eventPanel')}
      </Button>
    </Divisor>
  );

  const handleCreateNewEvent = async (data: NewEvent) => {
    setIsPending(true);
    try {
      const { data: event } = await api.post(EVENTS.ROOT, data);
      navigate(ROUTES.app.getLink('events'));
      setModalProps({
        icon: <Icon icon="IcSuccess" />,
        title: translate('event.eventList.success.title'),
        description: translate('event.eventList.success.description'),
        buttonLabel: translate('labels.understood'),
        noIconContainer: false,
        customButtons: <CustomButtons eventId={event.id} />,
      });
      setShowModal(true);
    } catch {
      setIsPending(false);
    }
  };

  return { handleCreateNewEvent, isPending };
};
