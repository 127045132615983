import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const routesPT = {
  routes: pt,
};

export const routesEN = {
  routes: en,
};

export const routesES = {
  routes: es,
};
