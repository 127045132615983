import { generateDocumentPreviewUrl } from 'lib/contexts';
import * as S from './PdfViewer.styles';

type PdfViewerProps = {
  file: File | string;
};

export const PdfViewer = ({ file }: PdfViewerProps) => (
  <S.Wrapper>
    <S.Document data={generateDocumentPreviewUrl(file)} type="text/html" />
  </S.Wrapper>
);
