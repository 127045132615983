import styled, { css } from 'styled-components';
import * as TypographyStyles from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  ${() => css`
    ${TypographyStyles.SubTitle} {
      margin-bottom: 0;
    }

    input {
      display: none;
    }
  `}
`;

export const UploadWrapper = styled.div`
  ${() => css`
    position: relative;
    cursor: pointer;

    &:hover {
      & > ${UploadMask} {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  `}
`;

export const UploadMask = styled.div`
  ${({ theme }) => css`
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    border-radius: 50%;
    color: ${theme.colors.neutral.white};

    box-sizing: border-box;
    transition: all 0.4s ease-in-out;
  `}
`;
