import { forwardRef } from 'react';
import { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { useTranslate } from 'hooks';
import { useIntlContext } from 'contexts';
import { EventScheduleFormModel } from 'components/contexts';
import { Calendar, TextField } from 'components/form';
import { Icon } from 'components/structure';
import { LanguagesAcronyms } from 'constants/enums';
import * as S from './FormContent.styles';

export type FormContentProps = Pick<UseFormMethods, 'control'> & {
  show: boolean;
  errors: DeepMap<EventScheduleFormModel, FieldError>;
  name: 'accreditation' | 'platform';
};

export const FormContent = forwardRef<HTMLInputElement, FormContentProps>(
  ({ show, errors, control, name }, ref) => {
    const { language } = useIntlContext();
    const translate = useTranslate();

    return (
      <S.Wrapper show={show}>
        <Calendar
          control={control}
          name={`${name}.start.date`}
          locale={LanguagesAcronyms[language]}
          placeholder={translate('event.newEvent.startDate')}
          error={errors[name]?.start?.date?.message}
        />
        <Calendar
          control={control}
          name={`${name}.end.date`}
          locale={LanguagesAcronyms[language]}
          placeholder={translate('event.newEvent.endDate')}
          error={errors[name]?.end?.date?.message}
        />
        <TextField
          ref={ref}
          name={`${name}.start.hour`}
          mask="hour"
          icon={<Icon icon="IcAlarm" />}
          label={translate('event.newEvent.startHour')}
          placeholder={translate('event.newEvent.startHour')}
          error={!!errors[name]?.start?.hour?.message}
          helperText={translate(errors[name]?.start?.hour?.message)}
          autoComplete="off"
        />
        <TextField
          ref={ref}
          name={`${name}.end.hour`}
          mask="hour"
          icon={<Icon icon="IcAlarm" />}
          label={translate('event.newEvent.endHour')}
          placeholder={translate('event.newEvent.endHour')}
          error={!!errors[name]?.end?.hour?.message}
          helperText={translate(errors[name]?.end?.hour?.message)}
          autoComplete="off"
        />
      </S.Wrapper>
    );
  },
);
